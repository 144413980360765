import React, { useState } from "react";
import { t } from "i18next";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdFolder, MdOutlineCreateNewFolder, MdOutlineFileUpload } from "react-icons/md";
import toast from "react-hot-toast";

import Button, { ButtonStyle } from "components/Button";
import DropdownMenu from "components/DropdownMenu";
import JobFilesService from "services/jobfiles.service";
import { uploadDocuments } from "redux/documents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { SearchType } from "./SearchTypeSelector";
import { MAX_UPLOAD_SIZE } from "config/constants";
import { InputAdornment, TextField } from "@mui/material";
import ConfirmationModal from "components/ConfirmationModal";

const ACCEPTED_MIMETYPES = ["image/*", "application/pdf", "application/x-zip-compressed"]

type NewMenuProps = {
  reloadSearch: (searchType: SearchType) => void;
};

const getRandomName = () => `#${Math.random().toString(36).substring(2, 8)}`;

export const NewMenu: React.FC<NewMenuProps> = ({ reloadSearch }) => {
  const [newJobfileName, setNewJobfileName] = useState(getRandomName());
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { uploadProgression } = useAppSelector((state) => state.documents);

  const fileInputRef = React.createRef<HTMLInputElement>();

  // Create a new jobfile
  const handleCreateJobfile = async () => {
    if (newJobfileName.length === 0) {
      toast.error(t("home.new_menu.new_jobfile_name_required"));
      return;
    }
    try {
      const jobfile = await JobFilesService.createJobfile(newJobfileName);
      if (jobfile) {
        toast.success(t("home.new_menu.new_jobfile_created", { name: jobfile.name }));
      }
    } catch (error) {
      toast.error(t("home.new_menu.new_jobfile_error"));
    } finally {
      // Trigger reload of search
      handleConfirmModalClose();
      setNewJobfileName(getRandomName());
      reloadSearch(SearchType.Jobfiles);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  // Triggered when user click "upload document" in NewMenu
  const onClickUploadDocument = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    fileInputRef.current?.click();
  };

  // Handle file input change
  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const _files = e.target.files;

    if (!_files) {
      toast.error("No files in target to be uploaded");
      return;
    }

    const _file = _files[0];
    if (_file.size > MAX_UPLOAD_SIZE) {
      // Max file size exceeded
      toast.error(t("document_upload_size_exceeded") + ` ${Math.round(MAX_UPLOAD_SIZE / 1024 / 1024 * 10) / 10} MB. (${Math.round(_file.size / 1024 / 1024 * 10) / 10} MB).`);
    } else if (!ACCEPTED_MIMETYPES.includes(_file.type)) {
      // Mimetype not accepted
      // Can happen even if file input is properly configured with "All files *.*" option
      toast.error(`${_file.type} ` + t("document_upload_mimetype_not_accepted") + ` ${ACCEPTED_MIMETYPES.join(", ")}.`);
    } else {
      // Handle the file
      const result = await dispatch(uploadDocuments({ files: [_file] }));
      if (result.payload) {
        toast.success(t("home.new_menu.document_uploaded"));

        // Trigger reload of search
        reloadSearch(SearchType.Documents);
      } else {
        // FIXME: We copy result to avoid Typescript error on result.error not existing
        const errorResult: any = { ...result }
        if (errorResult.error) {
          toast.error(t("document_upload_failed") + `\n${errorResult.error.message}`);
        } else {
          toast.error(t("document_upload_failed") + `\n${_file.name}`);
        }
      }

    }
  };

  // Rendering
  return (
    <div className="self-center ml-2">
      <DropdownMenu
        button={
          <Button
            text={uploadProgression.uploading ? t("document_upload_btn.uploading_label") : t("home.new_menu.title")}
            loading={uploadProgression.uploading}
            disabled={uploadProgression.uploading}
            leftIcon={!uploadProgression.uploading ? <AiOutlinePlusCircle className="inline mr-2 text-xl -mt-0.5" /> : null}
            className="shadow py-[17px]"
            color={ButtonStyle.Gray}
          />
        }
        items={[
          { text: t("home.new_menu.new_jobfile"), leftIcon: <MdOutlineCreateNewFolder className="text-lg mr-2 opacity-60" />, onClick: () => setConfirmModalOpen(true) },
          { text: t("home.new_menu.upload_document"), leftIcon: <MdOutlineFileUpload className="text-lg mr-2 opacity-60" />, onClick: onClickUploadDocument },
        ]}
      />

      <input className="hidden" type="file" onInput={handleFileInput} ref={fileInputRef} accept={`${ACCEPTED_MIMETYPES.join(",")}`} />
      <ConfirmationModal
        open={isConfirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleCreateJobfile}
        title={t("home.new_menu.new_jobfile_modal_title")}
      >
        {t("home.new_menu.new_jobfile_modal_content")} <br />
        <TextField
          autoFocus
          size="small"
          required
          error={newJobfileName.length === 0}
          helperText={newJobfileName.length === 0 ? t("home.new_menu.new_jobfile_modal_input_required") : ""}
          margin="normal"
          name="folderName"
          label={t("home.new_menu.new_jobfile_modal_input")}
          type="text"
          fullWidth
          variant="outlined"
          value={newJobfileName}
          onChange={(e) => setNewJobfileName(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdFolder />
              </InputAdornment>
            ),
            style: {
              fontSize: 13,
              paddingTop: 3,
              paddingBottom: 3
            }
          }}
        />
      </ConfirmationModal>
    </div>
  );
};
