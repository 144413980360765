import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  addTableRow,
  insertRowInTableLocally,
  revertLastColumnMapping,
  selectMappingWords,
  setDisplayCleaningAction,
  setFocusedField,
  setFocusedTable,
  setFocusedTableCell,
  setFocusedTableColumn,
  setHeuristicsMetadata,
  setMultiMapping,
  setTableRowsSelection,
  updateDocumentTableColumnLocally,
  updateDocumentTableLocally,
  updateTableColumnRows,
} from "redux/labelling";
import { useDocumentTypes } from "hooks/DocumentTypesHook";

import DocumentsService from "services/documents.service";
import { formatFieldId, getTableRows, getWordsSortedByLines, scrollToPageFromTableCell } from "utils/labelling";
import { DocumentFieldOrigin, DocumentFieldRowUpdateRequest, DocumentFieldUpdateRequest, DocumentTableFieldAddRowRequest, IDocumentField, IDocumentTableRow } from "models/document";
import { DocumentTypeFieldType, IDocumentTypeField } from "models/document_type";
import { OcrWord } from "types/labelling";

enum ArrowNavigationDirection {
  Left = 1,
  Right = 2,
  Up = 3,
  Down = 4,
}

type LabellingListenersProps = {};

type KeyHandlerCallbacks = {
  [key: string]: (event: KeyboardEvent) => void;
};

//
// This component is used to manage global labelling listeners (like keyboard listeners) in only one place
//
export const LabellingListeners: React.FC<LabellingListenersProps> = () => {
  const dispatch = useAppDispatch();
  const { currentDocument, pagesMetadata, focusedField, selectedMappingWords, focusedTable, readOnly } = useAppSelector((state) => state.labelling);
  const { currentDocType } = useDocumentTypes();

  // Detect when focusedField change and trigger a automatic scroll
  useEffect(() => {
    if (!focusedField) return;

    // Scroll to element if not visible
    const fieldId = formatFieldId(focusedField);
    const fieldElement = document.querySelector(`[id="${fieldId}"]`);
    fieldElement?.scrollIntoView({ block: "nearest" });
  }, [focusedField]);

  // Detect when focusedCell change and trigger a automatic scroll
  useEffect(() => {
    if (!currentDocument || !pagesMetadata || !focusedTable?.focusedCell?.cell) return;

    // Scroll to element if not visible in table panel
    const cellId = `table-${focusedTable.table.technicalName}.row_${focusedTable.focusedCell.row}.cell_${focusedTable.focusedCell.cell.technicalName}`;
    const cellElement = document.querySelector(`td[id="${cellId}"]`);
    cellElement?.scrollIntoView({ block: "nearest" });

    // Scroll to element if not visible inside the page
    scrollToPageFromTableCell(currentDocument, pagesMetadata, focusedTable, focusedTable.focusedCell.row, focusedTable.focusedCell.cell);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument, pagesMetadata, focusedTable?.focusedCell?.cell, focusedTable?.focusedCell?.row, focusedTable?.table.technicalName]);

  // Detect when column mapping change to another column and trigger a automatic scroll to document top
  useEffect(() => {
    if (!focusedTable?.focusedColumn?.column?.technicalName || !currentDocument) return;

    // 1) Get position of first row
    const tableRows = getTableRows(currentDocument, focusedTable);
    let lowestPageMapping: number | null = null;
    for (const tableRow of tableRows) {
      for (const [, entry] of Object.entries(tableRow)) {
        if (entry.mapping && entry.mapping.length > 0) {
          if (lowestPageMapping === null || entry.mapping[0].page < lowestPageMapping) lowestPageMapping = entry.mapping[0].page;
        }
      }
    }

    // If no mapping has been found, scroll to first page
    if (!lowestPageMapping) lowestPageMapping = 1;

    // Scroll to the page
    document.querySelector(`div[data-page-number="${lowestPageMapping}"]`)?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument, focusedTable?.focusedColumn?.column?.technicalName]);

  //
  // Keyboards listeners
  //

  // Handle multiple key listeners:
  // Case #1: Events on focusedField
  // Case #2: Events on focusedTable
  // Case #3: Events on focusedCell
  // Case #4: Events on mode "table column mapping"
  // Case #5: Others listeners
  useEffect(() => {
    // Create an array of handler in order to store all key events handlers that should be executed
    // In some condition, we may execute multiple of them
    let handlers: KeyHandlerCallbacks[] = [];
    // Case #1: Events on focusedField
    if (focusedField) handlers.push(getFocusedFieldListeners());
    // Case #2: Events on focusedTable (without annotation modal)
    if (focusedTable && !(selectedMappingWords && selectedMappingWords.length > 0)) handlers.push(getFocusedTableListeners());
    // Case #3: Events on focusedCell (without annotation modal)
    if (focusedTable?.focusedCell && !(selectedMappingWords && selectedMappingWords.length > 0)) handlers.push(getTableFocusedCellListeners());
    // Case #4: Events on mode "table column mapping"
    if (focusedTable?.focusedColumn) handlers.push(getTableColumnMappingListeners());

    // Case #5: Others listeners
    handlers.push(getOtherListeners());

    //
    // Add listeners to document DOM
    //

    // Listen key down events
    const keyDownHandler = (event: KeyboardEvent) => {
      // Execute availables handlers
      handlers.forEach((handler) => handler["keydown"]?.(event));
    };
    document.addEventListener("keydown", keyDownHandler);

    const keyUpHandler = (event: KeyboardEvent) => {
      // Execute availables handlers
      handlers.forEach((handler) => handler["keyup"]?.(event));
    };
    document.addEventListener("keyup", keyUpHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      document.removeEventListener("keyup", keyUpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument, pagesMetadata, focusedField, focusedTable, selectedMappingWords, currentDocType, dispatch]);

  // Return all listeners related to focused field
  // Case 1: When the escape key is pressed
  // Case 2: When the Alt key is pressed, we allow multimapping for a field
  const getFocusedFieldListeners = (): KeyHandlerCallbacks => {
    let handler: KeyHandlerCallbacks = {};

    // Listen key down events
    const keyDownHandler = (event: KeyboardEvent) => {
      if (!focusedField) return;

      const focusedFieldListenersCallback = {
        // Case 1: When the escape key is pressed
        // Case 1.1 : focusedField - we unfocus the field of the sidePanel and we don't care about the focusedTable.annotationZone
        // We don't care about the focusedTable.annotationZone because if we have both focusedFiel and focusedTable.annotationZone, the logical behaviour is to unfocus the focusedField, not the table area
        Escape: () => unfocusSelectedField(focusedField),
        Esc: () => unfocusSelectedField(focusedField),

        // Case 2: When the Alt key is pressed, we allow multimapping for a field
        Alt: () => dispatch(setMultiMapping(true)),

        // Case 3: When the Tab key is pressed and we are on last field, open table (if present)
        Tab: () => {
          if (event.shiftKey) return;

          const keyValueFields = currentDocType?.fields.filter((f) => f.type !== DocumentTypeFieldType.table) ?? [];
          if (keyValueFields.length > 0) {
            const lastField = keyValueFields[keyValueFields.length - 1];
            if (lastField.technicalName === focusedField.technicalName) {
              event.stopPropagation();
              const tableFields = currentDocType?.fields.filter((f) => f.type === DocumentTypeFieldType.table) ?? [];
              if (tableFields.length > 0) {
                dispatch(setFocusedTable(tableFields[0]));
              }
            }
          }
        },
      }[event.key];
      focusedFieldListenersCallback?.();
    };
    handler["keydown"] = keyDownHandler;

    // Listen key up events
    const keyUpHandler = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        dispatch(setMultiMapping(false));
      }
    };
    handler["keyup"] = keyUpHandler;

    // Return handler
    return handler;
  };

  // Return all listeners related to focused table
  // Case 1: metaKey or ctrlKey to enable / disable multiple documents selection
  // Case 2: shiftKey to enable / disable range documents selection
  // Case 3: pressing metaKey/ctrlKey + shiftKey to enable cleaning table feature
  const getFocusedTableListeners = (): KeyHandlerCallbacks => {
    let handler: KeyHandlerCallbacks = {};

    // Listen key down events
    const keyDownHandler = (event: KeyboardEvent) => {
      if (!focusedTable) return;

      // Case 1: metaKey or ctrlKey to enable / disable multiple documents selection
      if (event.metaKey || event.ctrlKey) {
        dispatch(setTableRowsSelection({ ...focusedTable.rowsSelection, multiSelectionEnabled: true }));
      }
      // Case 2: shiftKey to enable / disable range documents selection
      if (event.shiftKey) {
        dispatch(setTableRowsSelection({ ...focusedTable.rowsSelection, rangeSelectionEnabled: true }));
      }
      // Case 3: pressing metaKey/ctrlKey + shiftKey to enable cleaning table feature
      if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
        dispatch(setDisplayCleaningAction(true));
      }
    };
    handler["keydown"] = keyDownHandler;

    // Listen key up events
    const keyUpHandler = (event: KeyboardEvent) => {
      if (!focusedTable) return;

      let update: { [key: string]: any } = {};

      if (!event.metaKey && !event.ctrlKey) {
        update = { ...update, multiSelectionEnabled: false };
      }

      if (!event.shiftKey) {
        update = { ...update, rangeSelectionEnabled: false };
      }

      // Do only one state update if needed
      if (Object.keys(update).length > 0) dispatch(setTableRowsSelection({ ...focusedTable.rowsSelection, ...update }));

      // Case 3: pressing metaKey/ctrlKey + shiftKey to enable cleaning table feature
      if (((event.metaKey || event.ctrlKey) && event.shiftKey) === false) {
        dispatch(setDisplayCleaningAction(false));
      }
    };
    handler["keyup"] = keyUpHandler;

    // Return handler
    return handler;
  };

  // Return all listeners when a table cell is focused
  // #1: arrows to navigate between cells
  // #2: "Tab / Shift+Tab" to navigate between cells
  // #3: "del / backspace" to clean focusedCell
  // #4: "Enter" to start cell edition
  // #5: "Escape" to leave cell edition
  // #6: "Alt" to enable multi mapping for a single cell
  const getTableFocusedCellListeners = (): KeyHandlerCallbacks => {
    let handler: KeyHandlerCallbacks = {};
    // Listen key down events
    const keyDownHandler = (event: KeyboardEvent) => {
      const focusedCellListenersCallback = {
        ArrowLeft: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Left),
        Left: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Left),
        ArrowRight: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Right),
        Right: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Right),
        ArrowUp: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Up),
        Up: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Up),
        ArrowDown: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Down),
        Down: () => table_handleArrowNavigation(event, ArrowNavigationDirection.Down),

        Tab: () => table_handleTabNavigation(event),

        Backspace: () => table_handleCleanCurrentCell(event),
        Delete: () => table_handleCleanCurrentCell(event),

        Enter: () => table_handleEnter(event),

        Escape: () => table_handleLeaveCellEdition(event),
        Esc: () => table_handleLeaveCellEdition(event),

        Alt: () => dispatch(setMultiMapping(true)),
      }[event.key];
      focusedCellListenersCallback?.();
    };
    handler["keydown"] = keyDownHandler;

    // Listen key up events
    const keyUpHandler = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        dispatch(setMultiMapping(false));
      }
    };
    handler["keyup"] = keyUpHandler;

    // Return handler
    return handler;
  };

  // Table column mapping listeners
  // Case 1: When the Enter key is pressed, validate the selection
  // Case 2: When the Escape key is pressed and leave the mapping column mode
  // Case 3: When Ctrl+Z is pressed, we cancel last mapping zone saved
  // Case 4: When Tab / ShiftTab is pressed, save current selection and go to next / previous column
  const getTableColumnMappingListeners = (): KeyHandlerCallbacks => {
    let handler: KeyHandlerCallbacks = {};

    // Listen key down events
    const keyDownHandler = async (event: KeyboardEvent) => {
      if (!focusedTable?.focusedColumn) return;

      // Prevent default event
      event.preventDefault();
      event.stopPropagation();

      // Case 1: When the enter key is pressed, save all rows
      if (event.key === "Enter") {
        try {
          const saveSuccess = await saveColumnMappingSelection();

          if (!saveSuccess) return;
          // Unselect focused table column
          dispatch(setFocusedTableColumn(null));
        } catch (error) {
          console.error(error);
          toast.error(
            t("labelling_panel.table.column_mapping_fail", {
              column: t(`documentType.${focusedTable.focusedColumn.column.technicalName}`, `${focusedTable.focusedColumn.column.technicalName}`),
            })
          );
        }

      }

      // Case 2: When the Escape key is pressed and leave the mapping column mode
      if (event.key === "Escape") dispatch(setFocusedTableColumn(null));

      // Case 3: When Ctrl+Z is pressed, we cancel last mapping zone saved
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "z") dispatch(revertLastColumnMapping());

      // Case 4: When Tab / ShiftTab is pressed, save current selection and go to next / previous column
      if (event.key === "Tab") {
        if (focusedTable.table.columns && focusedTable.table.columns.length > 0) {
          // if nothing is selected, don't trigger the save by security
          if (focusedTable.focusedColumn.selectedMappingWords.length > 0) {
            try {
              const saveSuccess = await saveColumnMappingSelection();
              if (!saveSuccess) return;
            } catch (error) {
              console.error(error);
              return;
            }
          }

          const columnIndex = focusedTable.table.columns.findIndex((c) => c.technicalName === focusedTable.focusedColumn?.column.technicalName) ?? -1;
          if (columnIndex === -1) return;

          // If Shift is not pressed, go to next column (if possible)
          if (!event.shiftKey) {
            if (columnIndex < focusedTable.table.columns.length - 1) {
              return dispatch(setFocusedTableColumn({ column: focusedTable.table.columns![columnIndex + 1], selectedMappingWords: [] }));
            }
          }
          // Else we try to select previous column
          else {
            if (columnIndex > 0) {
              return dispatch(setFocusedTableColumn({ column: focusedTable.table.columns![columnIndex - 1], selectedMappingWords: [] }));
            }
          }
        }

        // if we arrive here, just unfocus column
        dispatch(setFocusedTableColumn(null));
      }
    };
    handler["keydown"] = keyDownHandler;

    // Return handler
    return handler;
  };

  // Return other listeners
  // Case 1: When the escape key is pressed and the annotation modal is displayed, close it
  // Case 2: When the tab key is pressed and the annotation modal is displayed, prevent it
  const getOtherListeners = (): KeyHandlerCallbacks => {
    let handler: KeyHandlerCallbacks = {};

    // Listen key down events
    const keyDownHandler = (event: KeyboardEvent) => {
      // Case 1: When the escape key is pressed and the annotation modal is displayed, close it
      if (event.key === "Escape" && !focusedField && selectedMappingWords && selectedMappingWords.length > 0) dispatch(selectMappingWords(null));

      // Case 2: When the tab key is pressed and the annotation modal is displayed, prevent it
      if (event.key === "Tab" && !focusedField && selectedMappingWords && selectedMappingWords.length > 0) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    };
    handler["keydown"] = keyDownHandler;

    // Return handler
    return handler;
  };

  //
  // Callback actions
  //

  // Handle navigation between cells with arrow
  const table_handleArrowNavigation = (event: KeyboardEvent, direction: ArrowNavigationDirection): void => {
    if (!currentDocument || !focusedTable || !focusedTable.table.columns || !focusedTable.focusedCell) return;

    // If the focused cell is currently edited
    if (focusedTable.focusedCell.edition) return;

    // Prevent event propagation
    event.stopPropagation();
    event.preventDefault();

    // Move current cell selection if possible
    switch (direction) {
      case ArrowNavigationDirection.Left:
        const columnIndex = focusedTable.table.columns.findIndex((c) => c.technicalName === focusedTable.focusedCell?.cell.technicalName);
        if (columnIndex !== -1 && columnIndex > 0) {
          dispatch(setFocusedTableCell({ cell: focusedTable.table.columns[columnIndex - 1], row: focusedTable.focusedCell.row, edition: false }));
        }
        break;

      case ArrowNavigationDirection.Right:
        const columnIndex2 = focusedTable.table.columns.findIndex((c) => c.technicalName === focusedTable.focusedCell?.cell.technicalName);
        if (columnIndex2 !== -1 && columnIndex2 < focusedTable.table.columns.length - 1) {
          dispatch(setFocusedTableCell({ cell: focusedTable.table.columns[columnIndex2 + 1], row: focusedTable.focusedCell.row, edition: false }));
        }
        break;

      case ArrowNavigationDirection.Up:
        if (focusedTable.focusedCell.row > 0) {
          const newRowIndex = focusedTable.focusedCell.row - 1;
          dispatch(setFocusedTableCell({ cell: focusedTable.focusedCell.cell, row: newRowIndex, edition: false }));
        }
        break;

      case ArrowNavigationDirection.Down:
        const tableRows = getTableRows(currentDocument, focusedTable);
        if (focusedTable.focusedCell.row < tableRows.length - 1) {
          const newRowIndex = focusedTable.focusedCell.row + 1;
          dispatch(setFocusedTableCell({ cell: focusedTable.focusedCell.cell, row: newRowIndex, edition: false }));
        }
        break;

      default:
    }
  };

  // Handle navigation between cells with tab
  const table_handleTabNavigation = async (event: KeyboardEvent) => {
    if (!currentDocument || !focusedTable || !focusedTable.table.columns || !focusedTable.focusedCell) return;

    // Prevent event propagation
    event.stopPropagation();
    event.preventDefault();

    // Check if it's a back tab
    const isBackTab = event.shiftKey ?? false;

    const columnIndex = focusedTable.table.columns.findIndex((c) => c.technicalName === focusedTable.focusedCell?.cell.technicalName);
    if (columnIndex === -1) return;

    // if it's back tab, then we have to go backward
    if (isBackTab) {
      if (columnIndex > 0) dispatch(setFocusedTableCell({ cell: focusedTable.table.columns[columnIndex - 1], row: focusedTable.focusedCell.row, edition: focusedTable.focusedCell.edition }));
      else {
        if (focusedTable.focusedCell.row >= 1) {
          dispatch(setFocusedTableCell({ cell: focusedTable.table.columns[focusedTable.table.columns.length - 1], row: focusedTable.focusedCell.row - 1, edition: focusedTable.focusedCell.edition }));
        }
      }
    }
    // Else we proceed a classic tab action => go to next input
    else {
      // Move current cell selection if possible AND keep edition state
      const columnIndex = focusedTable.table.columns.findIndex((c) => c.technicalName === focusedTable.focusedCell?.cell.technicalName);
      // Case #1: we can move to next column
      if (columnIndex < focusedTable.table.columns.length - 1) {
        dispatch(setFocusedTableCell({ cell: focusedTable.table.columns[columnIndex + 1], row: focusedTable.focusedCell.row, edition: focusedTable.focusedCell.edition }));
      }
      // Case #2: It's the last column, check if we can line jump
      else {
        const tableRows = getTableRows(currentDocument, focusedTable);
        if (focusedTable.focusedCell.row < tableRows.length - 1) {
          dispatch(setFocusedTableCell({ cell: focusedTable.table.columns[0], row: focusedTable.focusedCell.row + 1, edition: focusedTable.focusedCell.edition }));
        }
        // Case #3: It's the last column / last row, add a new line and focus it
        else {
          const newRowIndex = focusedTable.focusedCell.row + 1;
          table_createNewRow(newRowIndex, focusedTable.table.columns[0]);
        }
      }
    }
  };

  // Helper function to create a new row and autoFocus cell of this row
  const table_createNewRow = async (newRowIndex: number, focusColumn?: IDocumentTypeField) => {
    if (!focusedTable || !currentDocument || !focusedTable.table.columns) return;

    // Call API to add the row
    const tableFieldNewRow: DocumentTableFieldAddRowRequest = {
      field_id: focusedTable.table.technicalName,
      table: focusedTable.table.technicalName,
      row: newRowIndex,
      columns: focusedTable.table.columns ?? [],
    };

    try {
      await dispatch(addTableRow({ docId: currentDocument._id, newRow: tableFieldNewRow }));
      toast.success(t("labelling_panel.table.row_added"));
      // Update store locally
      const newRow: IDocumentTableRow = {};
      focusedTable.table.columns.forEach((col) => {
        newRow[col.technicalName] = {
          field_id: col.technicalName,
          field_type: col.type,
          value: "",
          origin: DocumentFieldOrigin.None,
          mapping: [],
        };
      });
      dispatch(
        insertRowInTableLocally({
          table: focusedTable.table.technicalName,
          row: newRowIndex,
          value: newRow,
        })
      );

      // Focus the new row / first cell
      if (focusColumn && focusedTable.focusedCell) {
        dispatch(setFocusedTableCell({ cell: focusColumn, row: focusedTable.focusedCell.row + 1, edition: focusedTable.focusedCell.edition ?? false }));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("labelling_panel.table.row_add_error"));
    }

  };

  // Handle cell cleaning (after using Backspace or Delete key)
  const table_handleCleanCurrentCell = async (event: KeyboardEvent) => {
    if (!currentDocument || !focusedTable || !focusedTable.table.columns || !focusedTable.focusedCell || focusedTable.focusedCell.edition === true) return;

    // Prevent event propagation
    event.stopPropagation();
    event.preventDefault();

    // Proceed the cell cleaning
    const rows: IDocumentTableRow[] = getTableRows(currentDocument, focusedTable);

    // Check if the row exist
    if (!rows[focusedTable.focusedCell.row]) {
      console.warn("[TableLabellingPanel] Error - Row index for field does not exist in table");
      return;
    }

    // Check if the field exist and have a value
    const currentRow = { ...rows[focusedTable.focusedCell.row] }; // Spread copy in order to make it editable
    const currentField: IDocumentField = currentRow[focusedTable.focusedCell.cell.technicalName];
    // If field does not have a value set, we stop here
    if (!currentField.value || currentField.value.length === 0) return;

    const reinitFieldRequest: DocumentFieldUpdateRequest = {
      field_id: focusedTable.focusedCell.cell.technicalName,
      field_type: focusedTable.focusedCell.cell.type,
      value: "",
      origin: DocumentFieldOrigin.None,
      mapping: [],
    };

    // Reset the cell
    currentRow[focusedTable.focusedCell.cell.technicalName] = reinitFieldRequest;

    // Update redux store
    const tableRowUpdate: DocumentFieldRowUpdateRequest = {
      table: focusedTable.table.technicalName,
      row: focusedTable.focusedCell.row,
      value: currentRow,
    };

    // Update row remotely
    try {
      const heuristicsData = await DocumentsService.updateDocumentFieldInRow(currentDocument._id, focusedTable.table.technicalName, focusedTable.focusedCell.row, reinitFieldRequest);
      toast.success(t("labelling_panel.toaster_successful_table_mapping"));
      dispatch(updateDocumentTableLocally(tableRowUpdate));
      dispatch(setHeuristicsMetadata(heuristicsData));
    } catch (error) {
      console.error(error);
      toast.error(t("labelling_panel.toaster_unsuccessful_table_mapping"));
    }
  };

  // Handle Enter in focusedCell
  // Case #1: Enable cell editon (after using Enter key)
  // Case #2: Jump to next row (after using Enter key on an edited cell)
  // Case #3: Jump to previous row (after using Shift+Enter key on an edited cell)
  const table_handleEnter = async (event: KeyboardEvent) => {
    if (!focusedTable || !currentDocument || !focusedTable.table.columns || !focusedTable.focusedCell || readOnly) return;

    // Case #1: Enable cell editon (after using Enter key)
    if (!focusedTable.focusedCell.edition) {
      // Prevent event propagation
      event.stopPropagation();
      event.preventDefault();

      // Enable edition for field
      dispatch(setFocusedTableCell({ ...focusedTable.focusedCell, edition: true }));
    }
    // Else if a cell is currently edited, we can navigate to previous row with Shift+Enter and next row with Enter
    else {
      // If alt key is pressed, don't do anything more (automatically handled by input)
      if (!event.altKey) {
        // Prevent event propagation
        event.stopPropagation();
        event.preventDefault();

        // Case #3: Jump to previous row (after using Shift+Enter key on an edited cell)
        if (event.shiftKey) {
          if (focusedTable.focusedCell.row > 0) {
            dispatch(setFocusedTableCell({ ...focusedTable.focusedCell, row: focusedTable.focusedCell.row - 1 }));
          }
        }
        // Case #2: Jump to next row (after using Enter key on an edited cell)
        else {
          const tableRows = getTableRows(currentDocument, focusedTable);
          if (focusedTable.focusedCell.row < tableRows.length - 1) {
            dispatch(setFocusedTableCell({ ...focusedTable.focusedCell, row: focusedTable.focusedCell.row + 1 }));
          }
          // Case #3: It's the last column / last row, add a new line and focus it
          else {
            const newRowIndex = focusedTable.focusedCell.row + 1;
            table_createNewRow(newRowIndex, focusedTable.focusedCell.cell);
          }
        }
      }
    }
  };

  // Handle cell leave edition (after using Escape key)
  const table_handleLeaveCellEdition = async (event: KeyboardEvent) => {
    if (!focusedTable || !focusedTable.table.columns || !focusedTable.focusedCell) return;

    // Prevent event propagation
    event.stopPropagation();
    event.preventDefault();

    // Leave edition for field
    if (focusedTable.focusedCell.edition === true) dispatch(setFocusedTableCell({ cell: focusedTable.focusedCell.cell, row: focusedTable.focusedCell.row, edition: false }));
    // Unselect the cell if not in edition
    else dispatch(setFocusedTableCell(null));
  };

  // Unfocus current focused field
  const unfocusSelectedField = (field: IDocumentTypeField) => {
    if (!field) return;

    // Trigger blur event on the input DOM
    var domElement = window.document.getElementById(formatFieldId(field));
    if (domElement) domElement.blur();

    dispatch(setFocusedField({ field: null }));
  };

  // Save current column mapping selected zones
  const saveColumnMappingSelection = async (): Promise<boolean> => {
    if (!currentDocument || !focusedTable?.focusedColumn) return false;

    // Get unique words selected
    const words = focusedTable.focusedColumn.selectedMappingWords.reduce((acc: OcrWord[], items: OcrWord[]) => {
      acc.push(...items);
      return acc;
    }, []);
    const uniqueWords = Array.from(new Set(words));

    // Extract all lines
    const lines = getWordsSortedByLines(uniqueWords, pagesMetadata, false);

    // Compare number of lines with number of rows in table
    // If it's different then display a confirmation box
    const tableRows = getTableRows(currentDocument, focusedTable);
    if (tableRows.length > 0 && lines.length !== tableRows.length) {
      if (!window.confirm(t("labelling_panel.table.confirm_column_mapping_save", { table_lines_count: tableRows.length, mapping_lines_count: lines.length }))) return false;
    }

    // Format each IDocumentField based on these lines
    const fields: IDocumentField[] = [];
    for (const line of lines) {
      fields.push({
        field_id: focusedTable.focusedColumn.column.technicalName,
        field_type: focusedTable.focusedColumn.column.type,
        value: line.words.map((w) => w.content).join(" "),
        mapping: line.words,
        origin: DocumentFieldOrigin.Mapping,
      });
    }

    // Save locally
    dispatch(updateDocumentTableColumnLocally(fields));

    // Save remotely
    try {
      const result = await dispatch(updateTableColumnRows({ docId: currentDocument._id, tableId: focusedTable.table.technicalName, columnId: focusedTable.focusedColumn.column.technicalName, fields }));
      if (result.payload !== true) {
        // Display error popup
        toast.error(
          t("labelling_panel.table.column_mapping_fail", {
            column: t(`documentType.${focusedTable.focusedColumn.column.technicalName}`, `${focusedTable.focusedColumn.column.technicalName}`),
          })
        );
        return false;
      } else {
        // Display success popup
        const tableRows = getTableRows(currentDocument, focusedTable);
        const numberOfRowsUpdated = Math.max(tableRows.length, lines.length);
        toast.success(
          t("labelling_panel.table.column_mapping_successful", {
            row: numberOfRowsUpdated,
            column: t(`documentType.${focusedTable.focusedColumn.column.technicalName}`, `${focusedTable.focusedColumn.column.technicalName}`),
          })
        );

        return true;
      }

    } catch (error) {
      // Display error popup
      toast.error(
        t("labelling_panel.table.column_mapping_fail", {
          column: t(`documentType.${focusedTable.focusedColumn.column.technicalName}`, `${focusedTable.focusedColumn.column.technicalName}`),
        })
      );
      return false;
    }
  };

  //
  // Render nothing
  //
  return null;
};
