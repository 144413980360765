import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import Button, { ButtonStyle } from "components/Button";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { toast } from "react-hot-toast";
import usecasesService from "services/usecases.service";
import { useNavigate } from "react-router-dom";
import { INewUsecase, IUsecase } from "models/usecase";

export const initialUsecaseMeta: INewUsecase = {
  name: "",
  // exportFormatters: [],
  // sections: [],
  // docTypes: [],
  // connector: undefined
};

interface UsecaseFormModalProps {
  open: boolean;
  handleClose: () => void;
}

const UsecaseFormModal: React.FC<UsecaseFormModalProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  //const tenantUsers: IUser[] = useSelector((state: RootState) => state.tenantUsers.entities);
  const [newUsecase, setNewUsecase] = useState<INewUsecase>(initialUsecaseMeta);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      setNewUsecase(initialUsecaseMeta);
      setErrors({});
    }
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewUsecase((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setNewUsecase((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!newUsecase.name) newErrors.name = t("admin.usecases.name_required");

    return newErrors;
  };

  const handleAddUsecase = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const newUsecaseID: string = await usecasesService.createUsecase(newUsecase);
      handleClose();
      navigate(`/admin/usecase/${newUsecaseID}/configuration`);
    } catch (err) {
      console.error(err);
      toast.error(t("user_list_view.add_user_error"));
    };
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("admin.usecases.add")}</DialogTitle>
      <DialogContent>
        <TextField
          error={!!errors.name}
          helperText={errors.name}
          autoFocus
          margin="dense"
          name="name"
          label="name"
          type="text"
          fullWidth
          variant="outlined"
          value={newUsecase.name}
          onChange={handleInputChange}
          required
        />
        {/* <FormControl fullWidth margin="dense">
          <InputLabel>{t("admin.usecases.export_formatters")}</InputLabel>
          <Select name="type" value={newUsecase.exportFormatters} onChange={handleSelectChange} label={t("admin.usecases.type")}>
            {Object.keys(UsecaseType).map((connType) => (
              <MenuItem key={connType} value={UsecaseType[connType as keyof typeof UsecaseType]}>
                {UsecaseType[connType as keyof typeof UsecaseType]}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button text={t("user_list_view.cancel")} onClick={handleClose} color={ButtonStyle.Gray} />
        <Button text={t("user_list_view.add")} onClick={handleAddUsecase} color={ButtonStyle.Saving} />
      </DialogActions>
    </Dialog >
  );
};

export default UsecaseFormModal;
