import { FormControlLabel, Checkbox, Typography, TextField, Autocomplete, Chip } from "@mui/material";
import { t } from "i18next";
import { IUsecaseAutomation } from "models/usecase";
import React, { useEffect } from "react";

interface AutomationSectionProps {
  automation: IUsecaseAutomation | null;
  onAutomationChange: (automation: IUsecaseAutomation) => void;
};

const AutomationSection: React.FC<AutomationSectionProps> = ({ automation, onAutomationChange }) => {
  const [selectedAutomation, setSelectedAutomation] = React.useState<IUsecaseAutomation | null>(automation);
  useEffect(() => {
    if (selectedAutomation) onAutomationChange(selectedAutomation);
  }, [selectedAutomation, onAutomationChange]);

  return (
    <div className="items-center border-docloop_borderColor my-3 ">
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            style={{ opacity: automation?.enabled ? 1 : 0.6 }}
            title={t("usecase.automation.enabled")}
            checked={automation?.enabled}
            onChange={(e) => { if (automation) setSelectedAutomation({ ...automation, enabled: e.target.checked }) }}
          />
        }
        label={
          <Typography sx={{ fontSize: '0.75rem' }}>
            {t("usecase.automation.enabled")}
          </Typography>
        }
      />
      {automation?.enabled && (
        <>
          <TextField
            size="small"
            disabled={true}
            label={t("usecase.automation.inbound_email")}
            value={automation?.inbound_email || ""}
            className="w-full"
            inputProps={{ style: { textAlign: "center" } }}
            sx={{ mb: 2 }}
            onClick={() => navigator.clipboard.writeText(automation?.inbound_email || "")}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                style={{ opacity: automation?.notification_to_sender ? 1 : 0.6 }}
                title={t("usecase.automation.notification_to_sender")}
                checked={automation?.notification_to_sender}
                onChange={(e) => { if (automation) setSelectedAutomation({ ...automation, notification_to_sender: e.target.checked }) }}
              />
            }
            label={
              <Typography sx={{ fontSize: '0.75rem' }}>
                {t("usecase.automation.notification_to_sender")}
              </Typography>
            }
            sx={{ mb: 2 }}
          />
          <Autocomplete
            multiple
            id="notification_emails"
            options={[]}
            value={automation?.notification_emails || []}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip variant="outlined" label={option} key={key} {...tagProps} />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("usecase.automation.notification_emails")}
                placeholder={t("usecase.automation.notification_emails_placeholder")}
              />
            )}
            onChange={(e: any, newValue: any) => { if (automation) setSelectedAutomation({ ...automation, notification_emails: newValue }); }}
          />

        </>

      )}
    </div>
  )
}

export default AutomationSection;
