import { IDocumentType } from "models/document_type";
import api from "./api";
import { DocumentFieldUpdateRequest, DocumentHeuristicsMetadata, DocumentTableFieldAddRowRequest, IDocument, IDocumentField } from "models/document";
import { IJobFile } from "models/jobfile";
import { IUsecase } from "models/usecase";
import { AxiosError } from "axios";

const DOCUMENT_API_URL = "/document";

// TODO: properly type axios responses
class DocumentsService {
  // Retrieve a single document
  async getDocument(docId: string): Promise<IDocument> {
    return api.get(`${DOCUMENT_API_URL}/${docId}`).then((response) => {
      return response.data;
    });
  }

  // Get the document file url with SAS Token
  // TODO: get a more complete file object from the /file path (e.g: url, size, name etc)
  // hence we keep this naming while returning response.data, will be changed later
  async getDocumentFileUrl(docId: string) {
    return api.get(`${DOCUMENT_API_URL}/${docId}/file`).then((response) => {
      if (!response.data.file_url) return;

      return response.data.file_url;
    });
  }

  async getDocumentLayoutMeta(docId: string) {
    return api.get(`${DOCUMENT_API_URL}/${docId}/layout`).then((response) => {
      return response.data;
    });
  }

  async getDocumentHeuristicsMeta(docId: string): Promise<DocumentHeuristicsMetadata> {
    return api.get(`${DOCUMENT_API_URL}/${docId}/heuristics`).then((response) => {
      return response.data;
    });
  }

  async getDocumentLayout(docId: string) {
    return api.get(`${DOCUMENT_API_URL}/${docId}/layout?page=all`).then((response) => {
      return response.data;
    });
  }

  async getDocumentPageLayout(docId: string, page: number) {
    return api.get(`${DOCUMENT_API_URL}/${docId}/layout?page=${page}`).then((response) => {
      return response.data;
    });
  }

  // Upload a new document
  async uploadDocument(file: File, jobFile?: IJobFile | null): Promise<IDocument> {
    let formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("file", file);
    if (jobFile) formData.append("jobFileId", jobFile._id);

    try {
      const uploadResponse = await api.post(DOCUMENT_API_URL, formData, {
        timeout: 1800000, // 30 minutes
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (uploadResponse.status === 200 && uploadResponse?.data) return uploadResponse.data;
      // If we arrive here, throw an error
      console.log("could not upload");
      throw new Error("Document upload failed");
    } catch (error) {
      // A 500 will arrive here
      console.log(error);
      throw new Error("Document upload failed");
    }
  }

  // Retrieve all existing documents types
  async getDocumentsTypes(): Promise<IDocumentType[]> {
    return api.get(`${DOCUMENT_API_URL}/documents_types`).then((response) => {
      return response.data;
    });
  }

  // Update document type for a single document
  // If we are in the context of a usecased jobfile, we need to tell the back which usecase the docType comes from so that it
  // can find it back and perform accurate extractions
  // another alternative would be to send the whole docType object
  async setDocumentType(docId: string, docType: IDocumentType | null, usecase: IUsecase | null): Promise<IDocument> {
    return api.post(`${DOCUMENT_API_URL}/${docId}/doc_type`, { doc_type_name: docType?.name ?? null, usecase_id: usecase?._id ?? null }).then((response) => {
      return response.data;
    });
  }

  // Update document type for a single document
  async updateDocumentField(docId: string, fieldUpdateRequest: DocumentFieldUpdateRequest): Promise<DocumentHeuristicsMetadata> {
    // This field is represented as a key-value pair
    const field_id = fieldUpdateRequest.field_id;
    return api.post(`${DOCUMENT_API_URL}/${docId}/fields/${field_id}`, { field_update: fieldUpdateRequest }).then((response) => {
      return response.data;
    });
  }

  async updateDocumentFieldInRow(docId: string, field_id: string, item_id: number, fieldUpdateRequest: DocumentFieldUpdateRequest): Promise<DocumentHeuristicsMetadata> {
    return api.post(`${DOCUMENT_API_URL}/${docId}/fields/${field_id}/items/${item_id}/fields/${fieldUpdateRequest.field_id}`, { field_update: fieldUpdateRequest }).then((response) => {
      return response.data;
    });
  }

  async addTableRow(docId: string, fieldUpdateRequest: DocumentTableFieldAddRowRequest): Promise<DocumentHeuristicsMetadata> {
    const field_id = fieldUpdateRequest.table;
    return api.post(`${DOCUMENT_API_URL}/${docId}/fields/${field_id}/items`, { field_update: fieldUpdateRequest }).then((response) => {
      return response.data;
    });
  }

  async deleteTableRows(docId: string, table_id: string, rows: number[]): Promise<DocumentHeuristicsMetadata> {
    const field_id = table_id;
    return api
      .delete(`${DOCUMENT_API_URL}/${docId}/fields/${field_id}/items`, { data: { rows_to_delete: rows } })
      .then((response) => {
        return response.data;
      })
      .catch((err: AxiosError) => {
        console.error(err.response?.data);
        throw new Error("Table rows deletion failed!");
      });
  }

  async updateTableColumnRows(docId: string, table_id: string, column_id: string, fields: IDocumentField[]): Promise<DocumentHeuristicsMetadata> {
    const field_id = table_id;
    return api
      .post(`${DOCUMENT_API_URL}/${docId}/fields/${field_id}/columns/${column_id}/items`, { fields })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(`Failed to save column mapping for "${column_id}"`);
      });
  }

  // Delete document
  async deleteDocument(docId: string): Promise<IDocument> {
    return api.delete(`${DOCUMENT_API_URL}/${docId}`).then((response) => {
      return response.data;
    });
  }

  // Validate a document
  async validateDocument(docId: string): Promise<IDocument> {
    return api.put(`${DOCUMENT_API_URL}/${docId}/validate`).then((response) => {
      return response.data;
    });
  }

  async unvalidateDocument(docId: string): Promise<IDocument> {
    return api.patch(`${DOCUMENT_API_URL}/${docId}`).then((response) => {
      return response.data;
    });
  }
}

const documentsServices = new DocumentsService();
export default documentsServices;
