import React from "react";
import { t } from "i18next";
import { FormControl, Grid, TextField } from "@mui/material";
import { GrPowerReset } from "react-icons/gr";

import { IUsecaseSectionDataField } from "models/usecase";
import ReferentialLookupField from "./ReferentialLookupField";
import OverridedStatus from "./OverridedStatus";
import { ConnectorType } from "models/connector";

type ValidationFieldProps = {
  field: IUsecaseSectionDataField;
  value: any;
  overrided: boolean;
  className?: string;
  onChange: (value: any) => void;
  onReset: () => void;

  tableMode?: boolean; // Use to display this component inside a table cell
  onReferentialLookupMenuOpen?: () => void;
  onReferentialLookupMenuClose?: () => void;
};

const ValidationField: React.FC<ValidationFieldProps> = ({ field, value, overrided, className = "", onChange, onReset, tableMode = false, onReferentialLookupMenuOpen, onReferentialLookupMenuClose }) => {
  const isReferentialLookupField = ["referential_lookup", ConnectorType.DISPATCH_MISSION].includes(field.processor.type);
  return (
    <Grid container spacing={0} alignItems="center" className={!tableMode ? "mb-2" : ""}>
      {!tableMode && (
        <Grid item xs={4}>
          <span className="text-xs font-medium text-slate-500">{t(`${field.id}`, field.label)}</span>
          <span className="text-xs font-normal text-red-700">{field.required ? " *" : ""}</span><br />
          {/* <span className="text-[8px] font-medium text-blue-400">{`${field.id}`}</span> */}
        </Grid>
      )}
      <Grid item xs={tableMode ? 12 : 8}>
        <div className="flex">
          <div className={`grow relative ${field.required && (typeof value !== "number" && !value) ? "border-red-500 border rounded bg-red-50" : "border-0"}`}>
            {/* Classic text input */}
            {!isReferentialLookupField && (
              <FormControl fullWidth size="small" style={{}}>
                <TextField
                  value={value ?? ""}
                  inputProps={{ style: { padding: `4.5px ${tableMode ? "6px" : "21px"} 4.5px 6px`, fontSize: 12, boxShadow: "none", outline: "none" } }}
                  variant={tableMode ? "standard" : "outlined"}
                  InputProps={tableMode ? { disableUnderline: true } : {}}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    // If ctrl+z is pressed, reset to original value
                    if (e.ctrlKey && e.key.toLowerCase() === "z") {
                      e.preventDefault();
                      onReset();
                    }
                  }}
                />

                <OverridedStatus value={overrided} style={tableMode ? { width: "4px", top: -0.5, bottom: -1.5, right: -0.5, borderRadius: 0 } : {}} />
              </FormControl>
            )}

            {/* Text input with a remote search*/}
            {isReferentialLookupField && (
              <>
                <ReferentialLookupField
                  field={field}
                  value={value ?? ""}
                  onChange={(str) => {
                    onChange(str);
                  }}
                  hideBorder={tableMode}
                  onMenuOpen={onReferentialLookupMenuOpen}
                  onMenuClose={onReferentialLookupMenuClose}
                />
                <OverridedStatus value={overrided} style={tableMode ? { width: "4px", top: -0.5, bottom: -1.5, right: -0.5, borderRadius: 0 } : {}} />
              </>
            )}
          </div>
          {overrided && !tableMode && (
            <div className="grow-0 px-1 ml-1 py-1 cursor-pointer text-base opacity-40 hover:opacity-70" onClick={onReset} title={t("jobfile_validation.field_reset")}>
              <GrPowerReset />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ValidationField;
