import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import * as Yup from 'yup';
import logo from "assets/logotext-white.png";
import background from "assets/bg-auth.png";
import { Formik, Form, FormikHelpers } from "formik";
import { Box, CircularProgress, TextField, Typography, Button } from "@mui/material";


import registrationService from "services/registration.service";
import toast from "react-hot-toast";
import { IPasswordReset } from "models/user";
import userService from "services/user.service";

interface FormValues {
  email: string;
  newPassword: string;
  newPasswordCheck: string;
}

export default function ForgotPassword() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();

  const [userRegistrationInfo, setUserRegistrationInfo] = useState<FormValues>({
    email: '',
    newPassword: '',
    newPasswordCheck: '',
  });


  useEffect(() => {
    const fetchResetPasswordData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const tenantId = searchParams.get('tenant');

      if (!tenantId || !token) {
        setError(t("forgot_password_view.invalid_link_missing_params"));
        setLoading(false);
        return;
      }

      try {
        const response = await registrationService.resetPasswordByInviteToken(tenantId, token);
        setUserRegistrationInfo({
          email: response.data.email ? response.data.email : '',
          newPassword: '',
          newPasswordCheck: '',
        });
        setLoading(false);
      } catch (err) {
        setError(t("forgot_password_view.invalid_link"));
        setLoading(false);
      }
    };

    fetchResetPasswordData();
  }, [location.search]);

  const handleSubmit = async (values: IPasswordReset, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    const passwordUpdate: IPasswordReset = {
      email: values.email!,
      newPassword: values.newPassword!
    }
    try {
      await userService.resetPassword(passwordUpdate);
      toast.success(t('my_profile.toast.success'));
      resetForm();
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) toast.error(err.response.data.error);
      else toast.error(err);
    } finally {
      setSubmitting(false);
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('my_profile.form.email_required')),
    newPassword: Yup.string()
      .required(t('my_profile.form.new_password_required'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, t("my_profile.form.password_complexity_rules")),
    newPasswordCheck: Yup.string()
      .required(t('my_profile.form.password_required'))
      .oneOf([Yup.ref('newPassword')], t('my_profile.form.password_must_match')),
  })


  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen h-1/3 bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
        <div className="flex overflow-hidden rounded-xl bg-white">
          <div className="flex items-center bg-gradient px-20">
            <img className="h-32" src={logo} alt="" />
          </div>
          <div className="p-10 text-center">
            <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>
            <CircularProgress />
          </div>
        </div>
      </div>
    )
  }


  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen h-1/3 bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
        <div className="flex overflow-hidden rounded-xl bg-white">
          <div className="flex items-center bg-gradient px-20">
            <img className="h-32" src={logo} alt="" />
          </div>
          <div className="p-10 text-center">
            <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>
            <Typography
              color="error"
              style={{ whiteSpace: "break-spaces" }}
            >
              {error}
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex overflow-hidden rounded-xl bg-white">
        <div className="flex items-center bg-gradient px-20">
          <img className="h-32" src={logo} alt="" />
        </div>
        <div className="p-10 text-center">
          <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>

          <div className="h-[1px] bg-gray-100 w-50 mt-6 mb-6 block" />
          <div className="text-center w-72">

            <Formik initialValues={userRegistrationInfo} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ isSubmitting, handleChange, values, errors, touched, submitForm }) => (
                <Form>
                  <Box mb={2}>
                    <TextField
                      disabled={true}
                      size="small"
                      name="email"
                      label={t("registration_view.form.email")}
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      name="newPassword"
                      label={t("registration_view.form.password")}
                      type="password"
                      value={values.newPassword}
                      onChange={handleChange}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      name="newPasswordCheck"
                      label={t("registration_view.form.confirm_password")}
                      type="password"
                      value={values.newPasswordCheck}
                      onChange={handleChange}
                      error={touched.newPasswordCheck && Boolean(errors.newPasswordCheck)}
                      helperText={touched.newPasswordCheck && errors.newPasswordCheck}
                    />
                  </Box>
                  {error && (
                    <Box mb={2}>
                      <Typography color="error"> {error}</Typography>
                    </Box>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={submitForm}
                    disabled={isSubmitting}>
                    {t("registration_view.register_password")}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div >
  );
}
