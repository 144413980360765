import { FormControl, TextField } from "@mui/material";
import { t } from "i18next";
import { IConnector } from "models/connector";
import React, { useState } from "react";
import Button, { ButtonStyle } from "components/Button";
import connectorsService from "services/connectors.service";
import toast from "react-hot-toast";
interface DispatchConnectorProps {
  connector: IConnector
}

const DispatchConnector: React.FC<DispatchConnectorProps> = ({ connector }) => {
  const [connectorParams, setConnectorParams] = useState(connector.params || {});
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setConnectorParams((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      connector.params = connectorParams
      await connectorsService.updateConnector(connector.id, connector);
      toast.success(t("connector.params.save_success"));
    } catch (err) {
      console.error(err);
      toast.error(t("connector.params.save_error"));
    };
  };

  return (
    <div className="h-full p-2">
      <div className="px-2 py-2 border-b flex items-center select-none">
        <span className="ml-4 font-semibold grow">{connector.name} ({connector.type})</span>
      </div>
      <div className="p-10">
        <div className="w-1/3">
          <FormControl fullWidth size="small">
            <TextField
              fullWidth
              margin="dense"
              name="host"
              label={t("connector.params.dispatch.host")}
              value={connectorParams.host}
              onChange={handleInputChange}
              type="text"
            />
            <TextField
              fullWidth
              margin="dense"
              name="client_id"
              label={t("connector.params.dispatch.client_id")}
              value={connectorParams.client_id}
              onChange={handleInputChange}
              type="text"
            />
            <TextField
              fullWidth
              margin="dense"
              name="client_secret"
              label={t("connector.params.dispatch.client_secret")}
              value={connectorParams.client_secret}
              onChange={handleInputChange}
              type="password"
            />
            <TextField
              fullWidth
              margin="dense"
              name="username"
              label={t("connector.params.dispatch.username")}
              value={connectorParams.username}
              onChange={handleInputChange}
              type="text"
            />
            <TextField
              fullWidth
              margin="dense"
              name="password"
              label={t("connector.params.dispatch.password")}
              value={connectorParams.password}
              onChange={handleInputChange}
              type="password"
            />
          </FormControl>
          <Button text={t("connector.params.save_button")} onClick={handleSave} color={ButtonStyle.Saving} />
        </div>
      </div>
    </div>
  )
}

export default DispatchConnector

