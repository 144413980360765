import { selectCustomStylesFitContent } from "components/SelectSearch";
import { AVAILABLE_EXPORT_FORMATTERS } from "models/usecase";
import React from "react";
import Select, { MultiValue } from "react-select";

interface DownloadersSectionProps {
  selectedDownloaders: string[];
  handleDownloadersChange: (selectedOptions: MultiValue<unknown>) => void;
};

type ExportFormatterOptions = {
  value: string;
  label: string;
};

const DownloadersSection: React.FC<DownloadersSectionProps> = ({ selectedDownloaders, handleDownloadersChange }) => {
  const formatOptions: ExportFormatterOptions[] = AVAILABLE_EXPORT_FORMATTERS.map((formatter) => ({
    value: formatter,
    label: formatter
  })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="flex items-center border-docloop_borderColor my-3 ">
      <Select
        classNamePrefix={"downloaders_select"}
        className="text-xs"
        isMulti
        options={formatOptions}
        onChange={(newValue: MultiValue<unknown>) => handleDownloadersChange(newValue)}
        value={formatOptions.filter(option => selectedDownloaders.includes(option.value))}
        styles={selectCustomStylesFitContent}
        isSearchable={true}
        name="downloaders"
      />
    </div>
  )
}

export default DownloadersSection;
