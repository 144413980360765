import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { ConnectorsTableComponent, ConnectorTableRowComponent } from "./components/ConnectorsTableComponents";
import { Box } from "@mui/material";
import { default as DocloopButton, ButtonStyle } from "components/Button";
import ConnectorFormModal from "./components/AddConnectorFormModal";
import ConfirmationModal from "components/ConfirmationModal";
import { t } from "i18next";
import { MdAdd } from "react-icons/md";
import { Helmet } from "react-helmet";
import { IConnectorMeta } from "models/connector";
import connectorsService from "services/connectors.service";
import { toast } from "react-hot-toast";
import { UserRoleEnum } from "models/user";
import { useNavigate } from "react-router-dom";

const ConnectorsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [connectors, setConnectors] = useState<IConnectorMeta[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isAddConnectorModalOpen, setAddConnectorModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedConnectorForDeletion, setSelectedConnectorForDeletation] = useState<IConnectorMeta | null>(null);

  useEffect(() => {
    const getConnectors = async () => {
      try {
        const connectors = await connectorsService.listConnectors();
        setConnectors(connectors);
      } catch (err: any) {
        setError(err.message);
      }
    }
    getConnectors();
  }, [dispatch]);


  if (currentUser && currentUser.role !== UserRoleEnum.OWNER) return <div>User is {currentUser.role} and not authorized.</div>;
  if (error) return <div> Error fetching users: {error} </div>

  const handleOpenAddConnectorModal = () => setAddConnectorModalOpen(true);
  const handleCloseAddConnectorModal = () => setAddConnectorModalOpen(false);

  const handleConfirmModalOpen = (connMeta: IConnectorMeta) => {
    setSelectedConnectorForDeletation(connMeta);
    setConfirmModalOpen(true);
  };
  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmModalConfirm = async () => {
    if (selectedConnectorForDeletion) {
      try {
        await connectorsService.deleteById(selectedConnectorForDeletion.id);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        handleConfirmModalClose();
        const connectors = await connectorsService.listConnectors();
        setConnectors(connectors);
      }
    }
  };


  return (
    <div className="h-full">
      <Helmet>
        <title>{t("admin.connectors.title")}</title>
      </Helmet>

      <div className="px-2 py-2 border-b flex items-center select-none">
        <span className="ml-4 font-semibold grow">{t("admin.connectors.title")}</span>
        <Box display="flex" justifyContent="flex-end">
          <DocloopButton
            text={t("admin.connectors.add_connector")}
            color={ButtonStyle.Blue}
            leftIcon={<MdAdd className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
            onClick={handleOpenAddConnectorModal}
          />
        </Box>
      </div>
      <ConnectorFormModal
        open={isAddConnectorModalOpen}
        handleClose={handleCloseAddConnectorModal}
      />
      <div className="p-2">
        <ConnectorsTableComponent headers={[
          t("admin.connectors.list.name"),
          t("admin.connectors.list.type"),
          '']}>
          {connectors && connectors.length > 0 && connectors.map((connMeta: IConnectorMeta) => (
            <ConnectorTableRowComponent
              key={connMeta.id}
              values={[
                { value: connMeta.name },
                { value: connMeta.type },
                { value: connMeta.id }
              ]}
              onDelete={() => {
                return handleConfirmModalOpen(connMeta);
              }}
              onClick={() => {
                return navigate(`/admin/connectors/${connMeta.id}`)
              }}
            />
          ))}
        </ConnectorsTableComponent>

      </div>

      <ConfirmationModal
        open={isConfirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalConfirm}
        title={t("admin.connectors.confirm_deletion")}
      >
        {t("admin.connectors.confirm_deletion.message")} <br />
        <strong>{selectedConnectorForDeletion?.name}</strong> ?
      </ConfirmationModal>
    </div>
  );
};

export default ConnectorsList;
