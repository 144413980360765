import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import Button, { ButtonStyle } from "components/Button";
import { t } from "i18next";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { INewReferential, IReferential } from "models/referential";
import referentialsService from "services/referentials.service";

export const initialReferentialMeta: INewReferential = {
  name: "",
  description: "",
};

interface ReferentialFormModalProps {
  open: boolean;
  handleClose: () => void;
}

const ReferentialFormModal: React.FC<ReferentialFormModalProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  //const tenantUsers: IUser[] = useSelector((state: RootState) => state.tenantUsers.entities);
  const [newReferential, setNewReferential] = useState<INewReferential>(initialReferentialMeta);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      setNewReferential(initialReferentialMeta);
      setErrors({});
    }
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewReferential((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!newReferential.name) newErrors.name = t("admin.referentials.add.name_required");
    if (!newReferential.description) newErrors.description = t("admin.referentials.add.description_required");

    return newErrors;
  };

  const handleAddReferential = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const referential: IReferential = await referentialsService.createReferential(newReferential);
      handleClose();
      navigate(`/admin/referentials/${referential.id}/configuration`);
    } catch (err) {
      console.error(err);
      toast.error(t("admin.referentials.add.error"));
    };
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("admin.referentials.add")}</DialogTitle>
      <DialogContent>
        <TextField
          error={!!errors.name}
          helperText={errors.name}
          autoFocus
          margin="dense"
          name="name"
          label="name"
          type="text"
          fullWidth
          variant="outlined"
          value={newReferential.name}
          onChange={handleInputChange}
          required
        />
        <TextField
          error={!!errors.description}
          helperText={errors.description}
          margin="dense"
          name="description"
          label="description"
          type="text"
          fullWidth
          variant="outlined"
          value={newReferential.description}
          onChange={handleInputChange}
          required
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button text={t("user_list_view.cancel")} onClick={handleClose} color={ButtonStyle.Gray} />
        <Button text={t("user_list_view.add")} onClick={handleAddReferential} color={ButtonStyle.Saving} />
      </DialogActions>
    </Dialog >
  );
};

export default ReferentialFormModal;
