import { Formik, FormikHelpers, Form } from "formik";
import { Box, TextField } from "@mui/material";
import { default as DocloopButton, ButtonStyle } from "components/Button";



import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import * as Yup from 'yup';
import { t } from "i18next";
import { MdSave } from "react-icons/md";
import userService from "services/user.service";
import { IUserPasswordUpdate, LoginStyle } from "models/user";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

export default function User() {
    const user = useSelector((state: RootState) => state.user.currentUser);
    const userValues = { ...user, password: '', newPassword: '', newPasswordCheck: '' } as IUserPasswordUpdate;


    const handleSubmit = async (values: IUserPasswordUpdate, { setSubmitting, resetForm }: FormikHelpers<IUserPasswordUpdate>) => {
        setSubmitting(true);
        const passwordUpdate = {
            email: values.email!,
            password: values.password!,
            newPassword: values.newPassword!
        }
        try {
            await userService.updatePassword(passwordUpdate);
            toast.success(t('my_profile.toast.success'));
            resetForm();
        } catch (err: any) {
            if (err.response && err.response.data && err.response.data.error) toast.error(err.response.data.error);
            else toast.error(err);
        } finally {
            setSubmitting(false);
        }
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('my_profile.form.email_required')),
        password: Yup.string().required(t('my_profile.form.password_required')),
        newPassword: Yup.string()
            .required(t('my_profile.form.new_password_required'))
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, t("my_profile.form.password_complexity_rules"))
            .notOneOf([Yup.ref('password')], t('my_profile.form.password_must_not_match')),
        newPasswordCheck: Yup.string()
            .required(t('my_profile.form.password_required'))
            .oneOf([Yup.ref('newPassword')], t('my_profile.form.password_must_match')),
    })


    if (!user) return <div>Loading user...</div>

    return (
        <div className="h-full">
            <Helmet>
                <title>{t("admin.users_management.my_password")}</title>
            </Helmet>
            <div className="px-2 py-2 border-b flex items-center select-none">
                <span className="ml-4 font-semibold grow">{t("admin.users_management.my_password")}</span>
            </div>
            <div className="p-10">
                <div className="w-1/3">
                    <Formik initialValues={userValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ isSubmitting, handleChange, values, errors, touched, submitForm }) => (
                            <Form>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        size="small"
                                        name="email"
                                        label={t("my_profile.form.email")}
                                        type="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="password"
                                        label={t("my_profile.form.password")}
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="newPassword"
                                        label={t("my_profile.form.new_password")}
                                        type="password"
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        error={touched.newPassword && Boolean(errors.newPassword)}
                                        helperText={touched.newPassword && errors.newPassword}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="newPasswordCheck"
                                        label={t("my_profile.form.confirm_new_password")}
                                        type="password"
                                        value={values.newPasswordCheck}
                                        onChange={handleChange}
                                        error={touched.newPasswordCheck && Boolean(errors.newPasswordCheck)}
                                        helperText={touched.newPasswordCheck && errors.newPasswordCheck}
                                    />
                                </Box>
                                <DocloopButton
                                    type="submit"
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                    text={t("my_profile.form.save")}
                                    leftIcon={<MdSave className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
                                    color={ButtonStyle.Saving}
                                    className="border"
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}