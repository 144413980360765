import React from "react";
import { t } from "i18next";
import Dropzone, { FileRejection } from "react-dropzone";
import { ImSpinner2 } from "react-icons/im";
import toast from "react-hot-toast";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { uploadDocuments } from "redux/documents";

import { MAX_UPLOAD_SIZE, MAX_UPLOAD_FILES_NB } from "config/constants";

type UploadDocumentZoneProps = {
  onUploadFinished: () => void;
};

export const UploadDocumentZone: React.FC<UploadDocumentZoneProps> = ({ onUploadFinished }) => {
  const dispatch = useAppDispatch();
  const { currentJobfile } = useAppSelector((state) => state.jobfile);
  const { uploadProgression } = useAppSelector((state) => state.documents);

  // This triggers only if at least one file is valid regarding react-dropzone criteria
  // i.e. accept, multiple, minSize and maxSize
  // In such case, trigger file upload with zip archive handling
  const onFilesDropAccepted = async (acceptedFiles: File[]) => {
    try {

      const result = await dispatch(uploadDocuments({ files: acceptedFiles, jobFile: currentJobfile ?? undefined }));
      if (!result?.payload) {
        // FIXME: We copy result to avoid Typescript error on result.error not existing
        const errorResult: any = { ...result }
        if (errorResult.error) {
          toast.error(t("document_upload_failed") + `\n${errorResult.error.message}`);
        } else {
          toast.error(t("document_upload_failed") + `\n${acceptedFiles.map((f) => f.name).join(", ")}`);
        }
      }

      // Trigger callback
      onUploadFinished();
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // This triggers only if at least one file is rejected
  // In this case, toast an error with file name and file rejection code
  const onFilesDropRejected = (fileRejections: FileRejection[]) => {
    let error = "";
    // If at least one fileRejection is too-many-files
    if (fileRejections.some((f) => f.errors.some((e) => e.code === "too-many-files"))) {
      error += t("document_upload_file_number_exceeded") + ` ${MAX_UPLOAD_FILES_NB}.`
    } else {
      fileRejections.forEach((f) => {
        error += `${f.file.name} (${Math.round(f.file.size / 1024 / 1024 * 10) / 10}MB): ${f.errors.map((e) => e.code).join("\n")}\n`
      });
    }
    toast.error(error, { duration: 8000, position: "top-center", style: { maxWidth: 500 } });
  };

  return (
    <Dropzone
      onDropAccepted={(acceptedFiles) => onFilesDropAccepted(acceptedFiles)}
      onDropRejected={(fileRejections) => onFilesDropRejected(fileRejections)}
      accept={{
        "application/pdf": [".pdf"],
        "application/x-zip-compressed": [".zip"],
      }}
      disabled={uploadProgression.filesCount > 0 ? true : false}
      maxSize={MAX_UPLOAD_SIZE}
      maxFiles={MAX_UPLOAD_FILES_NB}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections, acceptedFiles }) => (
        <div className="border-t border-docloop_borderColor py-2 px-2">
          <section
            className={`h-[100px] bg-slate-50 hover:bg-slate-100 border border-dashed transition-all ${uploadProgression.filesCount > 0 ? "cursor-not-allowed" : "cursor-pointer"
              } `}
          >
            <div {...getRootProps()} className="h-full w-full flex items-center justify-center">
              <input {...getInputProps()} />

              <p className="whitespace-pre-wrap block text-xs text-center italic text-slate-500 leading-5 px-2">
                {uploadProgression.filesCount > 0 ? (
                  <>
                    {t("jobfile_documents_list.uploading")} {uploadProgression.filesUploaded} / {uploadProgression.filesCount}
                    <ImSpinner2 className="inline ml-4 animate-spin text-lg" />
                    <br />
                    {uploadProgression.fileInProgress ? uploadProgression.fileInProgress : " "}
                  </>
                ) : (
                  t("jobfile_documents_list.upload_zone")
                )}
              </p>
            </div>
          </section>
        </div>
      )}
    </Dropzone>
  );
};
