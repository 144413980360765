import { DocumentStatus, IDocument, IDocumentCreatedBy } from "./document";
import { IUsecase } from "./usecase";


export enum JobfileStatusType {
  None = "none",
  ToValidate = "to_validate", // User must "Validate annotation" of the document(s) in the jobfile & then "Go to next step" in jobfile documents view
  ToExport = "to_export", // User must trigger the connector in jobfile validation view
  Processing = "processing", // Jobfile data are being processed (validate) or connector is working (export)
  Rejected = "rejected", // Jobfile is considered as "finished" but user hasn't been through the whole process for some reason
}

export interface JobfileStatus {
  type: JobfileStatusType,
  message: string,
}

export interface IJobFileDataEntry {
  value: any; // It is actually a string | { [key: string]: IJobFileDataEntry }[]
  original_value: string;
}
export interface IJobFile {
  _id: string;
  name: string;
  usecase: IUsecase | null;
  status: JobfileStatus;
  data: { [key: string]: IJobFileDataEntry };
  documents?: IDocument[];

  createdAt: Date;
  updatedAt: Date;
  createdBy: IDocumentCreatedBy;
}
export interface JobfileDocument {
  _id: string;
  name: string;
  status: DocumentStatus;
  doc_type?: string;
  createdAt: Date;
  file_url: string;
  jobfile?: string; // id of the jobfile if any
}

export type IConnectorExecutionResult = {
  success: boolean;
  error?: string;
  output?: {
    raw: string;
    raw_mimetype: string;
    raw_filename: string;
  };
  response?: object;
}

export type JobfilesSearchResponse = {
  data: IJobFile[];
  total: number;
};