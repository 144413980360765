import React from "react";

import { formatDate } from "utils/helpers";

import { DocumentStatusLabel } from "./DocumentStatusLabel";
import { DocumentDeleteButton } from "./DocumentDeleteButton";
import { IUsecase } from "models/usecase";
import { JobfileDocument } from "models/jobfile";

type DocumentListItemProps = {
  document: JobfileDocument;
  usecase?: IUsecase;
  onClick: () => void;
  selected?: boolean;
  disabled: boolean;
  onDocumentStateChange: () => void;
};

export const DocumentListItem: React.FC<DocumentListItemProps> = ({ document, usecase, onClick, selected = false, disabled = false, onDocumentStateChange }) => {
  return (
    <div
      className={`flex-column flex w-full select-none border-b border-docloop_borderColor ${selected ? "bg-sky-50 hover:bg-blue-50" : "bg-white hover:bg-gray-50"} px-3 py-3 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      onClick={onClick}
    >
      {selected && <DocumentDeleteButton document={document} onDeletion={onDocumentStateChange} />}
      <div className="grow pr-2 flex flex-col">
        <span className="line-clamp-2 break-all text-sm font-medium">{document.name}</span>
        {usecase && <span className={`self-start bg-sky-100 inline grow-0 shrink px-1.5 py-0.5 text-[9px] mt-1 rounded-sm font-medium`}>{usecase.name}</span>}
      </div>
      <div className="flex shrink-0 grow-0 flex-col items-end justify-start text-right">
        <span className="text-[12px] text-gray-400">{formatDate(document.createdAt)}</span>
        <div className="flex">
          <DocumentStatusLabel status={document.status} />
        </div>
      </div>
    </div>
  );
};
