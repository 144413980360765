import api from "./api";
import { INewUser, IUser, IUserUpdate, IPasswordUpdate, LoginStyle, IPasswordReset } from "models/user";

const USER_URL = "/user";

class UserService {
  async getCurrentUser(): Promise<IUser> {
    return (await api.get(`${USER_URL}/me`)).data;
  }

  async getCurrentUserLoginStyle(): Promise<LoginStyle> {
    return (await api.get(`${USER_URL}/me/login_style`)).data;
  }

  async saveSearchQuery(queryName: string, searchText: string, searchDocumentsTypes: string[]): Promise<IUser> {
    return api.post(`${USER_URL}/search_queries/save`, { queryName, searchText, searchDocumentsTypes }).then((response) => {
      return response.data as IUser;
    });
  }

  async deleteSearchQuery(queryName: string): Promise<IUser> {
    return api.post(`${USER_URL}/search_queries/delete`, { queryName }).then((response) => {
      return response.data as IUser;
    });
  }

  async getAllUsers(): Promise<IUser[]> {
    return api.get(`${USER_URL}/`).then((response) => {
      return response.data
    });
  }

  async createUser(newUser: INewUser) {
    return api.post(`${USER_URL}/`, newUser).then((response) => {
      return response.data
    });
  }

  async deleteUserById(userId: string): Promise<boolean> {
    return (await (api.delete(`${USER_URL}/${userId}`))).status === 200;
  }

  async updateUser(user: IUserUpdate): Promise<IUser> {
    return api.patch(`${USER_URL}/${user.email}`, user).then((response) => {
      return response.data
    });
  }

  async updatePassword(user: IPasswordUpdate): Promise<IUser> {
    return api.patch(`${USER_URL}/${user.email}/password`, user).then((response) => {
      return response.data
    });
  }

  async resetPassword(user: IPasswordReset): Promise<IUser> {
    return api.patch(`${USER_URL}/${user.email}/password/reset`, user).then((response) => {
      return response.data
    });
  }
}

const userService = new UserService();
export default userService;
