import Loading from "components/Loading";
import { t } from "i18next";
import { IReferential, IReferentialEntry } from "models/referential";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import referentialsService from "services/referentials.service";
import { ReferentialEntriesTableComponent, ReferentialEntriesTableRowComponent } from "../components/ReferentialEntriesTableComponents";
import { FaFileExport, FaFileImport } from "react-icons/fa";
import Button, { ButtonStyle } from "components/Button";
import { MAX_UPLOAD_SIZE } from "config/constants";
import toast from "react-hot-toast";

import Papa from 'papaparse';
import { HiX } from "react-icons/hi";
import { TablePagination, TextField } from "@mui/material";
import { paginate } from "utils/helpers";

const ACCEPTED_FILE_EXTENSIONS = [".csv"];
const ACCEPTED_MIMETYPES = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
const NB_ITEMS_PER_PAGE = 15;
export default function ReferentialConfiguration() {
  const [referential, setReferential] = React.useState<IReferential | null>(null);
  const [referentialEntries, setReferentialEntries] = React.useState<IReferentialEntry[]>([]);
  const [filteredEntries, setFilteredEntries] = React.useState<IReferentialEntry[]>([]);
  const [headers, setHeaders] = React.useState<string[]>([]);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  const fileInputRef = React.createRef<HTMLInputElement>();

  let { referentialId } = useParams();

  useEffect(() => {
    if (!referentialId) return;
    setReferential(null);
    loadReferential(referentialId);
  }, [referentialId]);

  const loadReferential = async (referentialId: string) => {
    try {
      const resRef = await referentialsService.getReferential(referentialId);
      if (resRef) {
        setReferential(resRef);
        const resRefEntries: IReferentialEntry[] = await referentialsService.getReferentialEntries(referentialId);
        setReferentialEntries(resRefEntries);
        setFilteredEntries(resRefEntries);
        //setHeaders(Object.keys(resRefEntries[0]).filter((key) => resRef.fields.includes(key)));
        // We always set the header to the fields for now if any
        setHeaders(resRef.fields)
      }
    } catch (err) {
      console.error(err)
    }
  }

  // Update referentialEntries based on searchInput
  useEffect(() => {
    const filteredItems =
      referentialEntries && searchInput?.length > 0
        ? referentialEntries.filter((item) => {
          return Object.keys(item).some((key) => {
            return item[key]?.toString().toLowerCase().includes(searchInput.trim().toLowerCase());
          });
        })
        : referentialEntries;
    setFilteredEntries(filteredItems);
  }, [searchInput, referentialEntries]);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  if (!referential) return <Loading />;

  function onExportClick(): void {
    if (!referential || referentialEntries.length === 0) return;

    // Create a semi-colon delimited CSV
    // With double-quote as content delimiter
    const csv = [
      headers.map((header: string) => `"${header}"`).join(";"), // Headers
      ...referentialEntries.map((refEntry: IReferentialEntry) => { // Values
        return headers.map((header: string) => `"${refEntry[header]}"`).join(";");
      })
    ].join("\n");
    // Create a Blob with the CSV data and make it available in web browser
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", referential.name + "_" + new Date().toISOString() + ".csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  }

  function onImportClick(): void {
    if (fileInputRef.current) fileInputRef.current.value = "";
    fileInputRef.current?.click();
  }

  const updateEntries = async (ref: IReferential, refEntries: IReferentialEntry[]) => {
    try {
      await referentialsService.updateReferentialFields(ref.id, ref.fields)
      const createdEntriesCount = await referentialsService.importReferentialEntries(ref.id, refEntries);
      toast.success(`${t("referential_configuration.import_success")} - ${createdEntriesCount} entries`);
    } catch (error) {
      console.error(error);
      toast.error(t("referential_configuration.import_error.generic"));
    }
  }

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!referential) return;
    const _files = e.target.files;
    if (!_files) {
      toast.error(t("referential_configuration.import_error.no_file"));
      return;
    }

    const _file = _files[0];

    if (_file.size > MAX_UPLOAD_SIZE) {
      toast.error(t("referential_configuration.import_error.size") + ` ${Math.round(MAX_UPLOAD_SIZE / 1024 / 1024 * 10) / 10} MB. (${Math.round(_file.size / 1024 / 1024 * 10) / 10} MB).`);
      return;
    };

    if (!ACCEPTED_MIMETYPES.includes(_file.type)) {
      toast.error(`${t("referential_configuration.import_error.rejected_mimetype")} ${_file.type}\n` + t("referential_configuration.import_error.accepted_mimetype") + ` ${ACCEPTED_FILE_EXTENSIONS.join(", ")}.`);
      return;
    };

    Papa.parse(_file, {
      header: true,  // Extract headers automatically
      skipEmptyLines: true,
      complete: async (result: any) => {
        const header = result.meta.fields;

        // Check headers exist
        if (!header || header.length === 0) {
          toast.error(t("referential_configuration.import_error.no_header"));
          return;
        }

        const refFields = referential.fields;
        if (refFields && refFields.length > 0) {
          // Compare header and fields
          if (refFields.length !== header.length || !refFields.every((value, index) => value === header[index])) {
            toast.error(t("referential_configuration.import_error.different_fields"));
            return;
          }
        }

        if (!refFields || refFields.length === 0) {
          referential.fields = header;
        }

        // Otherwise set referential fields to the headers
        const csvEntries: IReferentialEntry[] = result.data.map((e: any) => ({ ...e, referential: referential.id }));
        const toastId = toast.loading(t("referential_configuration.import_loading"));
        try {
          await updateEntries(referential, csvEntries)
          await loadReferential(referential.id)
          toast.dismiss(toastId);
        } catch (error) {
          console.error(error);
          toast.error(t("referential_configuration.import_error.generic"));
          toast.dismiss(toastId);
        } finally {
        }
      },
      error: (error: any) => {
        toast.error(t("referential_configuration.import_error.generic") + error);
      }
    });
  }

  return (
    <div className="h-full">
      <Helmet>
        <title>{t("admin.referentials.configuration.title")}</title>
      </Helmet>

      <div className="px-2 py-2 border-b flex items-center select-none mb-2">
        <span className="ml-4 font-semibold grow">{referential.name} ({referentialEntries.length})</span>
        <div className="relative flex items-center gap-1">
          <TextField
            value={searchInput}
            label={t("referential_configuration.search")}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder={t("referential_configuration.search.placeholder")}
            size="small"
          />
          <HiX
            className="absolute top-[50%] right-[8px] translate-y-[-50%] cursor-pointer text-xl text-gray-500 transition-colors hover:text-red-500"
            onClick={() => setSearchInput("")}
          />
        </div>
        <TablePagination component="div" count={filteredEntries.length} rowsPerPage={NB_ITEMS_PER_PAGE} rowsPerPageOptions={[]} page={currentPage} onPageChange={handlePageChange} />

        <div className="ml-3">
          <Button leftIcon={<FaFileExport className="inline mr-2" />} text={t("referential_configuration.export_button")} onClick={onExportClick} color={ButtonStyle.Saving} />
        </div>
        <div className="ml-3">
          <Button leftIcon={<FaFileImport className="inline mr-2" />} text={t("referential_configuration.import_button")} onClick={onImportClick} color={ButtonStyle.Primary} />
          <input className="hidden" type="file" onInput={handleFileInput} ref={fileInputRef} accept={`${ACCEPTED_FILE_EXTENSIONS.join(",")}`} />
          {/* <input className="hidden" type="file" onInput={handleFileInput} ref={fileInputRef} accept="*.csv" /> */}
        </div>
      </div>
      <div className="p-2">
        {headers && headers.length > 0 &&

          <ReferentialEntriesTableComponent headers={[
            ...headers,
            '']}>
            {filteredEntries && filteredEntries.length > 0 && paginate(filteredEntries, currentPage, NB_ITEMS_PER_PAGE).map((refEntry: IReferentialEntry) => (
              <ReferentialEntriesTableRowComponent
                key={refEntry._id}
                values={[
                  ...headers.map((header) => {
                    return { value: refEntry[header] as string };
                  }),
                  { value: '' },
                ]}
                onDelete={() => {
                  console.log("WIP")
                }}
                onClick={() => {
                  console.log("WIP")
                }}
              />
            ))}
          </ReferentialEntriesTableComponent>
        }

      </div>
    </div>

  )
}