import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setBreadcrumbsMenuItems } from "redux/navigation";

import CatalogService from "services/catalog.service";
import { SearchBar } from "./components/SearchBar";
import { SavedQueries } from "./components/SavedQueries";
import { DocumentsTable } from "./components/DocumentsTable";
import { JobfilesTable } from "./components/JobfilesTable";
import { SearchTypeSelector, SearchType } from "./components/SearchTypeSelector";
import { SearchDocTypesSelector } from "./components/SearchDocTypesSelector";
import { NewMenu } from "./components/NewMenu";
import { CatalogSearchResponse, DocumentSearchHit, JobFileSearchHit } from "models/catalog";
import { UploadDocumentZone } from "../jobfile/documents/components/UploadDocumentZone";
import { SearchJobfileStatusesSelector } from "./components/SearchJobfileStatusesSelector";
import { SearchDocStatusesSelector } from "./components/SearchDocStatusesSelector";
import { setSearchDocumentStatuses, setSearchDocumentsTypes, setSearchJobfileStatuses, setSearchText, setSearchType } from "redux/search";
import { JobfileStatus, JobfileStatusType } from "models/jobfile";
import { DocumentStatus } from "models/document";

export default function Home() {
  const dispatch = useAppDispatch();
  const location = useLocation(); // Read URL
  const navigate = useNavigate() // Update
  const { searchType, searchText, searchDocumentsTypes, searchDocumentStatuses, searchJobfileStatuses } = useAppSelector((state) => state.search);

  const [documents, setDocuments] = useState<DocumentSearchHit[]>([]);
  const [jobfiles, setJobfiles] = useState<JobFileSearchHit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  // Reset navigation breadcrumbs on page loading
  useEffect(() => {
    dispatch(setBreadcrumbsMenuItems(null));
  // Initialize state from URL params on component mount
    const params = new URLSearchParams(location.search);

    const searchTextQuery = params.get("q");
    if (searchTextQuery) dispatch(setSearchText(searchTextQuery));

    const searchTypeQuery = params.get("t");
    const st = SearchType[searchTypeQuery as keyof typeof SearchType]
    if (st) {
      dispatch(setSearchType(searchType));
    }
    const searchDocumentsTypesQuery = params.get("dts");
    if (searchDocumentsTypesQuery) dispatch(setSearchDocumentsTypes(searchDocumentsTypesQuery.split(",")));

    const searchDocumentStatusesQuery = params.get("dcs");
    if (searchDocumentStatusesQuery) dispatch(setSearchDocumentStatuses(searchDocumentStatusesQuery.split(",") as DocumentStatus[]));
   
    const searchJobfileStatusesQuery = params.get("jfs");
    if (searchJobfileStatusesQuery) dispatch(setSearchJobfileStatuses(searchJobfileStatusesQuery.split(",") as JobfileStatusType[]));
   

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("t", searchType);
    params.set("q", searchText);
    params.set("dts", searchDocumentsTypes.join(","));
    params.set("dcs", searchDocumentStatuses.join(","));
    params.set("jfs", searchJobfileStatuses.join(","));

    navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });

    reloadSearch(searchType)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, searchText, searchDocumentsTypes, searchDocumentStatuses, searchJobfileStatuses]);

  //
  // APIs Calls
  //
  
  const searchDocuments = async () => {
    setIsLoading(true);
    setDocuments([]);
    const res: CatalogSearchResponse = await CatalogService.search(SearchType.Documents, searchText, searchDocumentsTypes);
    setDocuments(res.hits.documents.hits);
    setIsLoading(false);
  };
  const searchJobfiles = async () => {
    setIsLoading(true);
    setJobfiles([]);
    const res: CatalogSearchResponse = await CatalogService.search(SearchType.Jobfiles, searchText, searchDocumentsTypes);
    setJobfiles(res.hits.jobfiles.hits);
    setIsLoading(false);
  }

  const reloadSearch = async (searchType: SearchType) => {
    switch (searchType) {
      case SearchType.Documents: {
        searchDocuments();
        break;
      }
      case SearchType.Jobfiles: {
        searchJobfiles();
        break;
      }
      default:
        console.error(`Unhandled search type: ${searchType}`);
        break;
    }
  };

  //
  // Render
  //
  return (
    <div className="h-full px-2">
      <Helmet>
        <title>Docloop</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} xl={2}>
          <SavedQueries />
          <UploadDocumentZone
            onUploadFinished={() => {
              dispatch(setSearchType(SearchType.Documents));
              searchDocuments();
            }}
          />
        </Grid>
        <Grid item xs={9} md={8} xl={8}>
          <div className="flex flex-row">
            <SearchBar />
            <NewMenu
              reloadSearch={(searchType: SearchType) => {
                dispatch(setSearchType(searchType));
                reloadSearch(searchType);
              }}
            />
          </div>
          <div className="flex flex-row mb-3 items-center">
            <SearchTypeSelector type={searchType} onChange={(type) => dispatch(setSearchType(type))} />
            <SearchDocTypesSelector />
            {searchType === SearchType.Documents && <SearchDocStatusesSelector />}
            {searchType === SearchType.Jobfiles && <SearchJobfileStatusesSelector />}
          </div>

          {searchType === SearchType.Documents && <DocumentsTable documents={documents} loading={isLoading} reloadSearch={searchDocuments} />}
          {searchType === SearchType.Jobfiles && <JobfilesTable jobfiles={jobfiles} loading={isLoading} reloadSearch={searchJobfiles} />}
        </Grid>
      </Grid>
    </div>
  );
}
