import React, { useState, FocusEvent } from "react";
import { debounce } from "lodash";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setHeuristicsMetadata, updateDocumentFieldLocally } from "redux/labelling";
import { setFocusedField } from "redux/labelling";

import { IDocumentTypeField } from "models/document_type";
import { DocumentFieldOrigin, DocumentFieldUpdateRequest, IDocumentField } from "models/document";
import DocumentsService from "services/documents.service";

import InputField from "./fields";
import { LABELLING_SAVE_DEBOUNCE_TIME } from "config/constants";
import { getColorForFieldOrigin, scrollToPageFromField } from "utils/labelling";
import { OcrWord } from "types/labelling";
import { t } from "i18next";
import toast from "react-hot-toast";

import { RxCrossCircled } from "react-icons/rx";

type DocumentFieldProps = {
  id: string;
  field: IDocumentTypeField;
  documentField?: IDocumentField;
};

const DocumentField: React.FC<DocumentFieldProps> = ({ id, field, documentField }) => {
  const dispatch = useAppDispatch();
  const labellingState = useAppSelector((state) => state.labelling);
  const [value, setValue] = useState<any>(documentField?.value ?? "");
  const [origin, setOrigin] = useState<DocumentFieldOrigin | null>(documentField?.origin ?? null);
  const [mapping, setMapping] = useState<OcrWord[]>(documentField?.mapping ?? []);

  // Declare onChange function with a debouncing feature
  // Debounce is used to prevent too much requets to backend
  // Explanation here: https://www.developerway.com/posts/debouncing-in-react
  const debouncedSave = React.useRef(
    debounce(async (fieldUpdate: DocumentFieldUpdateRequest) => {
      if (!labellingState.currentDocument) return;
      try {
        const heuristicsData = await DocumentsService.updateDocumentField(labellingState.currentDocument._id, fieldUpdate);
        toast.success(t("labelling_panel.toaster_successful_mapping"));
        dispatch(setHeuristicsMetadata(heuristicsData));
      } catch (e) {
        console.log(e);
        toast.error(t("labelling_panel.toaster_failed_mapping"));
      }
    }, LABELLING_SAVE_DEBOUNCE_TIME)
  ).current;

  // On change callback when the value is updated
  const onChange = (fieldUpdate: DocumentFieldUpdateRequest | null) => {
    // If the fieldUpdate object is null or with an empty value, then we reset the field
    if (!fieldUpdate || !fieldUpdate.value || fieldUpdate.value === "") {
      fieldUpdate = {
        field_id: field.technicalName,
        field_type: field.type,
        value: "",
        origin: DocumentFieldOrigin.None,
        mapping: [],
      };
    }

    setValue(fieldUpdate.value);
    setOrigin(fieldUpdate.origin);
    setMapping(fieldUpdate.mapping);
    dispatch(updateDocumentFieldLocally(fieldUpdate));
    debouncedSave(fieldUpdate);
  };

  // On change callback when the input is focused
  const onFocus = (e: FocusEvent<HTMLElement>) => {
    dispatch(setFocusedField({ field }));

    scrollToPageFromField(labellingState.pagesMetadata, mapping);
  };

  const isFieldFocused = labellingState.focusedField?.technicalName === field.technicalName;

  //
  // Rendering
  //
  return (
    <div className={`relative rounded mx-2 mt-2 flex flex-row`} style={{ backgroundColor: `${getColorForFieldOrigin(isFieldFocused ? origin : null)}30` }}>
      <div className="flex items-center grow-0 w-52">
        <span className={`text-xs font-medium ml-2 block ${isFieldFocused ? "text-slate-700" : "text-slate-500"}`}>{t(`documentType.${field.technicalName}`, field.technicalName)}</span>
        {value && (
          <button
            className="text-xs font-medium ml-2 block text-slate-400 hover:text-slate-700" title={t("labelling_panel.reset_field")}
            onClick={() => onChange(null)}
            tabIndex={-1}
            disabled={labellingState.readOnly}
          >
            <RxCrossCircled />
          </button>
        )}
      </div>
      <InputField id={id} field={field} type={field.type} isFocused={isFieldFocused} value={value} origin={origin} mapping={mapping} onChange={onChange} onFocus={onFocus} />
    </div>
  );
};

export default DocumentField;
