import React, { useEffect, useRef, useState } from "react";
import { pdfjs, Document } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { LabellingState, setHeuristicsMetadata } from "redux/labelling";
import { DocumentStatus, IDocument } from "models/document";
import { SelectionZone } from "utils/labelling";

import Loading from "components/Loading";
import PageViewerToolbar, { PageZoom } from "./components/PageViewerToolbar";
import CustomPage from "./components/CustomPage";
import AnnotationModal from "../AnnotationModal";

import DocumentsService from "services/documents.service";

// FIXME: Kludge for an unknown reason the webpack configured under CRA will
// export a pdf.worker.min.js with requires() statements, probably because he
// tries to compile at load when resolving the import.meta.url, consequence is
// the resulting file is not readable in Chrome. Also it seems that js files
// are skipped from file loading with the CRA webpack config
// IMPORTANT: this requires that the dockerfile for prod copies BOTH the min.js and
// the map file to the static location
if (process.env.NODE_ENV === "production") {
  pdfjs.GlobalWorkerOptions.workerSrc = "/static/media/pdf.worker.min.js";
} else {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
}

const MAX_DOCUMENT_WIDTH = 800;

type PageViewerProps = {
  doc?: IDocument;
  annotationEnabled?: boolean;
};

export const PageViewer: React.FC<PageViewerProps> = ({ doc = null, annotationEnabled = false }) => {

  const dispatch = useAppDispatch();
  const labellingState: LabellingState = useAppSelector((state) => state.labelling);

  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const pagesRenderingOrientation = useRef<{ [key: string]: number }>({}); // PdfJs pages rendering orientation

  const [pagesCount, setPagesCount] = useState<number | null>(null); // Document pages count
  const [activePageNumber, setActivePageNumber] = useState(1); // Document current page
  const [pageZoom, setPageZoom] = useState<PageZoom>(annotationEnabled && localStorage.getItem("docloop.pageZoom") ? Number(localStorage.getItem("docloop.pageZoom")) : PageZoom.DEFAULT); // Current page zoom
  const [pageDisplayWidth, setPageDisplayWidth] = useState<number>();

  const [currentDocumentFileUrl, setCurrentDocumentFileUrl] = useState<string>();
  const [currentDocumentLayoutMeta, setCurrentDocumentLayoutMeta] = useState<{}>();

  const [lastSelectedZone, setLastSelectedZone] = useState<SelectionZone | null>(null); // User last selected zone

  // Important line: choose on which document we will use for this pdfViewer. Redux state or document in props
  const currentDocument = doc ? doc : labellingState.currentDocument;

  // Each time we init or switch the current document we first retrieve the layout headers (said meta)
  // This tells us the layout is available without having to perform many calls for each page
  // It also triggers an analysis in the backend if the layout is not present and fixes any mismatch between
  // the storage and the DB
  useEffect(() => {
    if (!currentDocument?._id) return;
    // if we switch current document we are not sure it comes from an get document to the backend
    // hence the sas token may be outdated and it will not be possible to retrieve the file
    // so we make an explicit call to the backend to get the url refreshed and not rely on IDoc
    DocumentsService.getDocumentFileUrl(currentDocument._id)
      .then((url) => setCurrentDocumentFileUrl(url))
      .catch((err) => console.log(err));

    DocumentsService.getDocumentLayoutMeta(currentDocument._id)
      .then((layout_meta) => setCurrentDocumentLayoutMeta(layout_meta))
      .catch((err) => console.log(err));

    DocumentsService.getDocumentHeuristicsMeta(currentDocument._id)
      .then((heuristics_meta) => dispatch(setHeuristicsMetadata(heuristics_meta)))
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument?._id]);

  // The ResizeObserver listens to the Document containerRef, that is the container div of <Document>
  // on change size the containerWidth state is updated to re-render the pdf pages
  useEffect(() => {
    if (!containerRef) return;

    const observer = new ResizeObserver(() => {
      if (containerRef.clientWidth) {
        setContainerWidth(containerRef.clientWidth);
      }
      if (containerRef.clientHeight) {
        setContainerHeight(containerRef.clientHeight);
      }
    });
    observer.observe(containerRef);

    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  // Listener on mouse wheel events for FIT_TO_PAGE mode
  // In Fit to page zoom level and Ctrl not pressed, we override the standard behaviour
  // Scroll up means "Focus on previous page", Scroll down means "Focus on next page"
  useEffect(() => {
    applyPageZoom(pageZoom);

    // Listen pageDown / pageUp events
    const handleKeyDown = (event: KeyboardEvent) => {
      handlePageKeyboard(event);
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageZoom, activePageNumber, currentDocument, containerHeight, containerWidth]);

  //
  // Callback of react-pdf for PDFs display
  //
  const onDocumentLoadSuccess = async (pdf: PDFDocumentProxy) => {
    console.log("On document load success");
    setPagesCount(pdf.numPages);

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      pagesRenderingOrientation.current[`page_${i}`] = page.rotate;
    }
  };

  const onPdfScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (labellingState.currentDocument && labellingState.currentDocument.meta.num_pages) {
      // Calculate the active page "float" index
      const p = (labellingState.currentDocument.meta.num_pages * e.currentTarget.scrollTop) / e.currentTarget.scrollHeight + 1;
      // Round to the nearest page
      if (p !== activePageNumber) setActivePageNumber(Math.round(p));
    }
  };

  // Apply user-selected page zoom
  // This is used at time of initAnnotationCanvas and each time page viewer dimensions change
  const applyPageZoom = (zoom: PageZoom) => {
    switch (zoom) {
      case PageZoom.FIT_TO_PAGE:
        // Rule of three to get final width from canvas size and page viewer div size
        const firstPageCanvas = document.getElementById(`page-canvas-1`);
        if (firstPageCanvas && containerWidth && containerHeight) {
          const canvas = firstPageCanvas as HTMLCanvasElement;
          // Round to avoid tiny pixel difference between canvas
          const newContainerWidth = Math.round(Math.min(containerHeight * 0.95 * (canvas.width / canvas.height), containerWidth) / 10) * 10;
          // Avoid infinite re-render
          if (newContainerWidth !== containerWidth) setPageDisplayWidth(newContainerWidth);
        }
        break;
      case PageZoom.FIT_TO_WIDTH:
        // Maximize width
        setPageDisplayWidth(containerWidth);
        break;
      case PageZoom.DEFAULT:
        // Default tradeoff: not too large but as large as possible
        setPageDisplayWidth(containerWidth ? Math.min(containerWidth, MAX_DOCUMENT_WIDTH) : MAX_DOCUMENT_WIDTH);
    }
  };

  // Listen to PageUp and PageDown to focus previous/next page instantly
  const handlePageKeyboard = (event: KeyboardEvent) => {
    let scrollToPageNumber: number | null = null;

    if (event.key === "PageUp") {
      scrollToPageNumber = activePageNumber > 1 ? activePageNumber - 1 : 1;
    } else if (event.key === "PageDown" && pagesCount !== null) {
      scrollToPageNumber = activePageNumber < pagesCount ? activePageNumber + 1 : pagesCount;
    }

    // If a page has been identified, scroll to it
    if (scrollToPageNumber !== null) {
      event.preventDefault();

      const canvasAnnotation = document.getElementById(`page-canvas-${scrollToPageNumber}`);
      if (canvasAnnotation) {
        canvasAnnotation.scrollIntoView({ behavior: "instant", block: "start" });
      }
    }
  };

  //
  // Rendering
  //
  return (
    <>
      <div id="page-viewer" onScroll={onPdfScroll} className={"bg-gray-500 relative z-0 flex grow flex-col p-3 items-center overflow-x-hidden overflow-y-scroll h-full"} ref={setContainerRef}>
        {currentDocument && currentDocumentFileUrl && currentDocumentLayoutMeta && (
          <div className={"w-full px-2 "}>
            <Document
              className={"flex flex-col items-center w-full px-2"}
              file={currentDocumentFileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loading className="bg-white px-10 py-6 rounded-md shadow-md mt-10" />}
            >
              {Array.from(new Array(pagesCount || 0), (el, index) => {
                // if pagesCount is undefined put 0
                const pageNumber = index + 1;
                return (
                  <CustomPage
                    key={`page-${pageNumber}`}
                    doc={currentDocument}
                    pageNumber={pageNumber}
                    pageDisplayWidth={pageDisplayWidth}
                    documentReady={pagesCount !== null ? true : false} // If pagesCount is not null, then the document ready is ready
                    renderingOrientation={pagesRenderingOrientation.current[`page_${pageNumber}`] ?? 0}
                    annotationEnabled={annotationEnabled}
                    onUserZoneSelection={(zone: SelectionZone) => setLastSelectedZone(zone)}
                  />
                );
              })}
            </Document>
          </div>
        )}
      </div>
      <PageViewerToolbar
        doc={currentDocument}
        pageZoom={pageZoom}
        onPageZoomChange={(zoom) => setPageZoom(zoom)}
        showZoomActions={annotationEnabled}
        activePage={activePageNumber}
        pagesCount={pagesCount ?? 0}
      />

      {currentDocument && currentDocument.status !== DocumentStatus.None && <AnnotationModal selectedZone={lastSelectedZone} />}
    </>
  );
};
