import React from "react";
import { t } from "i18next";

import Select, { ActionMeta, MultiValue } from "react-select";

import { selectCustomStyles } from "components/SelectSearch";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSearchDocumentStatuses } from "redux/search";
import { DocumentStatus } from "models/document";

type SearchTypeProps = {};

export const SearchDocStatusesSelector: React.FC<SearchTypeProps> = () => {
  const dispatch = useAppDispatch();
  const { searchDocumentStatuses } = useAppSelector((state) => state.search);

  const onSelect = async (newValue: DocumentStatus[]) => {
    dispatch(setSearchDocumentStatuses(newValue));
  };

  // Rendering
  return (
    <Select
      classNamePrefix="docStatus_select"
      className="ml-2 text-xs"
      // isSearchable
      isMulti
      isClearable={true}
      name="doc_status"
      value={searchDocumentStatuses.map((s: DocumentStatus) => ({ value: s, label: t(`document_status_${s}`) }))}
      placeholder={t("home.filters.doc_statuses.placeholder")}
      options={Object.values(DocumentStatus).map((s) => ({ value: s, label: t(`document_status_${s}`) }))}
      onChange={(newValue: MultiValue<any>, actionMeta: ActionMeta<unknown>) => onSelect(newValue.map((v) => v.value as DocumentStatus))}
      styles={selectCustomStyles}
      tabSelectsValue={false}
    />
  );
};
