import { ConnectorType, IExporter } from "./connector";
import { IDocumentType } from "./document_type";

export interface IProcessor {
  type: string;
  [key: string]: any;
}

export interface IUsecaseSectionDataField {
  id: string; // Id that will be used to access the field inside the connector. Ex: "dispatch.customerCode", "dispatch.mainSellPrice",
  label: string; // Label that will be display in the configuration screen and in the jobfile screen also
  processor: IProcessor; // Processor used to compute the value
  required: boolean; // Required means that the field must not be empty when connector is triggered from jobfile validation
}

export enum IUsecaseSectionType {
  KV = "key_value",
  TABLE = "table",
  KV_TABLE = "key_value_table",
}
export interface IUsecaseSection {
  id: string; // Internal section name
  type: IUsecaseSectionType; // Three types
  document_type: string;
  name: string; // Section name used for display
  table_id?: string; // Optional: table field technicalName
  data: IUsecaseSectionDataField[]; // List of fields
}

export interface INewUsecase {
  name: string;
}

export interface IUsecaseAutomation {
  enabled: boolean;
  inbound_email: string;
  notification_to_sender: boolean;
  notification_emails: string[];
}

export interface IUsecase extends INewUsecase {
  _id: string;
  downloaders: string[];
  exporters?: IExporter[];
  sections: IUsecaseSection[];
  docTypes: IDocumentType[];
  automation: IUsecaseAutomation;
}

// Available processors
export const AVAILABLE_PROCESSORS = ["fixed_value", "doc_field", "format_date", "format_number", "substring", "referential_lookup", ConnectorType.DISPATCH_MISSION];
export const AVAILABLE_EXPORT_FORMATTERS = [
  "agl_pegase_csv_create_order",
  "agl_guce_xlsx_create_order",
  "agl_spot_text_create_order",
  "granger_dispatch_json_create_mission",
  "asavinter_dispatch_json_create_mission",
  "asavinter_dispatch_json_update_quotation",
  "chatel_item_csv_create_mission",
  "std_akanea_xml_create_detail_note",
  "jdi_akanea_xml_create_detail_note",
  "std_dispatch_json_create_mission",
  "std_docloop_json",
  "technotrans_aeolus_aeo_create_order",
  "technotrans_aeolus_csv_import_invoice",
  "transcausse_unk_csv_import_invoice"
]
