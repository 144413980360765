import React, { useEffect, useState } from "react";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { TablePagination, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { MdDeleteOutline } from "react-icons/md";
import { LuWorkflow } from "react-icons/lu";
import { ImSpinner2 } from "react-icons/im";
import { IoDocumentsOutline } from "react-icons/io5";

import { useAppSelector } from "redux/hooks";
import { JobFileSearchHit } from "models/catalog";
import { JobfileStatus, JobfileStatusType } from "models/jobfile";
import { formatDate, paginate } from "utils/helpers";
import JobFilesService from "services/jobfiles.service";

import UserBadge from "components/UserBadge";
import Loading from "components/Loading";

const NB_ITEMS_PER_PAGE = 10;

type JobfilesTableProps = {
  loading: boolean;
  jobfiles: JobFileSearchHit[];

  reloadSearch: () => void;
};

export const JobfilesTable: React.FC<JobfilesTableProps> = ({ loading, jobfiles, reloadSearch }) => {
  const { searchJobfileStatuses } = useAppSelector((state) => state.search);

  const { useCases } = useAppSelector((state) => state.usecases);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedJobfiles, setSelectedJobfiles] = useState<JobFileSearchHit[]>(jobfiles);


  const navigate = useNavigate();

  useEffect(() => {
    if (!jobfiles) return;
    if (!searchJobfileStatuses || searchJobfileStatuses.length === 0) setSelectedJobfiles(jobfiles);
    else {
      const newSelectedJobfiles = jobfiles.filter((doc: JobFileSearchHit) => searchJobfileStatuses.includes(doc.status.type));
      setSelectedJobfiles(newSelectedJobfiles);
    }
  }, [searchJobfileStatuses, jobfiles]);


  // Helper that return usecase name based on an id
  const getUseCaseName = (id: string): string => {
    const usecase = useCases.find((u) => u._id === id);
    return usecase?.name ?? "unknown";
  };

  //
  // UI Actions
  //
  const handlePageChange = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const onClickJobfileDeletion = async (jobfileId: string, jobfileName: string) => {
    if (window.confirm(t("home.list_item.actions.delete_jobfile_confirm"))) {
      try {
        await JobFilesService.deleteJobfile(jobfileId);
        toast.success(t("home.list_item.actions.jobfile_deleted", { name: jobfileName }));
      } catch (error) {
        toast.error(t("home.list_item.actions.jobfile_deletion_error"));
      } finally {
        reloadSearch();
      }
    }
  };

  //
  // Render
  //

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            "& .MuiTableCell-sizeMedium": {
              padding: "10px 15px",
            },
          }}
        >
          <TableHead style={{ padding: "2px 4px !important" }}>
            <TableRow sx={{ userSelect: "none" }}>
              <TableCell component="th"></TableCell>
              <TableCell component="th" width={90}>
                <b>{t("home.table.columns.creation_date")}</b>
              </TableCell>
              <TableCell component="th" width={300}>
                <b>{t("home.table.columns.status")}</b>
              </TableCell>
              <TableCell component="th" width={100}>
                <b>{t("home.table.columns.createdBy")}</b>
              </TableCell>
              <TableCell component="th" width={30} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={4}>
                  <Loading className="my-10" />
                </TableCell>
              </TableRow>
            )}
            {!loading && selectedJobfiles.length === 0 && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={4}>
                  <span className="my-10 block text-center font-semibold">{t("home.table.no_result")}</span>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              selectedJobfiles.length > 0 &&
              paginate(selectedJobfiles, currentPage, NB_ITEMS_PER_PAGE).map((jobFile: JobFileSearchHit, index) => {
                return (
                  <TableRow
                    key={`jobFile-${jobFile._id}`}
                    hover={true}
                    sx={{
                      userSelect: "none",
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&.MuiTableRow-root:hover": {
                        backgroundColor: "rgba(180,180,180,0.05)",
                      },
                      "td:last-child": { padding: 0, opacity: 0 },
                      "&.MuiTableRow-root:hover td:last-child": { opacity: 1 },
                    }}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      const targetUrl = ([JobfileStatusType.ToExport].includes(jobFile.status.type)) ? `/jobfile/${jobFile._id}/validation` : `/jobfile/${jobFile._id}/documents`;
                      // Open a new tab if Ctrl+Click, Cmd+Click, or Middle Mouse Button Click
                      if (event.ctrlKey || event.metaKey || event.button === 1) {
                        event.preventDefault();  // Prevent the default behavior for the middle mouse button click
                        window.open(targetUrl, '_blank');
                      } else {
                        navigate(targetUrl);
                      }
                    }}
                    onAuxClick={(event: React.MouseEvent<HTMLElement>) => {
                      const targetUrl = ([JobfileStatusType.ToExport].includes(jobFile.status.type)) ? `/jobfile/${jobFile._id}/validation` : `/jobfile/${jobFile._id}/documents`;
                      // Open a new tab on Middle Mouse Button Click
                      if (event.button === 1) {
                        event.preventDefault();  // Prevent the default behavior for the middle mouse button click
                        window.open(targetUrl, '_blank');
                      }
                    }}
                  >
                    <TableCell>
                      <span className="font-bold float-left">{jobFile.name}</span>
                      {jobFile.usecase && (
                        <div className="float-left clear-both text-[11px] px-2 py-1 mt-1 bg-slate-100 rounded-md">
                          <LuWorkflow className="inline mr-2 text-sm" />
                          {getUseCaseName(jobFile.usecase)}
                        </div>
                      )}
                      <div className="float-left clear-both text-[11px] px-2 py-1 mt-1 bg-slate-100 rounded-md">
                        <div>
                          <IoDocumentsOutline className="inline mr-2 text-sm" />
                          {t("home.table.documents_count_ordinal", { count: jobFile.documents?.length ?? 0 })}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={formatDate(jobFile.createdAt, "DD/MM/YYYY - HH:mm")}>
                        <span className="text-xs">{formatDate(jobFile.createdAt, "D MMM.")}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <JobState status={jobFile.status} />
                    </TableCell>
                    <TableCell>{jobFile.createdBy && <UserBadge email={jobFile.createdBy.email} firstName={jobFile.createdBy.firstname} lastName={jobFile.createdBy.lastname} />}</TableCell>
                    <TableCell onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                      <Tooltip title={t("home.list_item.actions.delete_jobfile")}>
                        <button onClick={() => onClickJobfileDeletion(jobFile._id, jobFile.name)} className="cursor-pointer opacity-30 hover:opacity-100 p-2 mr-1">
                          <MdDeleteOutline className="text-xl" />
                        </button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination component="div" count={selectedJobfiles.length} rowsPerPage={NB_ITEMS_PER_PAGE} rowsPerPageOptions={[]} page={currentPage} onPageChange={handlePageChange} />
    </>
  );
};

type JobStateProps = {
  status: JobfileStatus;
};

const JobState: React.FC<JobStateProps> = ({ status }) => {
  let text = "";
  let colorClass = "bg-slate-100";

  switch (status.type) {
    case JobfileStatusType.None:
      return;
    case JobfileStatusType.ToValidate:
      text = t("jobfile_status_" + JobfileStatusType.ToValidate);
      colorClass = "bg-yellow-200 text-yellow-600";
      return <div className={`px-2 py-1 rounded-md float-left font-semibold text-[11px] ${colorClass}`}>{text}</div>;

    case JobfileStatusType.ToExport:
      text = t("jobfile_status_" + JobfileStatusType.ToExport);
      colorClass = "bg-blue-200 text-blue-600";
      return <div className={`px-2 py-1 rounded-md float-left font-semibold text-[11px] ${colorClass}`}>{text}</div>;

    case JobfileStatusType.Processing:
      text = t("jobfile_status_" + JobfileStatusType.Processing);
      colorClass = "bg-stone-200 text-stone-600";
      return (
        <div className={`px-2 py-1 rounded-md float-left font-semibold text-[11px] ${colorClass}`}>
          {text} <ImSpinner2 className="inline ml-1 animate-spin text-xs" />
        </div>
      );

    case JobfileStatusType.Rejected:
      text = t("jobfile_status_" + JobfileStatusType.Rejected);
      colorClass = "bg-red-200 text-red-600";
      return <div className={`px-2 py-1 rounded-md float-left font-semibold text-[11px] ${colorClass}`}>{text}</div>;

    default:
      text = t("jobfile_status_" + status.type);
      colorClass = "bg-gray-200 text-gray-600";
      return (
        <div className={`px-2 py-1 rounded-md float-left font-semibold text-[11px] ${colorClass}`}>
          {text} {status.type}
        </div>
      );
  }
};
