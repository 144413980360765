import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { t } from "i18next";
import { HiOutlineXCircle } from "react-icons/hi2";

import { IProcessor } from "models/usecase";
import { IReferential } from "models/referential";
import { IDocumentTypeField } from "models/document_type";

import DocFieldSelector from "./DocFieldSelector";

type ReferentialLookupInputProps = {
  processor: IProcessor;
  referentials: IReferential[];
  usableDocumentsFields: IDocumentTypeField[];
  onChange: (proc: any) => void;
};

const ReferentialLookupInput: React.FC<ReferentialLookupInputProps> = ({ processor, referentials, usableDocumentsFields, onChange }) => {
  const selectedReferential = referentials.find((r) => r.id === processor.referential_id);

  return (
    <div className="w-full flex flex-col">
      <Stack spacing={1.5}>
        <FormControl fullWidth size="small">
          <InputLabel required>{t("processor.referential_lookup.referential")}</InputLabel>
          <Select
            value={selectedReferential?.id ?? "-1"}
            required
            label={t("processor.referential_lookup.referential")}
            onChange={(e) => onChange({ ...processor, referential_id: e.target.value })}
            SelectDisplayProps={{ style: { padding: "2px 30px 2px 6px", fontSize: 12 } }}
          >
            {referentials.map((ref, index) => (
              <MenuItem key={`ref_${index}_${ref.name}`} value={ref.id} style={{ fontSize: 12 }}>
                {ref.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedReferential && (
          <>
            <FormControl fullWidth size="small">
              <InputLabel required>{t("processor.referential_lookup.input_field")}</InputLabel>
              <Select
                value={processor.input_field ?? "-1"}
                required
                label={t("processor.referential_lookup.input_field")}
                onChange={(e) => onChange({ ...processor, input_field: e.target.value })}
                SelectDisplayProps={{ style: { padding: "2px 30px 2px 6px", fontSize: 12 } }}
              >
                {selectedReferential.fields.map((field, index) => (
                  <MenuItem key={`field_${index}_${field}`} value={field} style={{ fontSize: 12 }}>
                    {field}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel required>{t("processor.referential_lookup.output_field")}</InputLabel>
              <Select
                value={processor.output_field ?? "-1"}
                required
                label={t("processor.referential_lookup.output_field")}
                onChange={(e) => onChange({ ...processor, output_field: e.target.value })}
                SelectDisplayProps={{ style: { padding: "2px 30px 2px 6px", fontSize: 12 } }}
              >
                {selectedReferential.fields.map((field, index) => (
                  <MenuItem key={`field_${index}_${field}`} value={field} style={{ fontSize: 12 }}>
                    {field}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="flex">
              <DocFieldSelector
                processor={{ ...processor, field: processor.default_search_field }}
                onChange={(settings: any) => onChange({ ...processor, default_search_field: settings.field })}
                usableDocumentsFields={usableDocumentsFields}
                label={t("processor.referential_lookup.default_search")}
                required={false}
              />
              {processor.default_search_field && (
                <div
                  className="grow-0 px-1 ml-1 py-1 cursor-pointer opacity-40 hover:opacity-70"
                  onClick={() => onChange({ ...processor, default_search_field: null })}
                  title={t("jobfile_validation.field_reset")}
                >
                  <HiOutlineXCircle />
                </div>
              )}
            </div>
          </>
        )}
      </Stack>
    </div>
  );
};

export default ReferentialLookupInput;
