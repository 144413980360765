import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import Select, { MultiValue } from "react-select";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";
import toast from "react-hot-toast";
import { FaRegSave } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import { MdOutlineRefresh } from "react-icons/md";

import UseCasesService from "services/usecases.service";
import jobFilesService from "services/jobfiles.service";
import ReferentialsService from "services/referentials.service";
import { IDocumentTypeField } from "models/document_type";
import { IReferential } from "models/referential";
import { IJobFile, IJobFileDataEntry } from "models/jobfile";
import { IProcessor, IUsecase, IUsecaseAutomation, IUsecaseSection } from "models/usecase";

import Button, { ButtonStyle } from "components/Button";
import SectionBloc from "components/SectionBloc";
import ConfigurationField from "./components/ConfigurationField";
import { selectCustomStyles } from "components/SelectSearch";
import Loading from "components/Loading";
import { IConnectorMeta, IExporter } from "models/connector";
import connectorsService from "services/connectors.service";

import ExportersSection from "./components/ExportersSection";
import DownloadersSection from "./components/DownloadersSection";
import AutomationSection from "./components/AutomationSection";

export default function UsecaseConfiguration() {
  const [usecase, setUsecase] = useState<IUsecase | null>(null);
  const [sections, setSections] = useState<IUsecaseSection[]>([]);
  const [automation, setAutomation] = useState<IUsecaseAutomation | null>(null);
  const [usableFields, setUsableFields] = useState<IDocumentTypeField[] | null>(null);
  const [referentials, setReferentials] = useState<IReferential[]>([]);
  const [connectors, setConnectors] = useState<IConnectorMeta[]>([]);
  const [hasConfigChanged, setHasConfigChanged] = useState<Boolean>(false);

  const [lastJobfiles, setLastJobfiles] = useState<IJobFile[]>([]);
  const [selectedJobfileId, setSelectedJobfileId] = useState<string | null>(null);
  const [selectedDownloaders, setSelectedDownloaders] = useState<string[]>([]);
  const [selectedExporters, setSelectedExporters] = useState<IExporter[]>([]);
  const [previewJobfile, setPreviewJobfile] = useState<IJobFile | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  let { useCaseId } = useParams();

  useEffect(() => {
    if (!useCaseId) return;

    // Reset
    setUsecase(null);
    setSections([]);
    setUsableFields(null);
    setHasConfigChanged(false);
    setLastJobfiles([]);
    setSelectedJobfileId(null);
    setPreviewJobfile(null);
    setLastJobfiles([]);
    setIsUpdating(false);

    // Load data
    loadUsecase(useCaseId);
    loadUsecaseUsableFields(useCaseId);
    loadUseCaseUsableJobfiles(useCaseId);
    loadReferentials();
    loadConnectors();

    // FIXME: react-hooks/exhaustive-deps
  }, [useCaseId]);

  // Refresh preview when selected jobfile changes
  useEffect(() => {
    if (selectedJobfileId) {
      getPreview();
    }
    // FIXME: react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobfileId]);

  const loadUsecase = async (useCaseId: string) => {
    const result = await UseCasesService.getUsecase(useCaseId);
    if (result) {
      setUsecase(result);
      setSections(result.sections);
      if (result.downloaders && result.downloaders.length > 0) setSelectedDownloaders(result.downloaders);
      if (result.exporters) setSelectedExporters(result.exporters);
      if (result.automation) setAutomation(result.automation);
    }
  };

  const loadUsecaseUsableFields = async (useCaseId: string) => {
    // Load usable documents fields
    const fields = await UseCasesService.getUsableFields(useCaseId);
    setUsableFields(fields);
  };

  const loadUseCaseUsableJobfiles = async (useCaseId: string) => {
    // Load usable jobfiles samples to get sample values
    const jobfiles = await UseCasesService.getUseCaseLastJobfiles(useCaseId);
    setLastJobfiles(jobfiles);
  };

  const loadReferentials = async () => {
    const referentials = await ReferentialsService.getReferentials();
    setReferentials(referentials);
  };

  const loadConnectors = async () => {
    const connectors = await connectorsService.listConnectors();
    setConnectors(connectors);
  };

  //
  // UI Actions
  //

  // Save field config change
  const onFieldConfigChange = (sectionId: string, fieldId: string, proc: IProcessor, required: boolean) => {
    const newSections: IUsecaseSection[] = [...sections];
    const section = newSections.find((s) => s.id === sectionId);
    if (section) {
      const field = section.data.find((d) => d.id === fieldId);
      if (field) {
        field.processor = proc;
        field.required = required;

        setSections(newSections);
        setHasConfigChanged(true);
      }
    }
  };

  // Save changes
  const onSaveClick = async () => {
    if (!useCaseId) return;

    await UseCasesService.updateUsecaseConfiguration(useCaseId, sections, selectedExporters ?? undefined, selectedDownloaders ?? undefined, automation ?? undefined);
    toast.success(t("usecase_configuration.updated_popup"));
    setHasConfigChanged(false);
  };

  // FIXME: This is a temporary solution to display the jobfile samples in the select
  // The label should not be a date + primary key but a more meaningful value
  // Nevertheless, the jobfiles have no meaningful id so far
  const formatJobfilesSamplesSelectOptions = () => {
    return lastJobfiles.map((j) => ({
      value: j._id,
      label: `${j.createdAt.toString().substring(0, 10)} | ${j._id}`,
    }));
  };

  // Handle the change event with the correct MultiValue type
  const handleDownloadersChange = (selectedOptions: MultiValue<any>) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedDownloaders(selectedValues);
  };

  const onExportersChange = (selectedOptions: any) => {
    setSelectedExporters(selectedOptions);
  }

  const onAutomationChange = (value: any) => {
    setAutomation(value);
  }

  const getPreview = async () => {
    if (usecase && selectedJobfileId) {
      setIsUpdating(true);
      const newJobfileDataSample = await jobFilesService.getJobfilePreview(usecase._id, selectedJobfileId);
      setPreviewJobfile(newJobfileDataSample);
      setIsUpdating(false);
    }
  };

  //
  // Rendering
  //

  if (!usecase) return <Loading />;

  return (
    <div className="h-full">
      <Helmet>
        <title>Usecase - Configuration</title>
      </Helmet>

      <div className="px-2 py-2 border-b flex items-center select-none mb-2">
        <span className="ml-4 font-semibold grow">{usecase.name}</span>
        <div className="flex items-center border-docloop_borderColor">
          <button title={t("usecase_configuration.refresh_action")} onClick={getPreview}>
            {" "}
            <MdOutlineRefresh className="text-2xl mr-2" />{" "}
          </button>
          <Select
            classNamePrefix="jobSample_select"
            isSearchable={true}
            name="job_sample"
            value={
              selectedJobfileId
                ? // FIXME: Label may/should contain date
                { value: selectedJobfileId, label: `${selectedJobfileId}` }
                : null
            }
            placeholder={t("usecase_configuration.document_sample_selection_placeholder")}
            options={formatJobfilesSamplesSelectOptions()}
            onChange={(option: any) => setSelectedJobfileId(option.value)}
            styles={selectCustomStyles}
            isDisabled={isUpdating}
            isLoading={isUpdating}
            tabSelectsValue={false}
          />
        </div>

        {hasConfigChanged && <span className="text-xs ml-2 font-medium italic text-gray-500">{t("usecase_configuration.changes_detected")}</span>}
        <div className="w-0.5 h-8 mx-4 bg-docloop_borderColor" />
        <Button leftIcon={<FaRegSave className="inline mr-2" />} text={t("usecase_configuration.save_button")} onClick={onSaveClick} color={ButtonStyle.Saving} />
      </div>

      {usableFields?.length === 0 && (
        <div className="mx-3 mt-3 shadow">
          <Alert icon={<BiError fontSize="inherit" />} severity="warning" sx={{ paddingTop: 2, paddingBottom: 2 }}>
            {t("usecase_configuration.no_usable_fields")}
          </Alert>
        </div>
      )}

      {/* EXPORTERS */}
      <Grid container spacing={1} className="p-3 w-full block justify-center">
        <Grid key="exporters" item xs={12} lg={8}>
          <SectionBloc title={t("usecase.exporters")} className="mb-1">
            <span className="text-xs text-center italic text-slate-500 leading-5 px-2 m-2">{t("usecase_configuration.exporters_description")}</span>
            <ExportersSection
              connectors={connectors}
              exporters={selectedExporters}
              onExportersChange={onExportersChange}
            />
          </SectionBloc>
        </Grid>
      </Grid>

      {/* DOWNLOADERS */}
      <Grid container spacing={1} className="p-3 w-full block justify-center">
        <Grid key="downloaders" item xs={12} lg={8}>
          <SectionBloc title={t("usecase.downloaders")} className="mb-1">
            <span className="text-xs text-center italic text-slate-500 leading-5 px-2 m-2">{t("usecase_configuration.downloaders_description")}</span>
            <DownloadersSection
              selectedDownloaders={selectedDownloaders}
              handleDownloadersChange={handleDownloadersChange}
            />
          </SectionBloc>
        </Grid>
      </Grid>

      {/* AUTOMATION */}
      <Grid container spacing={1} className="p-3 w-full block justify-center">
        <Grid key="automation" item xs={12} lg={4}>
          <SectionBloc title={t("usecase.automation")} className="mb-1">
            <span className="text-xs text-center italic text-slate-500 leading-5 px-2 m-2">{t("usecase.automation_description")}</span>
            <AutomationSection
              automation={automation}
              onAutomationChange={onAutomationChange}
            />
          </SectionBloc>
        </Grid>
      </Grid>

      {/* SECTIONS */}
      <Grid container spacing={1} className="p-3 w-full block">
        {sections.map((section) => {
          if (section.name === "empty_bloc") return <Grid key={`section-${section.name}`} item xs={4} />;

          return (
            <Grid key={`section-${section.name}`} item xs={12} lg={6}>
              <SectionBloc title={`${section.name} - ${section.table_id ?? ""} (${section.type})`} className="mb-1">
                {section.data.map((field) => {
                  let filteredUsableFields = section.table_id ? usableFields?.find((f) => f.technicalName === section.table_id)?.columns : usableFields;

                  // Get preview value (if possible)
                  let previewValue = null;
                  if (previewJobfile) {
                    if (!section.table_id) {
                      previewValue = previewJobfile?.data?.[field.id]?.value ?? "";
                    }
                    // If it's a table, display preview based on first line
                    else {
                      let previewTableData = (previewJobfile?.data?.[section.table_id]?.value as { [key: string]: IJobFileDataEntry }[]) ?? null;
                      if (previewTableData) {
                        previewValue = previewTableData[0]?.[field.id]?.value ?? "";
                      }
                    }
                  }

                  return (
                    <ConfigurationField
                      key={`section_field_${field.id}`}
                      field={field}
                      onChange={(required: boolean, proc: IProcessor) => onFieldConfigChange(section.id, field.id, proc, required)}
                      usableDocumentsFields={filteredUsableFields ?? []}
                      referentials={referentials ?? []}
                      connectors={connectors ?? []}
                      previewValue={previewValue}
                    />
                  );
                })}
              </SectionBloc>
            </Grid>
          );
        })}
      </Grid>
    </div >
  );
}
