import { IDocumentTypeField } from "models/document_type";
import api from "./api";
import { INewUsecase, IUsecase, IUsecaseAutomation, IUsecaseSection } from "models/usecase";
import { IJobFile } from "models/jobfile";
import { IExporter } from "models/connector";

const USECASE_API_URL = "/usecase";

class UseCasesService {
  // Retrieve all existing documents types
  async getUseCases(): Promise<IUsecase[]> {
    return api.get(`${USECASE_API_URL}/`).then((response) => {
      return response.data;
    });
  }

  async listUsecases(): Promise<IUsecase[]> {
    return api.get(`${USECASE_API_URL}/`).then((response) => {
      return response.data;
    });
  }

  async createUsecase(newUsecase: INewUsecase): Promise<string> {
    return (await api.post(USECASE_API_URL, newUsecase)).data;
  }

  // Retrieve a single usecase
  async getUsecase(useCaseId: string): Promise<IUsecase> {
    return api.get(`${USECASE_API_URL}/${useCaseId}`).then((response) => {
      return response.data;
    });
  }

  // Update usecase configuration change
  async updateUsecaseConfiguration(useCaseId: string, sections: IUsecaseSection[], exporters: IExporter[] | undefined, downloaders: string[] | undefined, automation: IUsecaseAutomation | undefined) {
    return api.post(`${USECASE_API_URL}/${useCaseId}/configuration`, { sections, exporters, downloaders, automation }).then((response) => {
      return response.data;
    });
  }

  // Retrieve all usables fields for a usecase
  async getUsableFields(useCaseId: string): Promise<IDocumentTypeField[]> {
    return api.get(`${USECASE_API_URL}/${useCaseId}/usable_fields`).then((response) => {
      return response.data;
    });
  }

  // Retrieve all jobfile samples ids for a usecase
  async getUseCaseLastJobfiles(useCaseId: string): Promise<IJobFile[]> {
    return api.get(`${USECASE_API_URL}/${useCaseId}/last_jobfiles`).then((response) => {
      return response.data;
    });
  }

  async deleteById(usecaseId: string): Promise<void> {
    return (await api.delete(`${USECASE_API_URL}/${usecaseId}`)).data;
  }
}

const useCasesService = new UseCasesService();
export default useCasesService;
