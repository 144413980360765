import { OcrWord } from "types/labelling";
import { DocumentTypeFieldType, IDocumentTypeField } from "./document_type";

export interface IDocumentMeta {
  num_pages: number | undefined;
}

export enum DocumentStatus {
  None = "none",
  ToClassify = "to_classify", // User must update the document type
  ToValidate = "to_validate", // User must "Validate annotation" of the document
  Processing = "processing", // Jobfile is being processed (validate) or connector is working (export)
  Error = "error",
}

export enum DocumentFieldOrigin {
  Manual = "manual",
  Mapping = "mapping",
  Prediction = "prediction",
  None = "none",
}

export interface IDocumentField {
  field_id: string;
  field_type: DocumentTypeFieldType;
  value: any;
  origin: DocumentFieldOrigin;
  mapping?: OcrWord[] | null;
  prediction?: OcrWord[] | null;
}

export interface IDocumentTableRow {
  [key: string]: IDocumentField;
}

// IDocumentMappedField is used to share the row and table while interacting with "all mapped fields" in the UI
export interface IDocumentMappedField extends IDocumentField {
  row?: number;
  table?: string;
}

export interface IDocumentExtraction {
  model_id: string;
  data: { [key: string]: IDocumentField };
}

export interface IDocumentCreatedBy {
  email: string;
  firstname?: string;
  lastname?: string;
}

export interface IDocument {
  _id: string;
  jobfile: string; // ref to jobfile with id
  name: string;
  status: DocumentStatus;
  meta: IDocumentMeta;
  file_url?: string;
  layout_url?: string;

  doc_type?: string;

  extraction?: IDocumentExtraction;

  createdAt: Date;
  updatedAt: Date;
  createdBy: IDocumentCreatedBy;
}

//
// Types used for API Requests / Responses
//

export type DocumentFieldUpdateRequest = {
  field_id: string;
  field_type: DocumentTypeFieldType;
  origin: DocumentFieldOrigin;
  value: string | never[];
  mapping: OcrWord[];
};

export type DocumentFieldRowUpdateRequest = {
  table: string;
  row: number;
  value: IDocumentTableRow;
};

export type DocumentTableFieldAddRowRequest = {
  field_id: string;
  table: string;
  row: number;
  columns: IDocumentTypeField[];
};

export type DocumentTableFieldDeleteRowsRequest = {
  field_id: string;
  table: string;
  rows: number[];
};

export type DocumentsSearchResponse = {
  data: IDocument[];
  total: number;
};

export interface DocumentHeuristicsTableColumnMetadata {
  technicalName: string;
  emptyAndNullCount: number;
  filledCount: number;
}

export interface DocumentHeuristicsTableMetadata {
  technicalName: string;
  columns: DocumentHeuristicsTableColumnMetadata[];
}

export interface DocumentHeuristicsMetadata {
  tables: DocumentHeuristicsTableMetadata[];
}