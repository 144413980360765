import { t } from "i18next"
import { IReferential } from "models/referential"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { ReferentialsTableComponent, ReferentialTableRowComponent } from "./components/ReferentialsTableComponents"
import { default as DocloopButton, ButtonStyle } from "components/Button";
import { useNavigate } from "react-router-dom"

import referentialsService from "services/referentials.service"
import { useSelector } from "react-redux"
import { RootState } from "redux/store"
import { UserRoleEnum } from "models/user"
import { Box } from "@mui/material"
import { MdAdd } from "react-icons/md"
import toast from "react-hot-toast"
import ConfirmationModal from "components/ConfirmationModal"
import ReferentialFormModal from "./components/AddReferentialFormModal"

const ReferentialsList = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [error, setError] = useState<string | null>(null);
  const [isAddReferentialModalOpen, setAddReferentialModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedReferentialForDeletion, setSelectedReferentialForDeletion] = useState<IReferential | null>(null);

  const [referentials, setReferentials] = React.useState<IReferential[]>([]);

  useEffect(() => {
    const getReferentials = async () => {
      try {
        const res = await referentialsService.getReferentials();
        setReferentials(res);
      } catch (err: any) {
        setError(err.message)
      }
    }
    getReferentials();
  }, [])

  if (currentUser && currentUser.role !== UserRoleEnum.OWNER) return <div>User is {currentUser.role} and not authorized.</div>;
  if (error) return <div> Error fetching referentials: {error} </div>

  const handleOpenAddReferentialModal = () => setAddReferentialModalOpen(true);
  const handleCloseAddReferentialModal = () => setAddReferentialModalOpen(false);

  const handleConfirmModalOpen = (referential: IReferential) => {
    setSelectedReferentialForDeletion(referential);
    setConfirmModalOpen(true);
  };
  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmModalConfirm = async () => {
    if (selectedReferentialForDeletion) {
      try {
        await referentialsService.deleteById(selectedReferentialForDeletion.id);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        handleConfirmModalClose();
        const referentials = await referentialsService.getReferentials();
        setReferentials(referentials);
      }
    }
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>{t("admin.referentials.title")}</title>
      </Helmet>

      <div className="px-2 py-2 border-b flex items-center select-none">
        <span className="ml-4 font-semibold grow">{t("admin.referentials.title")}</span>

        <Box display="flex" justifyContent="flex-end">
          <DocloopButton
            text={t("admin.referentials.add_referential")}
            color={ButtonStyle.Blue}
            leftIcon={<MdAdd className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
            onClick={handleOpenAddReferentialModal}
          />
        </Box>
      </div>
      <ReferentialFormModal
        open={isAddReferentialModalOpen}
        handleClose={handleCloseAddReferentialModal}
      />


      <div className="p-2">
        <ReferentialsTableComponent headers={[
          t("admin.referentials.list.name"),
          t("admin.referentials.list.description"),
          t("admin.referentials.list.fields"),
          '']}>
          {referentials && referentials.length > 0 && referentials.map((ref: IReferential) => (
            <ReferentialTableRowComponent
              key={ref.id}
              values={[
                { value: ref.name },
                { value: ref.description },
                { value: ref.fields.join(', ') },
                { value: '' },
              ]}
              onDelete={() => {
                return handleConfirmModalOpen(ref);
              }}
              onClick={() => {
                return navigate(`/admin/referentials/${ref.id}/configuration`)
              }}
            />
          ))}
        </ReferentialsTableComponent>

      </div>
      <ConfirmationModal
        open={isConfirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalConfirm}
        title={t("admin.referentials.confirm_deletion")}
      >
        {t("admin.referentials.confirm_deletion.message")} <br />
        <strong>{selectedReferentialForDeletion?.name}</strong> ?
      </ConfirmationModal>
    </div>
  )
}

export default ReferentialsList