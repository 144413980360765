import React, { useEffect } from "react";
import { t } from "i18next";
import { ImTable2 } from "react-icons/im";
import { FiDownload, FiEdit } from "react-icons/fi";
import { Tooltip } from "@mui/material";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCurrentDocument, setFocusedTable, setFocusedTableCell } from "redux/labelling";
import { loadJobfileAndDocuments } from "redux/jobfile";
import { DocumentTypeFieldType, IDocumentType } from "models/document_type";
import DocumentsService from "services/documents.service";
import { useDocumentTypes } from "hooks/DocumentTypesHook";

import DocumentField from "./DocumentField";
import ValidateAnnotationButton from "./ValidateAnnotationButton";
import { DocumentTypeSelector } from "./DocumentTypeSelector";
import { formatFieldId, getColorForFieldOrigin } from "utils/labelling";
import Button, { ButtonStyle } from "components/Button";
import { LabellingListeners } from "./LabellingListeners";
import { DocumentStatus } from "models/document";

import DocScan from "assets/gif/doc_scan.gif";
import { MdLock } from "react-icons/md";

type LabellingPanelProps = {};

export const LabellingPanel: React.FC<LabellingPanelProps> = () => {
  const dispatch = useAppDispatch();
  const documentsState = useAppSelector((state) => state.documents);
  const { currentDocument, focusedTable, readOnly } = useAppSelector((state) => state.labelling);
  const { currentJobfile } = useAppSelector((state) => state.jobfile);
  const { currentDocType } = useDocumentTypes();

  // On mount only: Auto-open table panel & focus on first cell if doctype has a table
  useEffect(() => {
    if (currentDocument && !focusedTable) {
      const table = currentDocType?.fields.find((f) => f.type === DocumentTypeFieldType.table);
      if (table && table.columns) {
        dispatch(setFocusedTable(table));
        // dispatch(setFocusedTableCell({ cell: table.columns[0], row: 0, edition: false })); // Don't do this it glitches and autopaginate to first row while loading pages
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the current document state is "ProcessingExtraction", automatically reload the document each 5sec
  // TODO: it should be replaced by the collaboration module with live socket
  useEffect(() => {
    if (!currentDocument || currentDocument.status !== DocumentStatus.Processing) return;

    const automaticRefreshId = setInterval(async () => {
      if (!currentDocument) return;

      // If the updated doc status is different than the current one, update store
      try {
        const updatedDoc = await DocumentsService.getDocument(currentDocument._id);
        if (updatedDoc && updatedDoc.status !== currentDocument.status) {
          dispatch(setCurrentDocument({ doc: updatedDoc, keepPagesMetadata: true }));

          if (currentJobfile) {
            dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 5000);

    return () => {
      clearInterval(automaticRefreshId);
    };
  }, [currentDocument, currentJobfile, dispatch]);

  // If there is no document loaded, return empty component
  if (!currentDocument && documentsState.documentsTypes.length === 0) return null;

  // Get the current usecase if we are in the context of a usecased jobfile
  const currentUsecase = currentJobfile?.usecase ?? null;

  const onDocTypeSelection = async (docType: IDocumentType | null) => {
    if (!currentDocument) return;
    // If the selected doc type is the same as the current one, do nothing
    if (docType && currentDocument.doc_type === docType.name) return;
    // Request confirmation if document already has a doctype
    // TODO: Request confirmation only on relevant cases
    // e.g. document has extraction with actual values in data
    const overwrite = (currentDocument.doc_type) ? window.confirm(t("labelling_panel.change_doctype_warning")) : true;

    if (overwrite) {
      try {
        const updatedDoc = await DocumentsService.setDocumentType(currentDocument._id, docType, currentUsecase);
        dispatch(setCurrentDocument({ doc: updatedDoc, keepPagesMetadata: true }));
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Download source PDF
  const handleDocumentDownload = async () => {
    if (!currentDocument) return;
    try {
      const doc = await DocumentsService.getDocument(currentDocument._id);
      if (doc) {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = doc.file_url!;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //
  // Rendering
  //
  const keyValueFields = currentDocType?.fields.filter((f) => f.type !== DocumentTypeFieldType.table);
  const tableFields = currentDocType?.fields.filter((f) => f.type === DocumentTypeFieldType.table);
  return (
    <div className="min-h-full h-full bg-white relative z-10 text-sm grow-0 flex flex-col alwaysShowScrollbar">
      <LabellingListeners />
      <div className="bg-gray-100 p-3 border-b border-docloop_borderColor text-center text-xs font-semibold shadow relative flex items-center justify-center">
        {readOnly &&
          <Tooltip title={t("labelling_panel.locked_edition")}>
            <div><MdLock className="text-xl inline-block mr-2 text-gray-500" /></div>
          </Tooltip>
        }
        <span>{t("labelling_panel.title")}</span>
        <Tooltip title={t("labelling_panel.document_download")}>
          <div className="absolute top-0.5 right-0 p-2 opacity-40 hover:opacity-100 cursor-pointer" onClick={handleDocumentDownload}>
            <FiDownload className="text-xl mr-2" />
          </div>
        </Tooltip>
      </div>

      <div className="overflow-y-scroll grow pb-2">
        {/* Select the document type */}
        {currentDocument && (
          <>
            <DocumentTypeSelector selectedDocumentTypeId={currentDocument.doc_type} onChange={onDocTypeSelection} />

            {currentDocument.status !== DocumentStatus.Processing && (
              <>
                {keyValueFields &&
                  keyValueFields.length > 0 &&
                  keyValueFields.map((field, idx) => {
                    const documentField = currentDocument.extraction?.data[field.technicalName];
                    return <DocumentField key={`doc-field-${currentDocument._id}-${field.technicalName}-${idx}`} id={formatFieldId(field)} field={field} documentField={documentField} />;
                  })}

                {tableFields &&
                  tableFields.length > 0 &&
                  tableFields.map((tableField, idx) => {
                    const isSelected = focusedTable?.table.technicalName === tableField.technicalName;

                    return (
                      <div
                        key={`labelling-table-${tableField.technicalName}`}
                        className={`relative rounded mx-2 mt-2 flex flex-row`}
                        style={{ backgroundColor: `${getColorForFieldOrigin(null)}${isSelected ? 80 : 30}` }}
                      >
                        <div className="flex items-center grow-0 w-52">
                          <span className={`text-xs ${isSelected ? "font-semibold" : "font-medium"} ml-2 block text-slate-500 py-3`}>
                            <ImTable2 className="inline mr-2" />
                            {t(`documentType.${tableField.technicalName}`, tableField.technicalName)}
                          </span>
                        </div>
                        <div className="flex grow mx-2 my-1 items-center select-none">
                          <Button
                            text={readOnly ? t("labelling_panel.table.open_view") : t("labelling_panel.table.open_edition")}
                            color={ButtonStyle.Blue}
                            small={true}
                            leftIcon={<FiEdit className="inline mr-2 -mt-0.5" />}
                            onClick={() => dispatch(setFocusedTable(tableField))}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            )}

            {currentDocument.status === DocumentStatus.Processing && (
              <div className="text-center mt-10 block">
                <span className="whitespace-pre-line block font-medium text-md text-slate-600">{t("labelling_panel.processing_extraction")}</span>
                <img className="mx-auto w-80 opacity-50 block" src={DocScan} alt={"Extraction..."} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex">{currentDocument && currentDocType && currentDocument.status !== DocumentStatus.Processing && <ValidateAnnotationButton />}</div>
    </div>
  );
};
