import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getAllUsers } from "redux/tenantUsers";
import { TenantUserTableComponent, TenantUserTableRowComponent } from "./components/TenantUserTableComponents";
import { IUser, UserRoleEnum } from "models/user";
import { Box } from "@mui/material";
import { default as DocloopButton, ButtonStyle } from "components/Button";
import TenantUserFormModal from "./components/AddTenantUserFormModal";
import userService from "services/user.service";
import { removeUser } from 'redux/tenantUsers';
import ConfirmationModal from "components/ConfirmationModal";
import { t } from "i18next";
import { MdAdd } from "react-icons/md";
import { Helmet } from "react-helmet";

const TenantUserList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const tenantUsers: IUser[] = useSelector((state: RootState) => state.tenantUsers.entities);
  const loading = useSelector((state: RootState) => state.tenantUsers.loading);
  const error = useSelector((state: RootState) => state.tenantUsers.error);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedUserForDeletion, setSelectedUserIdForDeletation] = useState<IUser | null>(null);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  if (currentUser && currentUser.role !== UserRoleEnum.OWNER) return <div>User is {currentUser.role} and not authorized.</div>;
  if (loading) return <div>Loading users...</div>
  if (error) return <div>Error fetching users: {error}</div>

  const handleOpenAdduserModal = () => setAddUserModalOpen(true);
  const handleCloseAddUserModal = () => setAddUserModalOpen(false);

  const handleConfirmModalOpen = (userId: IUser) => {
    setSelectedUserIdForDeletation(userId);
    setConfirmModalOpen(true);
  };
  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmModalConfirm = async () => {
    if (selectedUserForDeletion) {
      const result = await userService.deleteUserById(selectedUserForDeletion._id);
      if (result) {
        dispatch(removeUser(selectedUserForDeletion));
        handleConfirmModalClose();
      }
    }
  };


  return (
    <div className="h-full">
      <Helmet>
        <title>{t("admin.users_management.users")}</title>
      </Helmet>

      <div className="px-2 py-2 border-b flex items-center select-none">
        <span className="ml-4 font-semibold grow">{t("admin.users_management.users")}</span>
        <Box display="flex" justifyContent="flex-end">
          <DocloopButton

            text={t("user_list_view.add_new_user")}
            color={ButtonStyle.Blue}
            leftIcon={<MdAdd className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
            onClick={handleOpenAdduserModal}
          />
        </Box>
      </div>
      <TenantUserFormModal
        open={isAddUserModalOpen}
        handleClose={handleCloseAddUserModal}
      />
      <div className="p-2">
        <TenantUserTableComponent headers={[
          t("user_list_view.email_address"),
          t("user_list_view.name"),
          t("user_list_view.role"),
          t("user_list_view.account_state"),
          '']}>
          {tenantUsers.map((user) => (
            <TenantUserTableRowComponent
              key={user._id}
              values={[
                { value: user.email },
                { value: `${user.firstname ?? ""} ${user.lastname ?? ""}`.trim() },
                { value: user.role },
                { value: user.accountState },
                { value: user._id === currentUser?._id ? false : true }
              ]}
              onDelete={() => {
                if (user._id === currentUser?._id) return null;
                return handleConfirmModalOpen(user);
              }}
            />
          ))}
        </TenantUserTableComponent>

      </div>

      <ConfirmationModal
        open={isConfirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalConfirm}
        title={t("user_list_view.confirm_user_deletion")}
      >
        {t("user_list_view.are_you_sure_to_delete_user")} <br />
        <strong>{selectedUserForDeletion?.email}</strong> ?
      </ConfirmationModal>
    </div>
  );
};

export default TenantUserList;
