import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import Button, { ButtonStyle } from "components/Button";
import { t } from "i18next";
import { toast } from "react-hot-toast";
import { ConnectorType, IConnectorMeta, INewConnector } from "models/connector";
import connectorsService from "services/connectors.service";
import { useNavigate } from "react-router-dom";

export const initialConnectorMeta: INewConnector = {
  name: "",
  type: ConnectorType.FTP
};

interface ConnectorFormModalProps {
  open: boolean;
  handleClose: () => void;
}

const ConnectorFormModal: React.FC<ConnectorFormModalProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  //const tenantUsers: IUser[] = useSelector((state: RootState) => state.tenantUsers.entities);
  const [newConnector, setNewConnector] = useState<INewConnector>(initialConnectorMeta);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      setNewConnector(initialConnectorMeta);
      setErrors({});
    }
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewConnector((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setNewConnector((prev) => ({
      ...prev,
      [name]: value as ConnectorType,
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!newConnector.name) newErrors.name = t("admin.connectors.name_required");

    return newErrors;
  };

  const handleAddConnector = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const connector: IConnectorMeta = await connectorsService.createConnector(newConnector);
      handleClose();
      navigate(`/admin/connectors/${connector.id}`);
    } catch (err) {
      console.error(err);
      toast.error(t("user_list_view.add_user_error"));
    };
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("admin.connectors.add")}</DialogTitle>
      <DialogContent>
        <TextField
          error={!!errors.name}
          helperText={errors.name}
          autoFocus
          margin="dense"
          name="name"
          label="name"
          type="text"
          fullWidth
          variant="outlined"
          value={newConnector.name}
          onChange={handleInputChange}
          required
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>{t("admin.connectors.type")}</InputLabel>
          <Select name="type" value={newConnector.type} onChange={handleSelectChange} label={t("admin.connectors.type")}>
            {Object.keys(ConnectorType).map((connType) => (
              <MenuItem key={connType} value={ConnectorType[connType as keyof typeof ConnectorType]}>
                {ConnectorType[connType as keyof typeof ConnectorType]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button text={t("user_list_view.cancel")} onClick={handleClose} color={ButtonStyle.Gray} />
        <Button text={t("user_list_view.add")} onClick={handleAddConnector} color={ButtonStyle.Saving} />
      </DialogActions>
    </Dialog >
  );
};

export default ConnectorFormModal;
