import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUsecase } from "models/usecase";
import UseCasesService from "services/usecases.service";

export type UseCasesState = {
  useCases: IUsecase[]; // List of all available use cases for the tenant
};

export const initialState: UseCasesState = {
  useCases: [],
};

// Declare all async tasks
export const getUseCases = createAsyncThunk("usecases/getUseCases", async () => {
  return await UseCasesService.getUseCases();
});

// Core
const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUseCases.fulfilled, (state, action: PayloadAction<IUsecase[]>) => {
      state.useCases = action.payload;
    });
  },
});

export const reducer = documentsSlice.reducer;
