import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import Button, { ButtonStyle } from "components/Button";
import { INewUser, IUser, UserRoleEnum } from "models/user";
import userService from "services/user.service";
import { addUser } from "redux/tenantUsers";
import { AppDispatch, RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { toast } from "react-hot-toast";

export const initialUserData: INewUser = {
  email: "",
  firstname: "",
  lastname: "",
  role: UserRoleEnum.CONTRIBUTOR,
};

interface TenantUserFormModalProps {
  open: boolean;
  handleClose: () => void;
}

const TenantUserFormModal: React.FC<TenantUserFormModalProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const tenantUsers: IUser[] = useSelector((state: RootState) => state.tenantUsers.entities);
  const [userData, setUserData] = useState<INewUser>(initialUserData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      setUserData(initialUserData);
      setErrors({});
    }
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value as UserRoleEnum,
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!userData.email) newErrors.email = t("user_list_view.email_required");
    else if (!/\S+@\S+\.\S+/.test(userData.email)) newErrors.email = t("user_list_view.email_invalid");
    else if (tenantUsers.some((user) => user.email === userData.email)) newErrors.email = t("user_list_view.email_already_exists");

    return newErrors;
  };

  const handleAddUser = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const user: IUser = await userService.createUser(userData);
      dispatch(addUser(user));
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error(t("user_list_view.add_user_error"));
    };
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("user_list_view.add_new_user")}</DialogTitle>
      <DialogContent>
        <TextField
          error={!!errors.email}
          helperText={errors.email}
          autoFocus
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={userData.email}
          onChange={handleInputChange}
          required
        />
        <TextField margin="dense" name="firstname" label={t("user_list_view.firstname")} type="text" fullWidth variant="outlined" value={userData.firstname} onChange={handleInputChange} />
        <TextField margin="dense" name="lastname" label={t("user_list_view.lastname")} type="text" fullWidth variant="outlined" value={userData.lastname} onChange={handleInputChange} />
        <FormControl fullWidth margin="dense">
          <InputLabel>{t("user_list_view.role")}</InputLabel>
          <Select name="role" value={userData.role} onChange={handleSelectChange} label={t("user_list_view.role")}>
            {Object.keys(UserRoleEnum).map((role) => (
              <MenuItem key={role} value={UserRoleEnum[role as keyof typeof UserRoleEnum]}>
                {UserRoleEnum[role as keyof typeof UserRoleEnum]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button text={t("user_list_view.cancel")} onClick={handleClose} color={ButtonStyle.Gray} />
        <Button text={t("user_list_view.add")} onClick={handleAddUser} color={ButtonStyle.Saving} />
      </DialogActions>
    </Dialog >
  );
};

export default TenantUserFormModal;
