import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, ListItemIcon, ListItemText } from "@mui/material";
import { HiOutlineLogout } from "react-icons/hi";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { CiSettings } from "react-icons/ci";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { logout, retrieveUserProfile } from "redux/users";
import { getDocumentsTypes } from "redux/documents";
import { getUseCases } from "redux/usecases";
import authService from "services/auth.service";

export default function AppMenu() {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (userState.loggedIn === true) {
      dispatch(getDocumentsTypes());
      dispatch(getUseCases());
      dispatch(retrieveUserProfile());
    }
  }, [dispatch, userState.loggedIn]);

  //
  // UI Actions
  //
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    try {
      await authService.logout();
    } catch (err: any) {
      console.log(err);
    } finally {
      dispatch(logout());
    }

    handleMenuClose();
  };

  // Rendering
  return (
    <div>
      <Button aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        <HiOutlineUserCircle className="text-[28px] text-white" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="text-[8px] text-center pb-2">{userState.currentUser?.email}</div>
        <Divider />
        <MenuItem
          key={`menu-admin`}
          onClick={() => {
            navigate(`/admin/users/my_profile`);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <CiSettings />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: 14 }}>{t("user_menu.admin")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <HiOutlineLogout />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: 14 }}>{t("user_menu.logout")}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
