import api from "./api";
import { IConnector, IConnectorMeta, INewConnector } from "models/connector";

const CONNECTORS_URL = "/admin/connectors";

class ConnectorsService {
  async listConnectors(): Promise<IConnectorMeta[]> {
    return (await api.get(`${CONNECTORS_URL}`)).data;
  }
  async createConnector(newConnector: INewConnector) {
    return (await api.post(`${CONNECTORS_URL}`, newConnector)).data;
  }
  async deleteById(connId: string): Promise<void> {
    return (await api.delete(`${CONNECTORS_URL}/${connId}`)).data;
  }
  async getConnector(connId: string): Promise<IConnector> {
    return (await api.get(`${CONNECTORS_URL}/${connId}`)).data;
  }
  async updateConnector(connId: string, connector: IConnector): Promise<IConnector> {
    return (await api.put(`${CONNECTORS_URL}/${connId}`, connector)).data;
  }
}

export default new ConnectorsService();