import React from "react";

import { ImSpinner2 } from "react-icons/im";

export const enum ButtonStyle {
  Primary,
  Saving,
  Gray,
  DarkGray,
  Blue,
  White,
  Red,
}

type ButtonProps = {
  text: string;
  onClick?: () => void;

  tooltip?: string;
  leftIcon?: React.ReactNode;
  visible?: boolean;
  loading?: boolean;
  disabled?: boolean;

  className?: string;
  small?: boolean;

  color?: ButtonStyle;
  type?: 'submit' | 'reset' | 'button' | undefined; // HTML Button type
};

const Button: React.FC<ButtonProps> = ({ text, onClick, tooltip = "", leftIcon, visible = true, loading = false, disabled = false, className = "", small = false, color = ButtonStyle.Primary, type = "button" }) => {
  if (!visible) return null;

  const getStyleForType = (style: ButtonStyle) => {
    switch (style) {
      case ButtonStyle.Primary:
        return "text-white text-shadow bg-yellow-400 hover:bg-yellow-500";

      case ButtonStyle.Saving:
        return "text-white text-shadow bg-green-400 hover:bg-green-500";

      case ButtonStyle.Blue:
        return "text-white text-shadow bg-sky-400 hover:bg-sky-500";

      case ButtonStyle.White:
        return "text-slate-700 bg-white hover:bg-gray-50";

      case ButtonStyle.DarkGray:
        return "text-white text-shadow bg-[#2F2F2F] hover:bg-[#2F2F2FEE]";

      case ButtonStyle.Red:
        return "text-white text-shadow bg-red-400 hover:bg-red-500";

      case ButtonStyle.Gray:
      default:
        return "text-white text-shadow bg-gray-400 hover:bg-gray-500";


    }
  };

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`focus:outline-none font-medium ${small ? "px-2.5 py-1.5 text-xs rounded" : "px-5 py-2.5 text-sm rounded-lg "} disabled:opacity-40 ${getStyleForType(color)} ${className}`}
      title={tooltip}
    >
      {leftIcon ?? null}
      {text}
      {loading && <ImSpinner2 className="inline ml-4 animate-spin text-lg" />}
    </button>
  );
};

export default Button;
