import { IProcessor } from "models/usecase";
import api from "./api";
import { INewReferential, IReferential, IReferentialEntry, IReferentialEntryDispatchCustomer } from "models/referential";

const REFERENTIALS_API_URL = "/referentials";

class ReferentialsService {
  async createReferential(newReferential: INewReferential) {
    return (await api.post(`${REFERENTIALS_API_URL}`, newReferential)).data;
  }
  async deleteById(refId: string) {
    return (await api.delete(`${REFERENTIALS_API_URL}/${refId}`)).data;
  }
  async updateReferentialFields(refId: string, fields: string[]) {
    return (await api.patch(`${REFERENTIALS_API_URL}/${refId}/fields`, fields)).data;
  }
  // Retrieve all existing referentials
  async getReferentials(): Promise<IReferential[]> {
    return api
      .get(`${REFERENTIALS_API_URL}/`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  }

  async getReferential(referentialId: string): Promise<IReferential> {
    return api
      .get(`${REFERENTIALS_API_URL}/${referentialId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  }

  async getReferentialEntries(referentialId: string): Promise<IReferentialEntry[]> {
    return api.get(`${REFERENTIALS_API_URL}/${referentialId}/entries`)
      .then((response) => response.data)
      .catch((err) => [])
  }

  async importReferentialEntries(referentialId: string, entries: IReferentialEntry[]): Promise<IReferentialEntry[]> {
    return api
      .post(`${REFERENTIALS_API_URL}/${referentialId}/entries/bulk`, entries)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err)
        return [];
      });

  }

  // Search entries into a Docloop referential DB
  async searchReferentialEntries(referential_id: string, input_field: string, output_field: string, search_text: string): Promise<IReferentialEntry[]> {
    return api
      .post(`${REFERENTIALS_API_URL}/${referential_id}/search`, { input_field, output_field, search_text })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  }
  // Search entries via Dispatch customer API endpoint
  async searchDispatchCustomer(processor: IProcessor, search_text: string): Promise<IReferentialEntryDispatchCustomer[]> {
    return api
      .post(`${REFERENTIALS_API_URL}/dispatch/search/customer`, { processor, search_text })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  }
}



const referentialsService = new ReferentialsService();
export default referentialsService;
