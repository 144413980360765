import { CatalogSearchResponse } from "models/catalog";
import api from "./api";
import { SearchType } from "views/scenes/home/components/SearchTypeSelector";

const CATALOG_API_URL = "/catalog";

class CatalogService {
  async search(searchType: SearchType, searchText: string, searchDocumentsTypes: string[]): Promise<CatalogSearchResponse> {
    try {
      const searchParams = {
        search_type: searchType,
        search_text: searchText,
        doc_types: searchDocumentsTypes
      }
      const res = await api.post(`${CATALOG_API_URL}/search`, searchParams)
      if (!res.data) throw new Error("No payload in response while success");
      return res.data
    } catch(err: any) {
      console.error(err);
      return {
        searchQuery: searchText,
        hits: {
          documents: {
            total: 0,
            hits: [],
          },
          jobfiles: {
            total: 0,
            hits: []
          }
        }
      }
    }
  }
}

const catalogServices = new CatalogService();
export default catalogServices;