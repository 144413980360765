import { Formik, FormikHelpers, Form } from "formik";
import { Box, TextField } from "@mui/material";
import { default as DocloopButton, ButtonStyle } from "components/Button";



import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import * as Yup from 'yup';
import { t } from "i18next";
import { MdSave } from "react-icons/md";
import userService from "services/user.service";
import { INewUser } from "models/user";
import toast from "react-hot-toast";
import { useAppDispatch } from "redux/hooks";
import { retrieveUserProfile } from "redux/users";
import { Helmet } from "react-helmet";


export default function User() {
    const dispatch = useAppDispatch();
    const user = useSelector((state: RootState) => state.user.currentUser);
    const userValues = user as INewUser;
    const handleSubmit = async (values: INewUser, { setSubmitting }: FormikHelpers<INewUser>) => {
        setSubmitting(true);
        try {
            const patchValues = {
                email: values.email!,
                role: values.role!,
                firstname: values.firstname!,
                lastname: values.lastname!,
            }
            const data = await userService.updateUser(patchValues);
            if (data) {
                dispatch(retrieveUserProfile());
                toast.success(t("my_profile.toast.success"));
            }
        } catch (err: any) {
            console.error(err);
            toast.error(err);
        }
        setSubmitting(false);
    }

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('my_profile.form.firstname_required')),
        lastname: Yup.string().required(t('my_profile.form.lastname_required')),
        email: Yup.string().email('Invalid email').required(t('my_profile.form.email')),
    });



    if (!user) return <div>Loading user...</div>


    return (
        <div className="h-full">
            <Helmet>
                <title>{t("admin.users_management.my_profile")}</title>
            </Helmet>
            <div className="px-2 py-2 border-b flex items-center select-none">
                <span className="ml-4 font-semibold grow">{t("admin.users_management.my_profile")}</span>
            </div>
            <div className="p-10">
                <div className="w-1/3">
                    <Formik initialValues={userValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ isSubmitting, handleChange, values, errors, touched, submitForm }) => (
                            <Form>
                                <Box mb={2}>
                                    <TextField
                                        disabled={true}
                                        fullWidth
                                        size="small"
                                        name="email"
                                        label={t("my_profile.form.email")}
                                        type="email"
                                        value={values.email}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        disabled={true}
                                        fullWidth
                                        size="small"
                                        name="last_login"
                                        label={t("my_profile.form.last_login")}
                                        value={values.last_login}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        disabled={true}
                                        fullWidth
                                        size="small"
                                        name="role"
                                        label={t("my_profile.form.role")}
                                        type="email"
                                        value={values.role}
                                        onChange={handleChange}
                                        error={touched.role && Boolean(errors.role)}
                                        helperText={touched.role && errors.role}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="firstname"
                                        label={t("my_profile.form.firstname")}
                                        value={values.firstname}
                                        onChange={handleChange}
                                        error={touched.firstname && Boolean(errors.firstname)}
                                        helperText={touched.firstname && errors.firstname}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="lastname"
                                        label={t("my_profile.form.lastname")}
                                        value={values.lastname}
                                        onChange={handleChange}
                                        type="text"
                                        error={touched.lastname && Boolean(errors.lastname)}
                                        helperText={touched.lastname && errors.lastname}
                                        autoComplete="new-lastname"
                                    />
                                </Box>
                                <DocloopButton
                                    type="submit"
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                    text={t("my_profile.form.save")}
                                    leftIcon={<MdSave className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
                                    color={ButtonStyle.Saving}
                                    className="border"
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}