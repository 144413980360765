import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { UsecasesTableComponent, UsecaseTableRowComponent } from "./components/UsecasesTableComponents";
import { Box } from "@mui/material";
import { default as DocloopButton, ButtonStyle } from "components/Button";
import UsecaseFormModal from "./components/AddUsecaseFormModal";
import ConfirmationModal from "components/ConfirmationModal";
import { t } from "i18next";
import { MdAdd } from "react-icons/md";
import { Helmet } from "react-helmet";
import usecasesService from "services/usecases.service";
import { toast } from "react-hot-toast";
import { UserRoleEnum } from "models/user";
import { useNavigate } from "react-router-dom";
import { IUsecase } from "models/usecase";

const UsecasesList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [usecases, setUsecases] = useState<IUsecase[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isAddUsecaseModalOpen, setAddUsecaseModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedUsecaseForDeletion, setSelectedUsecaseForDeletation] = useState<IUsecase | null>(null);

  useEffect(() => {
    const getUsecases = async () => {
      try {
        const usecases = await usecasesService.getUseCases();
        setUsecases(usecases);
      } catch (err: any) {
        setError(err.message);
      }
    }
    getUsecases();
  }, [dispatch]);


  if (currentUser && currentUser.role !== UserRoleEnum.OWNER) return <div>User is {currentUser.role} and not authorized.</div>;
  if (error) return <div> Error fetching users: {error} </div>

  const handleOpenAddUsecaseModal = () => setAddUsecaseModalOpen(true);
  const handleCloseAddUsecaseModal = () => setAddUsecaseModalOpen(false);

  const handleConfirmModalOpen = (uc: IUsecase) => {
    setSelectedUsecaseForDeletation(uc);
    setConfirmModalOpen(true);
  };
  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmModalConfirm = async () => {
    if (selectedUsecaseForDeletion) {
      try {
        await usecasesService.deleteById(selectedUsecaseForDeletion._id);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        handleConfirmModalClose();
        const usecases = await usecasesService.listUsecases();
        setUsecases(usecases);
      }
    }
  };


  return (
    <div className="h-full">
      <Helmet>
        <title>{t("admin.usecases.title")}</title>
      </Helmet>

      <div className="px-2 py-2 border-b flex items-center select-none">
        <span className="ml-4 font-semibold grow">{t("admin.usecases.title")}</span>
        <Box display="flex" justifyContent="flex-end">
          <DocloopButton
            text={t("admin.usecases.add_usecase")}
            color={ButtonStyle.Blue}
            leftIcon={<MdAdd className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
            onClick={handleOpenAddUsecaseModal}
          />
        </Box>
      </div>
      <UsecaseFormModal
        open={isAddUsecaseModalOpen}
        handleClose={handleCloseAddUsecaseModal}
      />
      <div className="p-2">
        <UsecasesTableComponent headers={[
          t("admin.usecases.list.name"),
          t("admin.usecases.list.doc_types"),
          t("admin.usecases.list.exporters"),
          t("admin.usecases.list.downloaders"),
          '']}>
          {usecases && usecases.length > 0 && usecases.map((uc: IUsecase) => (
            <UsecaseTableRowComponent
              key={uc._id}
              values={[
                { value: uc.name },
                { value: uc.docTypes.map((t) => t.name).join('\n') },
                { value: uc.exporters?.length },
                { value: uc.downloaders?.join('\n') },
                { value: uc._id }
              ]}
              onDelete={() => {
                return handleConfirmModalOpen(uc);
              }}
              onClick={() => {
                return navigate(`/admin/usecase/${uc._id}/configuration`)
              }}
            />
          ))}
        </UsecasesTableComponent>

      </div>

      <ConfirmationModal
        open={isConfirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalConfirm}
        title={t("admin.usecases.confirm_deletion")}
      >
        {t("admin.usecases.confirm_deletion.message")} <br />
        <strong>{selectedUsecaseForDeletion?.name}</strong> ?
      </ConfirmationModal>
    </div>
  );
};

export default UsecasesList;
