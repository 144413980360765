import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentStatus } from "models/document";
import { JobfileStatusType } from "models/jobfile";
import { SearchType } from "views/scenes/home/components/SearchTypeSelector";

export type SearchState = {
  searchType: SearchType;
  searchText: string;
  searchDocumentsTypes: string[]
  searchDocumentStatuses: DocumentStatus[]
  searchJobfileStatuses: JobfileStatusType[]
};

export const initialState: SearchState = {
  searchType: SearchType.Jobfiles,
  searchText: "",
  searchDocumentsTypes: [],
  searchDocumentStatuses: [],
  searchJobfileStatuses: []
};

// Core
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearchState(state) {
      Object.assign(state, initialState);
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setSearchDocumentsTypes(state, action: PayloadAction<string[]>) {
      state.searchDocumentsTypes = action.payload;
    },
    setSearchDocumentStatuses(state, action: PayloadAction<DocumentStatus[]>) {
      state.searchDocumentStatuses = action.payload;
    },
    setSearchJobfileStatuses(state, action: PayloadAction<JobfileStatusType[]>) {
      state.searchJobfileStatuses = action.payload;
    },
    setSearchType(state, action: PayloadAction<SearchType>) {
      state.searchType = action.payload;
    }
  },
});

export const { resetSearchState, setSearchText, setSearchDocumentsTypes, setSearchDocumentStatuses, setSearchJobfileStatuses, setSearchType } = searchSlice.actions;
export const reducer = searchSlice.reducer;
