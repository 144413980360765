import React from "react";
import { FiDownload } from "react-icons/fi";
import { TbZoomReplace } from "react-icons/tb";
import { MdOutlineZoomInMap, MdOutlineZoomOutMap } from "react-icons/md";
import { t } from "i18next";

import DocumentsServices from "services/documents.service";
import { IDocument } from "models/document";

import Button, { ButtonStyle } from "components/Button";

export enum PageZoom {
  DEFAULT = 0,
  FIT_TO_PAGE = 1,
  FIT_TO_WIDTH = 2,
}

type PageViewerToolbarProps = {
  doc: IDocument | null;
  pageZoom: PageZoom;
  showZoomActions: boolean;
  onPageZoomChange: (zoom: PageZoom) => void;

  activePage: number;
  pagesCount: number;
};

const PageViewerToolbar: React.FC<PageViewerToolbarProps> = ({ doc, pageZoom, showZoomActions, onPageZoomChange, activePage, pagesCount }) => {
  // Handle page zoom actions click
  const handlePageZoomClick = (zoom: PageZoom) => {
    localStorage.setItem("docloop.pageZoom", zoom.toString());
    onPageZoomChange(zoom);
  };

  // Download source PDF
  const handleDocumentDownload = async () => {
    if(!doc) return;

    const doc_result = await DocumentsServices.getDocument(doc._id); // We trigger a getDocument API call to get a fresh file_url 
    if (doc_result) {
      const link = document.createElement("a");
      link.href = doc_result.file_url!;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //
  // Rendering
  //
  return (
    <div className="absolute top-1 right-5 text-right" style={{ textShadow: "1px 1px 1px #FFFFFFB3" }}>
      {showZoomActions ? (
        <div className="mb-0.5 block">
          <Button
            className={`${pageZoom === PageZoom.DEFAULT ? "bg-green-700 hover:bg-green-700" : ""}`}
            leftIcon={<TbZoomReplace className="inline text-lg" />}
            tooltip={t("labelling_panel.zoom_default")}
            text=""
            onClick={() => handlePageZoomClick(PageZoom.DEFAULT)}
            color={ButtonStyle.Saving}
            small={true}
          />
          <Button
            className={`${pageZoom === PageZoom.FIT_TO_PAGE ? "bg-green-700" : ""} ml-1`}
            leftIcon={<MdOutlineZoomInMap className="inline text-lg" />}
            tooltip={t("labelling_panel.zoom_fit_page")}
            text=""
            onClick={() => handlePageZoomClick(PageZoom.FIT_TO_PAGE)}
            color={ButtonStyle.Saving}
            small={true}
          />
          <Button
            className={`${pageZoom === PageZoom.FIT_TO_WIDTH ? "bg-green-700" : ""} ml-1`}
            leftIcon={<MdOutlineZoomOutMap className="inline text-lg" />}
            tooltip={t("labelling_panel.zoom_fit_width")}
            text=""
            onClick={() => handlePageZoomClick(PageZoom.FIT_TO_WIDTH)}
            color={ButtonStyle.Saving}
            small={true}
          />
        </div>
      ) : (
        <div className="mb-0.5 block">
          <Button
            leftIcon={<FiDownload className="inline text-lg" />}
            tooltip={t("labelling_panel.document_download")}
            text=""
            onClick={() => handleDocumentDownload()}
            color={ButtonStyle.Saving}
            small={true}
          />
        </div>
      )}
      {pagesCount > 0 && (
        <div className="bg-white bg-opacity-80 rounded-sm text-xs mx-auto px-1 py-0.5 shadow clear-both absolute right-0 -bottom-[22px] select-none">
          {activePage} / {pagesCount}
        </div>
      )}
    </div>
  );
};

export default PageViewerToolbar;
