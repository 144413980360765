import React, { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Select from "react-select";
import { t } from "i18next";
import { ImTable2 } from "react-icons/im";
import toast from "react-hot-toast";

import { DocumentTypeFieldType, IDocumentType, IDocumentTypeField } from "models/document_type";
import { setFocusedField, setFocusedTable, setFocusedTableCell, setHeuristicsMetadata, updateDocumentTableLocally } from "redux/labelling";
import { SelectionZone, formatTextFromMapping, getMappedFieldsForPage } from "utils/labelling";
import { useDocumentTypes } from "hooks/DocumentTypesHook";
import { selectCustomStyles } from "components/SelectSearch";
import { DocumentFieldOrigin, DocumentFieldUpdateRequest, IDocumentTableRow } from "models/document";

import DocumentsService from "services/documents.service";

type AnnotationModalProps = {
  selectedZone: SelectionZone | null;
};

type ModalPosition = {
  left: number;
  top: number;
};

type AnnotationField = {
  value: string;
  label: string;
  type: DocumentTypeFieldType;
  index: number;
  isSet: boolean;
};

const MODAL_MIN_HEIGHT = 230; // Used to automatically replace the modal if there is not enough vertical space

const AnnotationModal: React.FC<AnnotationModalProps> = ({ selectedZone }) => {
  const { focusedField, selectedMappingWords, focusedTable } = useAppSelector((state) => state.labelling);
  const [currentModalPosition, setCurrentModalPosition] = useState<ModalPosition | null>(null);
  const [lastSelectedZone, setLastSelectedZone] = useState<SelectionZone | null>(null);
  const { currentDocType } = useDocumentTypes();
  const dispatch = useAppDispatch();

  // Listen to change of labellingState
  useEffect(() => {
    if (!selectedZone) {
      setCurrentModalPosition(null);
      return;
    }

    // If there is no focused field and there is selected mapping words, then the annotation modal needs to be shown
    if (!focusedField && !focusedTable?.focusedCell?.edition && !focusedTable?.focusedColumn && selectedMappingWords && selectedMappingWords.length > 0) {
      let left = selectedZone.w > 0 ? selectedZone.mouseX - selectedZone.w / 2 : selectedZone.mouseX;
      let top = selectedZone.mouseY + 5;

      // Detect maxY position based on the pageViewer DOM Element
      const elem = document.getElementById("page-viewer");
      let maxY = elem?.getBoundingClientRect().bottom ?? window.innerHeight;

      // Check vertical position: If the modal is not fully displayed, adapt the position
      if (top + MODAL_MIN_HEIGHT > maxY) {
        left += 60;
        top = maxY - MODAL_MIN_HEIGHT;
      }

      setCurrentModalPosition({ left, top });
      setLastSelectedZone({ ...selectedZone });
    }
    // Else, we hide it
    else {
      setCurrentModalPosition(null);
    }
  }, [selectedZone, focusedField, focusedTable, selectedMappingWords, dispatch]);

  //
  // Rendering
  //
  if (!currentModalPosition) return;
  return (
    <div id="annotation-modal" className={`fixed w-80 z-50 bg-white rounded-sm shadow-lg border border-slate-100`} style={{ left: currentModalPosition.left, top: currentModalPosition.top }}>
      {!focusedTable?.focusedCell && <FieldSelector docType={currentDocType} lastSelectedZone={lastSelectedZone} />}
      {focusedTable?.focusedCell && <TableFieldSelector docType={currentDocType} lastSelectedZone={lastSelectedZone} />}
    </div>
  );
};

// Type used for selector components
type SelectorProps = {
  docType: IDocumentType | null;
  lastSelectedZone: SelectionZone | null;
};

// Subcomponent for simples fields dropdown selection
const FieldSelector: React.FC<SelectorProps> = ({ docType, lastSelectedZone }) => {
  const dispatch = useAppDispatch();
  const { currentDocument, selectedMappingWords } = useAppSelector((state) => state.labelling);

  // Use object from formatFieldsIntoSelectOptions() to display it
  const formatOptionLabel = (data: any): ReactNode => {
    if (!currentDocument || !docType) return;

    const field = currentDocument.extraction?.data[data.value];
    const fieldType = docType.fields.find((f) => f.technicalName === data.value)?.type;
    const isSet = fieldType !== DocumentTypeFieldType.table && field?.value?.length > 0 ? true : false;

    return (
      <div className={`flex items-center ${isSet ? "opacity-40" : ""}`}>
        {fieldType === DocumentTypeFieldType.table ? <ImTable2 className="mr-2" /> : ""}
        {data.label}
        {isSet ? " ✅" : ""}
      </div>
    );
  };

  // Helper function that format dropdown options
  const formatFieldsIntoSelectOptions = () => {
    if (!currentDocument || !docType) return [];
    let options: AnnotationField[] = docType.fields.map((f, index) => {
      const documentField = currentDocument.extraction?.data[f.technicalName];
      const isSet = documentField && documentField.value && documentField.value.length > 0 ? true : false;
      return {
        value: f.technicalName,
        label: `${t(`documentType.${f.technicalName}`, f.technicalName)}`,
        isSet,
        index,
        type: f.type,
      };
    });

    // Sort options based on their "isSet" state
    options.sort((a, b) => {
      if (b.isSet && !a.isSet) return -1;
      else if (!b.isSet && a.isSet) return 1;
      else return a.index - b.index;
    });

    // Sort options to have type table first
    options.sort((a, b) => {
      if (a.type === "table" && b.type !== "table") return -1;
      else if (a.type !== "table" && b.type === "table") return 1;
      else return 0;
    });

    return options;
  };

  // Callback function when a field has been selected
  // Case 1: [KV UPDATE] Key/value field is selected so we update the selected field
  // Case 2: [TABLE AREA INIT] Table field is selected so we activate table mode
  // We create the table area from selection
  // We initialize table and first row if needed
  const onFieldSelection = async (fieldId: string) => {
    if (!currentDocument || !docType) return;

    let field: IDocumentTypeField | undefined = docType.fields.find((f) => f.technicalName === fieldId);
    if (field) {
      // Case 1 : Key value field is updated
      if (field.type !== DocumentTypeFieldType.table && selectedMappingWords && selectedMappingWords.length > 0) {
        console.log("[ANNOT MODAL] Key value field update");
        // 0) Check sameWord logic
        let isAnyWordAlreadyMapped = false;
        let mappedFields = getMappedFieldsForPage(currentDocument, selectedMappingWords[0].page).flat();

        for (const word of selectedMappingWords) {
          const sameWord = mappedFields.find(
            (f) =>
              fieldId !== f.field_id &&
              f.mapping &&
              f.mapping.find((w) => {
                return w.page === word.page && w.bbox.x0 === word.bbox.x0 && w.bbox.x1 === word.bbox.x1 && w.bbox.y0 === word.bbox.y0 && w.bbox.y1 === word.bbox.y1;
              })
          );

          if (sameWord && fieldId) {
            isAnyWordAlreadyMapped = true;
            let errorMessage = `${t("labelling_panel.toaster_failed_mapping_already_mapped")} ${t(`documentType.${sameWord.field_id}`, `${sameWord.field_id}`)} ${Number.isInteger(sameWord.row) ? `- ${t("labelling_panel.toaster_failed_table_row", { row: sameWord.row })}` : ""
              }`;
            toast.error(errorMessage);
            break;
          }
        }
        if (!isAnyWordAlreadyMapped) {
          // 1) Set focused field with keepSelectedMappingWords option. It will automatically trigger the field onChange
          dispatch(setFocusedField({ field, keepSelectedMappingWords: true }));
        }
        // 2) Unselect the focusedField after this trigger
        // Note: This is a "easy solution" instead of re-thinking the whole usage of redux store for document fields
        // FIXME: 75ms are required, 50ms are not enough.
        setTimeout(() => {
          dispatch(setFocusedField({ field: null }));
        }, 75);
      }
      // Case 2 : Activate table mode
      else if (field.type === DocumentTypeFieldType.table && lastSelectedZone) {
        const documentTable = currentDocument.extraction?.data[field.technicalName];
        if (documentTable) {
          // Get number of rows from table if it exists
          const numberOfRows = documentTable.value.length ?? 0;
          console.log("[ANNOT MODAL] Focus table");

          // Focus table and last row
          dispatch(setFocusedTable(field));
          if (numberOfRows > 0) {
            dispatch(setFocusedTableCell({ cell: field.columns![0], row: numberOfRows - 1, edition: false }));
          }
        }
      }
    }
  };

  // Render
  return (
    <Select
      autoFocus
      classNamePrefix="annotation_modal_select"
      isSearchable={true}
      menuIsOpen={true}
      styles={selectCustomStyles}
      name="annotation_modal_select"
      value={null}
      placeholder={t("labelling_panel.annotation_modal.input_placeholder")}
      options={formatFieldsIntoSelectOptions()}
      formatOptionLabel={formatOptionLabel}
      onChange={(option: any) => onFieldSelection(option.value)}
      menuPosition="fixed"
      tabSelectsValue={false}
    />
  );
};

// Subcomponent for table fields dropdown selection
const TableFieldSelector: React.FC<SelectorProps> = ({ docType }) => {
  const dispatch = useAppDispatch();
  const { currentDocument, selectedMappingWords, focusedTable, pagesMetadata } = useAppSelector((state) => state.labelling);
  if (!currentDocument || !focusedTable?.focusedCell) return;

  // Retrieve current active row
  const documentTable = currentDocument.extraction?.data[focusedTable.table.technicalName];
  const activeDocumentTableRow: IDocumentTableRow = documentTable?.value[focusedTable.focusedCell.row] ?? [];

  // Use object from formatFieldsIntoSelectOptions() to display it
  const formatOptionLabel = (data: any): ReactNode => {
    const field = Object.values(activeDocumentTableRow).find((e) => e.field_id === data.value);
    const isSet = field?.value?.length > 0 ? true : false;

    return (
      <div className={`flex items-center ${isSet ? "opacity-40" : ""}`}>
        {data.label}
        {isSet ? " ✅" : ""}
      </div>
    );
  };

  // Helper function that format dropdown options
  const formatFieldsIntoSelectOptions = () => {
    let options: AnnotationField[] = [];
    if (focusedTable.table.columns) {
      options = focusedTable.table.columns.map((c, index) => {
        return {
          value: c.technicalName,
          label: `${t(`documentType.${c.technicalName}`, c.technicalName)}`,
          isSet: activeDocumentTableRow[c.technicalName]?.value?.length > 0 ? true : false,
          index,
          type: c.type,
        };
      });
    }

    // Sort options based on their "isSet" state
    options.sort((a, b) => {
      if (b.isSet && !a.isSet) return -1;
      else if (!b.isSet && a.isSet) return 1;
      else return a.index - b.index;
    });

    return options;
  };

  // Callback function when a field has been selected
  // Case 3: [COL UPDATE] A column from a table is selected so we update the selected row/column
  // That means table area is already active and we know active row
  const onFieldSelection = async (fieldId: string) => {
    if (!currentDocument || !focusedTable.focusedCell) return;

    let field: IDocumentTypeField | undefined = focusedTable.table.columns?.find((c) => c.technicalName === fieldId);

    if (field && field.type !== DocumentTypeFieldType.table && selectedMappingWords) {
      console.log("[ANNOT MODAL] Table column update");
      // Case 3 : Map the appropriate row in the table
      const documentTable = currentDocument.extraction?.data[focusedTable.table.technicalName];

      if (documentTable) {
        // Case 3.1 : Table already exists in the document
        console.log("[ANNOT MODAL] Update existing row in existing table: ", fieldId);
        const row: IDocumentTableRow = { ...documentTable.value[focusedTable.focusedCell.row] };
        if (row) {
          const updatedValue = formatTextFromMapping(selectedMappingWords, pagesMetadata);
          let isAnyWordAlreadyMapped = false;
          const pageIndex = selectedMappingWords[0]?.page ?? 0;
          let mappedFields = getMappedFieldsForPage(currentDocument, pageIndex).flat();

          for (const word of selectedMappingWords) {
            const sameWord = mappedFields?.find(
              (f) =>
                (fieldId !== f.field_id || (fieldId === f.field_id && focusedTable.focusedCell?.row !== f.row)) &&
                f.mapping &&
                f.mapping.find((w) => {
                  return w.page === word.page && w.bbox.x0 === word.bbox.x0 && w.bbox.x1 === word.bbox.x1 && w.bbox.y0 === word.bbox.y0 && w.bbox.y1 === word.bbox.y1;
                })
            );

            if (sameWord && fieldId) {
              isAnyWordAlreadyMapped = true;
              let errorMessage = `${t("labelling_panel.toaster_failed_mapping_already_mapped")} ${t(`documentType.${sameWord.field_id}`, `${sameWord.field_id}`)} ${Number.isInteger(sameWord.row) ? `- ${t("labelling_panel.toaster_failed_table_row", { row: sameWord.row })}` : ""
                }`;
              toast.error(errorMessage);
              setTimeout(() => {
                dispatch(setFocusedField({ field: null }));
              }, 50);
              break;
            }
          }
          if (!isAnyWordAlreadyMapped && selectedMappingWords.length > 0) {
            row[fieldId] = {
              ...row[fieldId],
              origin: DocumentFieldOrigin.Mapping,
              value: updatedValue,
              mapping: selectedMappingWords,
            };
          }

          const tableRowUpdate = {
            table: focusedTable.table.technicalName,
            row: focusedTable.focusedCell.row,
            value: row,
          };

          const newField = row[fieldId];
          const fieldUpdateRequest: DocumentFieldUpdateRequest = {
            field_id: newField.field_id,
            field_type: newField.field_type,
            origin: newField.origin,
            value: newField.value,
            mapping: newField.mapping ?? [],
          };

          dispatch(updateDocumentTableLocally(tableRowUpdate));
          try {
            const heuristicsData = await DocumentsService.updateDocumentFieldInRow(currentDocument._id, focusedTable.table.technicalName, focusedTable.focusedCell.row, fieldUpdateRequest);
            toast.success(t("labelling_panel.toaster_successful_table_mapping"));

            dispatch(setFocusedTableCell({ cell: field, row: focusedTable.focusedCell?.row, edition: false }));
            dispatch(setHeuristicsMetadata(heuristicsData));
          } catch (error) {
            console.error(error);
            toast.error(t("labelling_panel.toaster_unsuccessful_table_mapping"));
          }
        }
      } else {
        // Table does not exist in the document > Error > The table should be created when table area is drawn
        console.log("[ANNOT MODAL] Table does not exist", fieldId);
      }
    } else {
      // Error: Field not found
      console.log("[ANNOT MODAL] Error in onFieldSelection", fieldId);
    }
  };

  // Render
  return (
    <Select
      autoFocus
      classNamePrefix="annotation_modal_select"
      isSearchable={true}
      menuIsOpen={true}
      styles={selectCustomStyles}
      name="annotation_modal_select"
      value={null}
      placeholder={t("labelling_panel.annotation_modal.input_placeholder")}
      options={formatFieldsIntoSelectOptions()}
      formatOptionLabel={formatOptionLabel}
      onChange={(option: any) => onFieldSelection(option.value)}
      menuPosition="fixed"
      tabSelectsValue={false}
    />
  );
};

export default AnnotationModal;
