import React from "react";
import { t } from "i18next";
import { IoIosArrowDropright } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import JobFileService from "services/jobfiles.service";
import { DocumentStatus } from "models/document";
import { useAppSelector } from "redux/hooks";
import { Tooltip } from "@mui/material";
import { MdStopCircle } from "react-icons/md";
import { JobfileStatusType } from "models/jobfile";
import { useAppDispatch } from "redux/hooks";
import { setCurrentJobFile } from "redux/jobfile";

const ValidateJobfileButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const { currentJobfile, currentJobfileDocuments } = useAppSelector((state) => state.jobfile);
  const navigate = useNavigate();

  const areAllDocsValidated = currentJobfileDocuments?.length > 0 && currentJobfileDocuments.every((d) => d.status === DocumentStatus.None);

  const onClickValidate = async () => {
    // if usecase is not selected, prevent the action
    if (currentJobfile?.usecase == null) return toast.error(t("jobfile_documents_list.no_usecase_selected"));

    // if some docs are not validated, prevent the action
    if (!areAllDocsValidated) return toast.error(t("jobfile_documents_list.documents_not_validated"));

    // Check if jobfile has a data attribute
    // If jobfile has never been computed i.e. jobfile has no data, trigger validation
    try {
      if (!currentJobfile.data) await JobFileService.validateJobfile(currentJobfile._id);
    } catch (error) {
      console.error(error);
    } finally {
      navigate(`/jobfile/${currentJobfile._id}/validation`);
    }
  };

  const onClickReject = async () => {
    if (!currentJobfile) return
    const reason = window.prompt(t('jobfile.reject_reason'))
    if (reason) {
      try {
        const newJobfile = await JobFileService.rejectJobfile(currentJobfile._id, reason)
        dispatch(setCurrentJobFile(newJobfile))
        toast.success(t("jobfile.reject_success"))
      } catch (err: any) {
        console.error(err);
        toast.error(t("jobfile.reject_fail"))
      }
    } else {
      toast.error(t("jobfile.reject_reason_mandatory"))
    }
  }

  return (

    <div className="flex flex-row w-full">
      <div
        className={`grow flex-1 px-3 py-3 font-medium cursor-pointer text-sm text-center border-t border-1 border-slate-300 ${!areAllDocsValidated || currentJobfile?.usecase == null ? "bg-slate-200 hover:bg-slate-300" : "bg-emerald-200 hover:bg-emerald-300"
          }`}
        onClick={onClickValidate}
      >
        <IoIosArrowDropright className="inline mr-2 -mt-0.5 text-lg" />
        {t("jobfile_documents_list.validate_button")}
      </div>
      {currentJobfile && ![JobfileStatusType.Rejected, JobfileStatusType.None].includes(currentJobfile.status.type) &&
        (<Tooltip title={t("jobfile.reject_jobfile")} arrow={true}>
          <div className="flex text-center items-center justify-center grow-0 w-12 cursor-pointer  border-t border-1 border-slate-300 bg-red-200 hover:bg-red-300" onClick={onClickReject}>
            <MdStopCircle className="inline text-xl text-red-700" />
          </div>
        </Tooltip>)
      }
    </div>
  );
};

export default ValidateJobfileButton;
