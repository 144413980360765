import { ConnectorType, IConnector } from "models/connector";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import connectorsService from "services/connectors.service";
import FTPConnector from "./components/FTPConnector";
import DispatchConnector from "./components/DispatchConnector";

const ConnectorConfiguration = () => {
  const { connectorId } = useParams();
  const [connector, setConnector] = useState<IConnector | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getConnector = async () => {
      try {
        if (!connectorId) throw new Error("ConnectorId is undefined");
        setIsLoading(true);
        const connector = await connectorsService.getConnector(connectorId);
        console.log("connector - ", connector);
        setConnector(connector);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    getConnector()
  }, []);

  if (isLoading) return <div> Loading ...</div>
  if (error) return <div> Error fetching connector: {error} </div>

  if (connector) {
    switch (connector.type) {
      case ConnectorType.FTP:
        return <FTPConnector connector={connector}></FTPConnector>
      case ConnectorType.AKANEA_DOUANE:
        return <FTPConnector connector={connector}></FTPConnector>
      case ConnectorType.FTP_NON_SECURE:
        return <FTPConnector connector={connector}></FTPConnector>
      case ConnectorType.FTP_WITH_PDF:
        return <FTPConnector connector={connector}></FTPConnector>
      case ConnectorType.HTTP:
        return (<div>HTTP</div>);
      case ConnectorType.DISPATCH_MISSION:
        return <DispatchConnector connector={connector}></DispatchConnector>;
      case ConnectorType.DISPATCH_QUOTATION:
        return <DispatchConnector connector={connector}></DispatchConnector>;
      default:
        setError(`Unknown connector type ${connector.type}`);
    }
  }
};

export default ConnectorConfiguration;