import React from 'react';
import TableActionButton from 'components/TableActionButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';

type TableRowValue = {
  value: React.ReactNode;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

type ReferentialsTableRowComponentProps = {
  values: TableRowValue[];
  onDelete: (id: string) => void;
  onClick: () => void;
};

export const ReferentialTableRowComponent: React.FC<ReferentialsTableRowComponentProps> = ({ values, onDelete, onClick }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {values.map((item, index) => {
        if (index === 0) {
          return (
            <TableCell className='hover:underline cursor-pointer w-1/5'
              key={index} align="inherit" onClick={onClick}
            >
              {item.value}
            </TableCell>
          );
        } else if (index === values.length - 1) {
          return (
            <TableCell key={index} align="right">
              <TableActionButton
                Icon={DeleteIcon}
                onClick={() => onDelete('')}
                tooltip={t("admin.referentials.delete")}
                color="default"
                className="max-h-[20px] max-w-[20px]"
              />
            </TableCell>
          );
        } else {
          return (
            <TableCell key={index} align="center">
              {item.value}
            </TableCell>
          );
        }
      })}
    </TableRow>
  )
};

type ReferentialsTableHeaderComponentProps = {
  headers: string[];
}

const TableHeaderComponent: React.FC<ReferentialsTableHeaderComponentProps> = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => {
          return (
            <TableCell key={index} sx={{ fontWeight: 'bold' }} align={index === 0 ? "left" : "center"}>
              {header}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
};

type ReferentialsTableComponentProps = {
  headers: string[];
  children: React.ReactNode;
};

export const ReferentialsTableComponent: React.FC<ReferentialsTableComponentProps> = ({ headers, children }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
        <TableHeaderComponent headers={headers} />
        <TableBody sx={{ width: 'max-content' }}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
