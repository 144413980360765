export enum UserRoleEnum {
  CONTRIBUTOR = "contributor",
  OWNER = "owner",
};

export enum UserAccountStateEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING_INVITATION = "pending_invitation",
  DELETED = "deleted",
}

export enum LoginStyle {
  PASSWORD = "password",
  SSO = "sso",
}

export interface ISavedQuery {
  name: string;
  search_text: string;
  search_documents_types: string[];
}

export interface ISignUpInvitation {
  token: string;
  expires: Date;
}

export interface INewUser {
  email: string;
  firstname?: string;
  lastname?: string;
  last_login?: Date;
  role: UserRoleEnum;
}

export interface IUser extends INewUser {
  _id: string;
  saved_queries: ISavedQuery[];
  signUpInvitation?: ISignUpInvitation;
  accountState: UserAccountStateEnum;
}

export interface IUserPasswordUpdate extends INewUser {
  password?: string;
  newPassword?: string;
  newPasswordCheck?: string;
}

export interface IUserUpdate {
  email: string;
  firstname: string;
  lastname: string;
}
export interface IPasswordUpdate {
  email: string;
  password: string;
  newPassword: string;
}

export interface IPasswordReset {
  email: string;
  newPassword: string;
}