import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { UseCasesState } from "redux/usecases";
import { LabellingState, setCurrentDocument, loadDocumentLabelling } from "redux/labelling";
import { loadJobfileAndDocuments, resetUsecaseForJobfile, selectUsecaseForJobfile, setCurrentJobFile, setLeftPanelVisibility, setNameForJobfile } from "redux/jobfile";
import { setBreadcrumbsMenuItems } from "redux/navigation";
import { JobfileDocument } from "models/jobfile";
import DocumentsServices from "services/documents.service";

import SplitView, { SplitViewOrientation } from "components/SplitView";
import { LabellingPanel } from "components/document/LabellingPanel";
import { selectCustomStyles } from "components/SelectSearch";
import { PageViewer } from "components/document/page_viewer";
import TableLabellingPanel from "components/document/TableLabellingPanel";
import { DocumentsSelector } from "./components/DocumentSelector";
import { UploadDocumentZone } from "./components/UploadDocumentZone";
import SidemenuToggle from "./components/SidemenuToggle";
import ValidateJobfileButton from "./components/ValidateJobfileButton";
import AnnotationIndicator from "components/document/AnnotationIndicator";
import Loading from "components/Loading";
import { LOCALSTORAGE_DOCUMENTS_PANEL_WIDTH_KEY, DEFAULT_DOCUMENTS_PANEL_WIDTH, LOCALSTORAGE_LABELLING_TABLE_PANEL_HEIGHT_KEY, DEFAULT_LABELLING_TABLE_PANEL_HEIGHT } from "config/constants";

export default function JobFileDocuments() {
  const dispatch = useAppDispatch();
  const { currentJobfile, isLeftPanelVisible, loading, currentJobfileDocuments } = useAppSelector((state) => state.jobfile);
  const { currentDocument, focusedTable, isDocumentLoading }: LabellingState = useAppSelector((state) => state.labelling);

  const [, setIsDraggingView] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  let { jobFileId, jobfileDocId } = useParams();

  // Load documents on jobfile change
  useEffect(() => {
    if (!jobFileId) return;
    setIsInitialLoading(true);
    dispatch(setCurrentDocument({ doc: null }));
    dispatch(loadJobfileAndDocuments({ jobFileId, displayLoader: true }));
    // Automatically reload jobfile documents each 10sec
    // TODO: it should be replaced by the collaboration module with live socket
    const automaticRefreshId = setInterval(() => {
      if (!jobFileId) return;
      dispatch(loadJobfileAndDocuments({ jobFileId }));
    }, 10000);
    // some components shared between view (eg. File vs Folder) use the jobFile state to
    // understand in which context they are
    // they may behave differently if we keep the jobfile state running
    // hence we make sure we reset the jobfile state when we leave from this view
    // FIXME: you may find a better way to it
    return () => {
      dispatch(setCurrentJobFile(null));
      clearInterval(automaticRefreshId);
    };
  }, [jobFileId, dispatch]);

  // Set navigation breadcrumbs based on document
  useEffect(() => {
    dispatch(
      setBreadcrumbsMenuItems([
        { text: t("breadcrumbs_menu.jobfiles"), path: "/", state: { search_type: "jobfiles" }, icon: "folders" },
        { text: currentJobfile?.name ?? "", path: `/jobfile/${currentJobfile?._id}/documents`, icon: "folder" },
        { text: t("breadcrumbs_menu.documents") ?? "" },
      ])
    );
  }, [currentJobfile, dispatch]);

  // If there is no selected document and left panel is also, force display the left panel
  useEffect(() => {
    if (!currentDocument && isLeftPanelVisible === false) {
      dispatch(setLeftPanelVisibility(true));
    }
  }, [currentDocument, isLeftPanelVisible, dispatch]);

  // If this is the first time that jobfile is loaded
  // AND documents are loaded
  // AND no document are currently selected
  // => Automatically select the first document or the one given as param
  useEffect(() => {
    if (!currentJobfile || currentDocument || !isInitialLoading) return;
    if (!currentJobfileDocuments || !(currentJobfileDocuments.length > 0)) return;

    setIsInitialLoading(false);
    // if a document has been passed as param get the guy
    const jobfileDocument = currentJobfileDocuments.find((doc) => doc._id === jobfileDocId);

    if (jobfileDocument) {
      onDocumentClick(jobfileDocument);
      return;
    }

    onDocumentClick(currentJobfileDocuments[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJobfile, currentJobfileDocuments, currentDocument, isInitialLoading]);

  //
  // UI Actions
  //

  // On document selection, retrieve the doc + display it
  const onDocumentClick = async (doc: JobfileDocument) => {
    try {
      const fullDoc = await DocumentsServices.getDocument(doc._id);
      dispatch(setCurrentDocument({ doc: fullDoc }));
      dispatch(loadDocumentLabelling(doc._id));
      window.history.pushState(null, "", `/jobfile/${currentJobfile?._id}/documents/${doc._id}`);
    } catch (e) {
      console.error(e);
    }
  };

  // onDocumentStateChange triggers from DocumentsSelector in two cases
  // - A document is deleted
  // - A document is unvalidated
  const onDocumentStateChange = () => {
    dispatch(loadJobfileAndDocuments({ jobFileId: jobFileId! }));
  };

  // Get default side panel width
  const getDefaultPanelWidth = (): number => {
    const savedPanelWidth = localStorage.getItem(LOCALSTORAGE_DOCUMENTS_PANEL_WIDTH_KEY);
    if (savedPanelWidth) return parseInt(savedPanelWidth);
    else return DEFAULT_DOCUMENTS_PANEL_WIDTH;
  };

  // Get default table panel height
  const getDefaultLabellingTablePanelHeight = (): number => {
    const tablePanelHeight = localStorage.getItem(LOCALSTORAGE_LABELLING_TABLE_PANEL_HEIGHT_KEY);
    if (tablePanelHeight) return parseInt(tablePanelHeight);
    else return DEFAULT_LABELLING_TABLE_PANEL_HEIGHT;
  };

  //
  // Rendering
  //
  return (
    <div className="flex h-full flex-1 overflow-hidden">
      <Helmet>
        <title>{currentJobfile?.name ?? ""}</title>
      </Helmet>
      <div className={`grow-0 flex h-full ${isLeftPanelVisible ? "w-80" : "w-4"} flex-none  border-r border-docloop_borderColor bg-[#FFF] shadow-md relative z-1`}>
        <div className="relative flex h-full w-full flex-col alwaysShowScrollbar">
          {currentDocument && <SidemenuToggle />}
          {isLeftPanelVisible && (
            <div className="relative z-40 w-full flex-none select-none bg-white text-xs">
              <EditableJobFileTitle />
              <UseCaseSelector />
              <div className="bg-gray-100 p-3 border-b border-docloop_borderColor text-center text-xs font-semibold shadow-md">
                {t("jobfile_documents_list.documents")} {`(${currentJobfileDocuments.length})`}
              </div>
            </div>
          )}
          <DocumentsSelector
            visible={isLeftPanelVisible}
            documents={currentJobfileDocuments}
            selectedDocumentId={currentDocument?._id ?? null}
            loading={loading}
            onClick={onDocumentClick}
            onDocumentStateChange={onDocumentStateChange}
          />
          {isLeftPanelVisible && (
            <>
              <UploadDocumentZone
                onUploadFinished={() => {
                  if (currentJobfile) dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));
                }}
              />
              <ValidateJobfileButton />
            </>
          )}
        </div>
      </div>
      <div className="min-h-full flex-1 grow relative z-0 overflow-x-hidden">
        <SplitView
          defaultPanelLength={getDefaultPanelWidth()}
          onDraggingStateChange={(value) => {
            setIsDraggingView(value);
          }}
          onPanelLengthChange={(value: number) => {
            localStorage.setItem(LOCALSTORAGE_DOCUMENTS_PANEL_WIDTH_KEY, value.toString());
          }}
          left={
            currentDocument && !isDocumentLoading ? (
              <div className={"h-full relative z-0 flex grow flex-col alwaysShowScrollbar"}>
                <SplitView
                  orientation={SplitViewOrientation.Vertical}
                  defaultPanelLength={getDefaultLabellingTablePanelHeight()}
                  onDraggingStateChange={(value) => {
                    setIsDraggingView(value);
                  }}
                  onPanelLengthChange={(value: number) => {
                    localStorage.setItem(LOCALSTORAGE_LABELLING_TABLE_PANEL_HEIGHT_KEY, value.toString());
                  }}
                  left={
                    <>
                      <PageViewer annotationEnabled={true} />
                      <AnnotationIndicator />
                    </>
                  }
                  right={focusedTable && <TableLabellingPanel />}
                />
              </div>
            ) : isDocumentLoading ? (
              <Loading />
            ) : null
          }
          right={currentDocument && !isDocumentLoading ? <LabellingPanel /> : null}
        />
      </div>
    </div>
  );
}

// Locally styled component that allows to double click on the folder title and change it
const EditableJobFileTitle = () => {
  const dispatch = useAppDispatch();
  const { currentJobfile } = useAppSelector((state) => state.jobfile);

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(currentJobfile?.name);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event: any) => {
    setText(event.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    if (!currentJobfile) return null;
    if (!text || text.length === 0) {
      setText(currentJobfile.name);
    } else {
      await dispatch(setNameForJobfile({ jobFileId: currentJobfile._id, name: text }));
      await dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));
    }
  };

  useEffect(() => {
    if (!currentJobfile) return;
    setText(currentJobfile.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJobfile?.name]);

  // Focus the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div className="bg-gray-100 p-3 border-b border-docloop_borderColor text-center text-xs font-semibold shadow-md" onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input className="bg-gray-100 p-3 border-b text-center text-xs font-semibold shadow-md" type="text" value={text} onChange={handleChange} onBlur={handleBlur} ref={inputRef} />
      ) : (
        <span className="w-full inline-block overflow-hidden text-ellipsis">{text}</span>
      )}
    </div>
  );
};

// Local component for selecting the docType
const UseCaseSelector = () => {
  const dispatch = useAppDispatch();
  const { currentJobfile, updating } = useAppSelector((state) => state.jobfile);
  const { currentDocument } = useAppSelector((state) => state.labelling);
  const useCasesState: UseCasesState = useAppSelector((state) => state.usecases);

  // we are in the context of a jobfile, check if there is a use case already assigned
  // FIXME: Harmonize use of usecase vs usecase_id to avoid having to use dispatch(loadJobfileAndDocuments...
  const jobFileUsecase = currentJobfile?.usecase;

  // Format useCases for the select component
  const formatUseCasesSelectOptions = () => {
    return useCasesState.useCases.map((u) => ({ value: u._id, label: u.name }));
  };

  // Triggering when a use case is selected
  const onUseCaseSelection = async (option: any) => {
    if (!currentJobfile) return null;
    // If the selected usecase is the same as the current one, do nothing
    if (option && option.value && currentJobfile.usecase?._id === option.value) return;
    // Request confirmation if jobfile already has a usecase
    const overwrite = currentJobfile.usecase?._id ? window.confirm(t("labelling_panel.change_usecase_warning")) : true;
    if (!overwrite) return;

    try {
      if (!option) {
        // Reset usecase for jobfile
        await dispatch(resetUsecaseForJobfile({ jobFileId: currentJobfile._id }));
      } else {
        // Update usecaseId in jobfile
        await dispatch(selectUsecaseForJobfile({ jobFileId: currentJobfile._id, useCaseId: option ? option.value : null }));
      }

      // Force reload to update value in dropdown
      dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));

      // If document had a docType, then we unselect it to prevent labelling issue (due to docType reset on backend)
      if (currentDocument?.doc_type) dispatch(setCurrentDocument({ doc: null }));

      toast.success(t("jobfile_documents_list.usecase_selected"));
    } catch (error) {
      console.error(error);
      toast.error(t("jobfile_documents_list.usecase_selection_error"));
    }
  };

  return (
    <div className="px-2 py-3 border-b border-docloop_borderColor">
      <Select
        classNamePrefix="useCase_select"
        isSearchable={true}
        name="usecase"
        value={jobFileUsecase ? { value: jobFileUsecase._id, label: jobFileUsecase.name } : null}
        placeholder={t("labelling_panel.use_case_select_placeholder")}
        options={formatUseCasesSelectOptions()}
        onChange={onUseCaseSelection}
        styles={selectCustomStyles}
        isDisabled={updating}
        isLoading={updating}
        tabSelectsValue={false}
        isClearable={true}
      />
    </div>
  );
};
