import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import * as Yup from 'yup';
import logo from "assets/logotext-white.png";
import background from "assets/bg-auth.png";
import { Formik, Form, FormikHelpers } from "formik";
import { Box, CircularProgress, TextField, Typography, Button } from "@mui/material";
import { ButtonStyle, default as DocloopButton } from "components/Button";


import registrationService from "services/registration.service";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "redux/hooks";
import { login } from "redux/users";
import api from "services/api";
import authService from "services/auth.service";
import { ReactComponent as MicrosoftLogo } from "assets/svg/microsoft_icon.svg";
import { AuthenticationResult } from "@azure/msal-browser";
import toast from "react-hot-toast";

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export default function Registration() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const [useMicrosoft, setUseMicrosoft] = useState(false);

  const [userRegistrationInfo, setUserRegistrationInfo] = useState<FormValues>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });


  useEffect(() => {
    const fetchInvitationData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const tenantId = searchParams.get('tenant');

      if (!tenantId || !token) {
        setError(t("registration_view.invalid_link_missing_params"));
        setLoading(false);
        return;
      }

      try {
        const response = await registrationService.registerByInviteToken(tenantId, token);
        setUserRegistrationInfo({
          email: response.data.email ? response.data.email : '',
          firstname: response.data.firstname ? response.data.firstname : '',
          lastname: response.data.lastname ? response.data.lastname : '',
          password: '',
          confirmPassword: '',
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchInvitationData();
  }, [location.search]);


  const validationSchema = Yup.object().shape({
    firstname: Yup.string(),
    lastname: Yup.string(),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: useMicrosoft ? Yup.string() : Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        t("registration_view.password_complexity_rules")
      )
      .required('Password is required'),
    confirmPassword: useMicrosoft ? Yup.string() : Yup.string().when("password", (password, schema) => {
      return password && password.length > 0
        ? schema.oneOf([Yup.ref('password')], t("registration_view.passwords_must_match")).required(t("registration_view.confirm_password_required"))
        : schema.notRequired();
    })
  });



  const handleClassicRegistration = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const tenantId = searchParams.get('tenant');

    if (!tenantId || !token) {
      setError(t("registration_view.invalid_link_missing_params"));
      setSubmitting(false);
      return;
    }
    try {
      await registrationService.validateRegistration(tenantId, token, values);
      const data = await authService.login(values.email, values.password);

      dispatch(login({
        tenant_id: data.tenant_id,
        email: data.email,
      }));

      setSubmitting(false);
      navigate('/'); // Redirect to login page after successful registration
    } catch (err: any) {
      console.error(err);
      if (err?.response && err.response.data?.error) setError(t("registration_view.registration_failed") + "\n" + t(err.response.data.error));
      else setError(t("registration_view.registration_failed"));

      setSubmitting(false);
    }
  }

  const handleMicrosoftRegistration = async () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const tenantId = searchParams.get('tenant');

    if (!tenantId || !token) {
      setError(t("registration_view.invalid_link_missing_params"));
      setLoading(false);
      return;
    }

    let res: AuthenticationResult | null = null;
    try {
      res = await instance.loginPopup({
        scopes: ["openid", "profile", "email"],
        prompt: "select_account",
      });
    } catch (err) {
      console.error("Microsoft Popup closed of failed");
      return
    }

    if (!res || !res.idToken) {
      console.error("Microsoft authentication result failed");
      return;
    }
    try {
      // If Microsoft response contain the name of the user, override registation name with SSO name
      if (res.account && res.account.name) {
        const name = res.account.name.split(" ");
        [userRegistrationInfo.firstname, userRegistrationInfo.lastname] = [name[0], name.pop() ?? ""];
      }

      const idToken = res.idToken;
      if (res.account?.username !== userRegistrationInfo.email) {
        toast.error(t("registration_view.emails_mismatch") + ` - ${res.account?.username}/${userRegistrationInfo.email}`);
        return;
      }
      console.log("Successfully authenticated to Microsoft");
      // we check registration AFTER trying to open the popup because the popup can be blocked by some browsers
      // so we need the user to un-block the popup before going to the next step
      await registrationService.validateRegistration(tenantId, token, userRegistrationInfo);

      if (!idToken) {
        console.error("Msal response has no id token");
        console.log(res);
        // toast.error(t("signin_view.microsoft.error"));
        return;
      }
      const data = await sendMsalTokenToBackend(idToken);

      dispatch(login({
        tenant_id: data?.tenant_id,
        email: data?.email,
      }));

      navigate('/'); // Redirect to login page after successful registration
    } catch (err) {
      console.error(err);
      setError('Registration failed');
    }
  }

  // API Call to backend to validate the authentication
  const sendMsalTokenToBackend = async (token: string) => {
    try {
      const response = await api.post("/auth/oauth2", {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        email: response.data.email,
        tenant_id: response.data.tenant_id,
      };

    } catch (error) {
      console.error("Error sending access token to backend:", error);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen h-1/3 bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
        <div className="flex overflow-hidden rounded-xl bg-white">
          <div className="flex items-center bg-gradient px-20">
            <img className="h-32" src={logo} alt="" />
          </div>
          <div className="p-10 text-center">
            <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>
            <CircularProgress />
          </div>
        </div>
      </div>
    )
  }


  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen h-1/3 bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
        <div className="flex overflow-hidden rounded-xl bg-white">
          <div className="flex items-center bg-gradient px-20">
            <img className="h-32" src={logo} alt="" />
          </div>
          <div className="p-10 text-center">
            <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>
            <Typography
              color="error"
              style={{ whiteSpace: "break-spaces" }}
            >
              {error}
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex overflow-hidden rounded-xl bg-white">
        <div className="flex items-center bg-gradient px-20">
          <img className="h-32" src={logo} alt="" />
        </div>
        <div className="p-10 text-center">
          <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>

          <div className="h-[1px] bg-gray-100 w-50 mt-6 mb-6 block" />
          <div className="text-center w-72">


            {/* Option 1: Register via Microsoft authentication */}
            <DocloopButton
              text={t("registration_view.microsoft")}
              leftIcon={<MicrosoftLogo className="w-5 inline mr-3 -mt-0.5 py-1" />}
              color={ButtonStyle.DarkGray}
              className="border w-full"
              onClick={handleMicrosoftRegistration}
            />
            <p className="my-6 font-semibold text-sm text-gray-800">{t("signin_view.separator_text")}</p>

            {/* Option 2: Register via password update */}
            <Formik initialValues={userRegistrationInfo} validationSchema={validationSchema} onSubmit={handleClassicRegistration}>
              {({ isSubmitting, handleChange, values, errors, touched, setFieldValue, submitForm }) => (
                <Form>
                  <Box mb={2}>
                    <TextField
                      disabled={true}
                      size="small"
                      name="email"
                      label={t("registration_view.form.email")}
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      name="firstname"
                      label={t("registration_view.form.firstname")}
                      value={values.firstname}
                      onChange={handleChange}
                      error={touched.firstname && Boolean(errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      name="lastname"
                      label={t("registration_view.form.lastname")}
                      value={values.lastname}
                      onChange={handleChange}
                      type="text"
                      error={touched.lastname && Boolean(errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                      autoComplete="new-lastname"
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      name="password"
                      label={t("registration_view.form.password")}
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      name="confirmPassword"
                      label={t("registration_view.form.confirm_password")}
                      type="password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                    />
                  </Box>
                  {error && (
                    <Box mb={2}>
                      <Typography color="error"> {error}</Typography>
                    </Box>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      setFieldValue("useMicrosoft", false);
                      setUseMicrosoft(false);
                      submitForm();
                    }}
                    disabled={isSubmitting}>
                    {t("registration_view.register_password")}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div >
  );
}
