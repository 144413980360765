import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Stack, Select, MenuItem } from "@mui/material";
// import Select from "react-select";

import { t } from "i18next";

import { IProcessor } from "models/usecase";
import { IReferential } from "models/referential";
import { IDocumentTypeField } from "models/document_type";
import { ConnectorType, IConnectorMeta } from "models/connector";

type ReferentialLookupDispatchCustomerInputProps = {
  processor: IProcessor;
  referentials: IReferential[];
  usableDocumentsFields: IDocumentTypeField[];
  onChange: (proc: any) => void;
  connectors: IConnectorMeta[] | null
};


const ReferentialLookupDispatchCustomerInput: React.FC<ReferentialLookupDispatchCustomerInputProps> = ({ processor, referentials, usableDocumentsFields, onChange, connectors }) => {

  const handleChange = (e: any) => {
    const newConnector = connectors?.find((c) => c.id === e.target.value)
    onChange(newConnector);
  };

  return (
    <div className="w-full flex">

      <FormControl fullWidth size="small">
        <InputLabel required>{t("processor.dispatch.connector")}</InputLabel>
        <Select
          value={processor.id ?? ""}
          required
          label={t("processor.dispatch.connector")}
          onChange={handleChange}
          SelectDisplayProps={{ style: { padding: "2px 30px 2px 6px", fontSize: 12 } }}
        >
          {connectors && connectors.filter((c) => c.type === ConnectorType.DISPATCH_MISSION).map((connector, index) => (
            <MenuItem key={`field_${index}_${connector}`} value={connector.id} style={{ fontSize: 12 }}>
              {connector.type} - {connector.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ReferentialLookupDispatchCustomerInput;
