import React from "react";
import { t } from "i18next";

import Select, { ActionMeta, MultiValue } from "react-select";

import { selectCustomStyles } from "components/SelectSearch";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSearchJobfileStatuses } from "redux/search";
import { JobfileStatus, JobfileStatusType } from "models/jobfile";

type SearchTypeProps = {};

export const SearchJobfileStatusesSelector: React.FC<SearchTypeProps> = () => {
  const dispatch = useAppDispatch();
  const { searchJobfileStatuses } = useAppSelector((state) => state.search);

  const onSelect = async (newValue: JobfileStatusType[]) => {
    dispatch(setSearchJobfileStatuses(newValue));
  };

  // Rendering
  return (
    <Select
      classNamePrefix="jobfileStatus_select"
      className="ml-2 text-xs"
      // isSearchable
      isMulti
      isClearable={true}
      name="jobfile_status"
      value={searchJobfileStatuses.map((st: JobfileStatusType) => ({ value: st, label: t(`jobfile_status_${st}`) }))}
      placeholder={t("home.filters.jobfile_statuses.placeholder")}
      options={Object.values(JobfileStatusType).map((st) => ({ value: st, label: t(`jobfile_status_${st}`) }))}
      onChange={(newValue: MultiValue<any>, actionMeta: ActionMeta<unknown>) => onSelect(newValue.map((v) => v.value as JobfileStatusType))}
      styles={selectCustomStyles}
      tabSelectsValue={false}
    />
  );
};
