import React, { ReactNode } from "react";
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { CiCalendarDate } from "react-icons/ci";
import { TbNumber, TbDatabaseSearch } from "react-icons/tb";
import { MdOutlineDocumentScanner, MdOutlineRemoveRedEye } from "react-icons/md";
import { RxLetterSpacing } from "react-icons/rx";
import { t } from "i18next";

import { IDocumentTypeField } from "models/document_type";
import { IReferential } from "models/referential";
import { AVAILABLE_PROCESSORS, IUsecaseSectionDataField, IProcessor } from "models/usecase";
import ProcessorInput from "./processors/ProcessorInput";
import { ConnectorType, IConnectorMeta } from "models/connector";

type ConfigurationFieldProps = {
  field: IUsecaseSectionDataField;
  usableDocumentsFields: IDocumentTypeField[];
  referentials: IReferential[];
  onChange: (required: boolean, proc: IProcessor) => void;
  className?: string;
  previewValue: string | null;
  connectors: IConnectorMeta[];
};

const ConfigurationField: React.FC<ConfigurationFieldProps> = ({ field, usableDocumentsFields, referentials, className = "", previewValue, onChange, connectors }) => {
  const handleProcessorChange = (event: SelectChangeEvent<string>) => {
    const processorType = AVAILABLE_PROCESSORS.find((p) => p === event.target.value);
    onChange(field.required, { type: processorType! });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, field.processor);
  };

  const getIconForProcessor = (processor: string): ReactNode | null => {
    switch (processor) {
      case "doc_field":
        return <MdOutlineDocumentScanner className="inline mr-1" />;
      case "format_date":
        return <CiCalendarDate className="inline mr-1" />;
      case "format_number":
        return <TbNumber className="inline mr-1" />;
      case "substring":
        return <RxLetterSpacing className="inline mr-1" />;
      case "referential_lookup":
        return <TbDatabaseSearch className="inline mr-1" />;
      case ConnectorType.DISPATCH_MISSION:
        return <TbDatabaseSearch className="inline mr-1" />;
      default:
        return null;
    }
  };

  const selectedProcessor = AVAILABLE_PROCESSORS.find((pt) => pt === field.processor?.type) ?? AVAILABLE_PROCESSORS[0];
  return (
    <Grid container spacing={0} alignItems="start" className={"mb-2"}>
      <Grid item xs={4}>
        <div className="text-xs font-medium text-slate-500 mb-1" title={field.id}>
          {t(`wip.${field.id}`, field.label)}
        </div>
        <label title={t("usecase_configuration.required_title")} className="text-[11px] font-normal text-slate-400 align-top">
          <input type="checkbox" className="mr-1 w-[9px] h-[9px]" checked={field.required} onChange={handleFieldChange} />
          is required
        </label>
      </Grid>
      <Grid item xs={8} className="flex flex-col items-start">
        <div className="grow-0">
          <FormControl fullWidth size="small" style={{ maxWidth: 180 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedProcessor}
              displayEmpty
              onChange={handleProcessorChange}
              className="mr-2"
              SelectDisplayProps={{ style: { padding: "2px 30px 2px 6px", fontSize: 12 } }}
            >
              {AVAILABLE_PROCESSORS.map((processor, index) => (
                <MenuItem key={`field_${field.id}_select_proc_${index}`} value={processor} style={{ fontSize: 12 }}>
                  {getIconForProcessor(processor)}
                  {t(`processor.${processor}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full">
          <ProcessorInput
            processor={field.processor}
            usableDocumentsFields={usableDocumentsFields}
            referentials={referentials}
            connectors={connectors}
            onChange={(settings: Object) => onChange(field.required, { type: field.processor.type, ...settings })}
          />
          {previewValue !== null && (
            <div title={t("usecase_configuration.preview_icon_title")} className="flex items-center text-xs font-mono text-blue-700 mt-1">
              <MdOutlineRemoveRedEye className="mr-1" />
              {previewValue !== "" ? <span> {previewValue} </span> : <i className="text-blue-300">{t("usecase_configuration.no_preview")}</i>}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ConfigurationField;
