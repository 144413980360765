import { StylesConfig } from 'react-select';

// Custom styles for react-select component
export const selectCustomStyles: StylesConfig = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    fontSize: 12
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer",
  }),
  input: (styles) => ({
    ...styles,
    boxShadow: 'none !important',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: 180
  })
};

export const selectCustomStylesFitContent = {
  container: (provided: any) => ({
    ...provided,
    width: 'auto',
    // minWidth: 'fit-content', // Ensures the select box fits the longest option
    minWidth: '40%', // Ensures the select box fits the longest option
    zIndex: 100000
  }),
  control: (provided: any) => ({
    ...provided,
    // minWidth: 'fit-content',
    minWidth: '40%',
    zIndex: 100000
  }),
};