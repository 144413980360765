import React from "react";
import { t } from "i18next";
import { FaCheckCircle } from "react-icons/fa";

import { DocumentStatus } from "models/document";
import { ImSpinner2 } from "react-icons/im";

type DocumentStatusLabelProps = {
  status: DocumentStatus;
};

export const DocumentStatusLabel: React.FC<DocumentStatusLabelProps> = ({ status }) => {
  const commonClasses = "inline grow-0 shrink px-1.5 py-0.5 text-[12px] mt-1 rounded-sm font-medium select-none";

  switch (status) {
    case DocumentStatus.None:
      return
    case DocumentStatus.ToClassify:
      return <span className={`${commonClasses} bg-orange-200 text-orange-600`}>{t("document_status_" + status)}</span>;

    case DocumentStatus.ToValidate:
      return <span className={`${commonClasses} bg-yellow-200 text-yellow-600`}>{t("document_status_" + status)}</span>;

    case DocumentStatus.Processing:
      return (
        <div className={`${commonClasses} bg-stone-200 text-stone-600`}>
          {t("document_status_" + status)}<ImSpinner2 className="inline ml-1 animate-spin text-xs" />
        </div>
      );
    case DocumentStatus.Error:
      return <span className={`${commonClasses} bg-red-200 text-red-600`}>{t("document_status_" + status)}</span>;

    default:
      return <span className={`${commonClasses} bg-gray-200 text-gray-600`}>{t("document_status_" + status)} {status}</span>;
  }
};
