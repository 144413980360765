// Custom page component that use react-intersection-observer
// This observer will allow us to detect when the page is displayed on the screen and trigger OCR loading
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Page } from "react-pdf";
import toast from "react-hot-toast";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { PageMetadata, TableFocusedCell, addColumnMappingWords, addPageMetadata, selectMappingWords, setFocusedField, setFocusedTable, setFocusedTableCell } from "redux/labelling";

import { OcrBbox, OcrWord } from "types/labelling";
import { AzureOcrWord } from "types/azure";
import { DocumentFieldOrigin, DocumentStatus, IDocument, IDocumentMappedField } from "models/document";
import { SelectionZone, SelectionZoneRectangle, getColorForFieldOrigin, getMappedFieldsForPage, getPageRotation, rotateBbox } from "utils/labelling";
import { useDocumentTypes } from "hooks/DocumentTypesHook";
import { DocumentTypeFieldType } from "models/document_type";
import { ANNOTATION_ORIGIN_COLORS } from "config/constants";

import DocumentsServices from "services/documents.service";

type CustomPageProps = {
  doc: IDocument;
  pageNumber: number;
  pageDisplayWidth: number | undefined;
  documentReady: boolean;
  renderingOrientation: number;

  annotationEnabled: boolean;

  onUserZoneSelection: (zone: SelectionZone) => void;
};
const CustomPage: React.FC<CustomPageProps> = ({ doc, pageNumber, pageDisplayWidth, documentReady, renderingOrientation, annotationEnabled, onUserZoneSelection }) => {
  const dispatch = useAppDispatch();
  const { currentDocType } = useDocumentTypes();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { pagesMetadata, focusedField, focusedTable, multiMapping, selectedMappingWords } = useAppSelector((state) => state.labelling);

  const pageCanvas = useRef<HTMLCanvasElement | null>(null); // PdfJs page canvas
  const pageAnnotationsCanvas = useRef<HTMLCanvasElement | null>(null); // Docloop pages annotation canvas
  const [pageOCR, setPageOCR] = useState<any | null>(null); // RAW OCR for this page
  const [pageMetadata, setPageMetadata] = useState<PageMetadata | null>(null);
  const [pageWords, setPageWords] = useState<OcrWord[]>([]); // Words on this page

  const userSelectedZone = useRef<SelectionZone>({ x: 0, y: 0, w: 0, h: 0, mouseX: 0, mouseY: 0, active: false }); // User current selected zone

  const { ref, inView } = useInView({
    threshold: 0,
  });

  // Init annotation canvas (including loading OCR) when the page is visible
  useEffect(() => {
    if (inView && isLoaded && documentReady) {
      initAnnotationCanvas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isLoaded, documentReady]);

  // Save current page metadata locally in this component
  useEffect(() => {
    const pageMetadata = pagesMetadata.find((pm) => pm.page === pageNumber);
    if (!pageMetadata) return;

    setPageMetadata(pageMetadata);
  }, [pagesMetadata, pageNumber]);

  // Refresh mapped zones based on multiple criterias: focusedField, focusedTable.focusedColumn, page
  useEffect(() => {
    if (!annotationEnabled) return;

    // On pagesMetadata change, check if canvas dimensions are still identical between pageCanvas and annotationCanvas
    // An issue related to PdfJs can occured when we apply a rotation on a page. When it happens, canvas width/height can be hazardous and we need to adapt our own annotation canvas dimensions
    if (pageCanvas.current && pageAnnotationsCanvas.current) {
      // Apply change only if dimension(s) changed
      if (pageAnnotationsCanvas.current.width !== pageCanvas.current.width) pageAnnotationsCanvas.current.width = pageCanvas.current.width;
      if (pageAnnotationsCanvas.current.height !== pageCanvas.current.height) pageAnnotationsCanvas.current.height = pageCanvas.current.height;
    }

    // If canvas are init, then refresh all drawing
    if (pageCanvas.current && pageAnnotationsCanvas.current && pageMetadata) {
      clearCanvas();

      drawMappedFields();
      // drawTableRows();
      drawSelectedMappingWords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, pageNumber, annotationEnabled, focusedField, multiMapping, selectedMappingWords, focusedTable, pageMetadata]);

  // Each time the current document change, reset canvas listeners in order for them to have the last redux state
  useEffect(() => {
    if (!annotationEnabled) return;
    addListenersToAnnotationCanvas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, pageNumber, focusedField, multiMapping, focusedTable, pagesMetadata]);

  //
  // Annotation initialization
  //

  // Init the annotation canvas for a page
  const initAnnotationCanvas = async () => {
    if (!pageCanvas.current) return;

    var canvas = document.createElement("canvas");
    pageAnnotationsCanvas.current = canvas;

    // if a canvas already exists with the same id, remove it
    const canvasId = `canvas-annotation-${pageNumber}`;
    const existingCanvas = document.getElementById(canvasId);
    if (existingCanvas) existingCanvas.remove();

    canvas.className = "absolute top-0 left-0 z-10 w-full h-full";
    canvas.id = canvasId;
    if (pageCanvas) {
      canvas.width = pageCanvas.current.width;
      canvas.height = pageCanvas.current.height;
      pageCanvas.current.parentNode?.appendChild(canvas);
    }

    if (annotationEnabled) {
      addListenersToAnnotationCanvas();
      await loadOCR();

      clearCanvas();
      drawMappedFields();
      // drawTableRows();
      drawSelectedMappingWords();
    }
  };

  const addListenersToAnnotationCanvas = () => {
    if (pageAnnotationsCanvas.current) {
      pageAnnotationsCanvas.current.onmouseup = (ev: MouseEvent) => onMouseUp(ev);
      pageAnnotationsCanvas.current.onmousedown = (ev: MouseEvent) => onMouseDown(ev);
      pageAnnotationsCanvas.current.onmousemove = (ev: MouseEvent) => onMouseMove(ev);
      pageAnnotationsCanvas.current.onmouseleave = (ev: MouseEvent) => onMouseLeave(ev);
    }
  };

  // Load OCR for a page
  const loadOCR = async () => {
    if (!doc || pageOCR) return;

    try {
      const ocrPage = await DocumentsServices.getDocumentPageLayout(doc._id, pageNumber);
      setPageOCR(ocrPage);

      // Save pages dimensions / rotation information
      dispatch(
        addPageMetadata({
          page: pageNumber,
          width: ocrPage.width,
          height: ocrPage.height,
          rotationAngle: getPageRotation(ocrPage.angle),
        })
      );

      // Store all words for this page in local ref
      const words: OcrWord[] = [];

      ocrPage.words.forEach((word: AzureOcrWord) => {
        const bbox = {
          x0: word.polygon[0].x,
          x1: word.polygon[2].x,
          y0: word.polygon[0].y,
          y1: word.polygon[2].y,
        };

        words.push({
          page: pageNumber,
          content: word.content,
          bbox: bbox,
          confidence: word.confidence,
        });
      });

      setPageWords(words);
    } catch (e) {
      console.warn(`Load OCR for page ${pageNumber} failed`);
      console.warn(e);
    }
  };

  //
  // Drawing related functions
  //

  // Draw all mapped fields on the canvas
  const drawMappedFields = () => {
    if (!doc || !pageAnnotationsCanvas || !pageMetadata) return;

    let allMappedFields = getMappedFieldsForPage(doc, pageNumber);
    if (allMappedFields.length === 0) return;

    for (let field of allMappedFields) {
      if (!field.mapping) continue;
      for (const word of field.mapping) {
        if (!word.bbox || word.page !== pageNumber) continue;

        // Check if a field is currently focused
        let isFieldFocused = false;
        // #1 Key value field behaviour i.e. original simple behaviour
        if (focusedField && !field.table) {
          isFieldFocused = focusedField.technicalName === field.field_id;
        }
        // #2 Table annotation cell behaviour i.e. need to get table id and row number to check if the field is focused
        else if (focusedTable?.focusedCell) {
          isFieldFocused = focusedTable.focusedCell.cell.technicalName === field.field_id && focusedTable.focusedCell.row === field.row;
        }
        // #3 Column mapping behaviour i.e. focus all fields of the current column focused
        else if (focusedTable?.focusedColumn) {
          isFieldFocused = focusedTable.focusedColumn.column.technicalName === field.field_id && field.row !== undefined;
        }

        const mappingColor = isFieldFocused ? ANNOTATION_ORIGIN_COLORS.HIGHLIGHTING : getColorForFieldOrigin(field.origin, field.row);
        const forceOpaque = isFieldFocused;
        const lineWidth = isFieldFocused ? 2 : 1;

        const scaledBbox = convertBboxToCanvasScale(word.bbox);
        if (scaledBbox) drawWordOverlay(convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, lineWidth, forceOpaque);
      }
    }
  };

  // Draw all table rows indicators on the canvas
  const drawTableRows = () => {
    if (!doc || !pageAnnotationsCanvas.current || !pageMetadata) return;

    let allMappedFields = getMappedFieldsForPage(doc, pageNumber);
    if (allMappedFields.length === 0) return;

    // 1) Isolate all rows of the page
    const rows: TableRowPosition[] = [];
    for (let field of allMappedFields) {
      if (!field.mapping) continue;
      for (const word of field.mapping) {
        if (!word.bbox || word.page !== pageNumber || field.row === undefined) continue;

        // 1.2) For each row, get the min y0 and the max y1
        const scaledBbox = convertBboxToCanvasScale(word.bbox);
        if (scaledBbox) {
          const entry = rows.find((r) => r.index === field.row);
          if (entry) {
            if (scaledBbox.y0 < entry.y0) entry.y0 = scaledBbox.y0;
            if (scaledBbox.y1 > entry.y1) entry.y1 = scaledBbox.y1;
          } else {
            rows.push({ index: field.row, y0: scaledBbox.y0, y1: scaledBbox.y1 });
          }
        }
      }
    }

    // 2) Draw a row indicator for each detected lines
    for (const row of rows) {
      const scaledBbox = {
        x0: 0,
        x1: 30,
        y0: row.y0,
        y1: row.y1,
      };
      const activeRowRect = convertOcrBoxToSelectionZoneRectangle(scaledBbox);
      const isFieldFocused = focusedTable?.focusedCell?.row === row.index;
      const color = isFieldFocused ? ANNOTATION_ORIGIN_COLORS.HIGHLIGHTING : getColorForFieldOrigin(DocumentFieldOrigin.Mapping, row.index);
      drawWordOverlay(activeRowRect, color, 0, isFieldFocused);

      let ctx = pageAnnotationsCanvas.current.getContext("2d") || null;
      if (ctx) {
        ctx.font = `${Math.min(activeRowRect.h - 1, activeRowRect.w * 0.5)}px helvetica`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "black";
        ctx.fillText(`${row.index + 1}`, activeRowRect.x + activeRowRect.w / 2, activeRowRect.y + activeRowRect.h / 2);
      }
    }
  };

  // Draw selected mapping words (if needed)
  const drawSelectedMappingWords = () => {
    if (!doc || !pageAnnotationsCanvas || !pageMetadata) return;

    // If there is no focused field but selected words, we display them
    if (!focusedField && !focusedTable?.focusedCell?.edition && selectedMappingWords && selectedMappingWords.length > 0) {
      // Highlight selected words
      // We are on the canvas of a particular page we will then select only the words of this page to display
      const selectedMappingWordsInPage = selectedMappingWords.filter((word) => word.page === pageNumber);
      for (const word of selectedMappingWordsInPage) {
        const mappingColor = getColorForFieldOrigin(DocumentFieldOrigin.Manual);
        const scaledBbox = convertBboxToCanvasScale(word.bbox);
        if (scaledBbox) drawWordOverlay(convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, 1, true);
      }

      // Note: An annotation modal will be automatically displayed based on the selected words
    }

    // If we are in column mapping mode
    if (focusedTable?.focusedColumn) {
      // Highlight selected words
      // We are on the canvas of a particular page we will then select only the words of this page to display
      for (const words of focusedTable.focusedColumn.selectedMappingWords) {
        for (const word of words) {
          if (word.page !== pageNumber) continue;

          const mappingColor = getColorForFieldOrigin(DocumentFieldOrigin.Manual);
          const scaledBbox = convertBboxToCanvasScale(word.bbox);
          if (scaledBbox) drawWordOverlay(convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, 1, true);
        }
      }
    }
  };

  // Function that draw a Rectangle based on a position
  const drawWordOverlay = (rect: SelectionZoneRectangle, color: string, lineWidth: number = 1, forceOpaque: Boolean = false) => {
    let ctx = pageAnnotationsCanvas.current?.getContext("2d") || null;
    if (!ctx) return null;

    const { x, y, w, h } = rect;

    ctx.fillStyle = forceOpaque ? `${color}90` : focusedField ? `${color}2A` : `${color}40`;
    ctx.fillRect(x, y, w, h);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = focusedField && !forceOpaque ? `${color}BB` : color;
    ctx.setLineDash([]);
    ctx.beginPath();
    ctx.rect(x - lineWidth, y - lineWidth, w + lineWidth * 2, h + lineWidth * 2);
    ctx.stroke();
  };

  // Clear annotation canvas
  const clearCanvas = () => {
    if (!pageAnnotationsCanvas.current) return;
    pageAnnotationsCanvas.current.getContext("2d")?.clearRect(0, 0, pageAnnotationsCanvas.current.width, pageAnnotationsCanvas.current.height);
  };

  // Convert bbox to document scale
  const convertBboxToCanvasScale = (bbox: OcrBbox): OcrBbox | null => {
    if (!pageAnnotationsCanvas.current || !pageMetadata) return null;

    let { width, height } = pageMetadata;
    if (!(width * height > 0)) throw new Error(`Invalid page size ${pageMetadata}`);

    let rotatedBbox = rotateBbox(bbox, width, height, -pageMetadata.rotationAngle);

    // If there is a rotation of 90 or 270 degrees, we need to switch layout width and height
    if (Math.abs(pageMetadata.rotationAngle) === 90 || Math.abs(pageMetadata.rotationAngle) === 270) [width, height] = [height, width];

    let scaledBbox = {
      x0: (rotatedBbox.x0 * pageAnnotationsCanvas.current.width) / width,
      x1: (rotatedBbox.x1 * pageAnnotationsCanvas.current.width) / width,
      y0: (rotatedBbox.y0 * pageAnnotationsCanvas.current.height) / height,
      y1: (rotatedBbox.y1 * pageAnnotationsCanvas.current.height) / height,
    };

    if (scaledBbox.x0 > scaledBbox.x1) [scaledBbox.x0, scaledBbox.x1] = [scaledBbox.x1, scaledBbox.x0];
    if (scaledBbox.y0 > scaledBbox.y1) [scaledBbox.y0, scaledBbox.y1] = [scaledBbox.y1, scaledBbox.y0];

    return scaledBbox;
  };

  /**
   * Converts an OCR bounding box (x0, y0, x1, y1) to a selection zone rectangle (x, y, w, h).
   *
   * @param {OcrBbox} bbox - The OCR bounding box to convert.
   * @return {SelectionZoneRectangle} The converted selection zone rectangle.
   */
  const convertOcrBoxToSelectionZoneRectangle = (bbox: OcrBbox): SelectionZoneRectangle => {
    const rect: SelectionZoneRectangle = {
      x: bbox.x0,
      y: bbox.y0,
      w: bbox.x1 - bbox.x0,
      h: bbox.y1 - bbox.y0,
    };
    return rect;
  };

  //
  // Mouse events listeners
  //

  // Get current mouse position
  // Note: we have to convert the position to match the real canvas dimensions
  const getMousePos = (evt: MouseEvent) => {
    if (!pageAnnotationsCanvas.current) return { x: 0, y: 0 };

    const rect = pageAnnotationsCanvas.current.getBoundingClientRect();

    let x = ((evt.clientX - rect.left) * pageAnnotationsCanvas.current.width) / rect.width;
    let y = ((evt.clientY - rect.top) * pageAnnotationsCanvas.current.height) / rect.height;
    return { x, y };
  };

  // When the user push left click of the mouse, then we open the userSelectedZone
  const onMouseDown = (evt: MouseEvent) => {
    const { x, y } = getMousePos(evt);
    userSelectedZone.current.x = Number(x);
    userSelectedZone.current.y = Number(y);
    userSelectedZone.current.active = true;
  };

  // Listen mouse move
  const onMouseMove = (evt: MouseEvent) => {
    if (!pageAnnotationsCanvas.current || !pageMetadata) return;

    if (!userSelectedZone.current.active) return;

    // If there is no docType selected, don't trigger anything on mouseMove
    if (doc && (!doc.doc_type || doc.status === DocumentStatus.Processing)) return;

    // Save current selection zone
    const { x, y } = getMousePos(evt);
    userSelectedZone.current.w = Number(x) - userSelectedZone.current.x;
    userSelectedZone.current.h = Number(y) - userSelectedZone.current.y;
    userSelectedZone.current.mouseX = evt.clientX;
    userSelectedZone.current.mouseY = evt.clientY;

    // Clear everything and redraw
    clearCanvas();

    // Draw the userSelectedZone
    drawWordOverlay(userSelectedZone.current, "#f0f2f1", 1, true);

    // If we arrive here, then a text selection is in progress
    const selectedWords = getWordsInUserSelectedZone();

    selectedWords.forEach((word) => {
      const scaledBbox = convertBboxToCanvasScale(word.bbox);
      if (scaledBbox) drawWordOverlay(convertOcrBoxToSelectionZoneRectangle(scaledBbox), "#5abced", 0);
    });
  };

  // When the user mouse leave the canvas, stop everything
  const onMouseLeave = (evt: MouseEvent) => {
    userSelectedZone.current.active = false;
  };

  // When the user release the mouse, then we trigger the onSelect callback.
  const onMouseUp = (evt: any) => {
    if (!userSelectedZone.current) return;

    const { x, y } = getMousePos(evt);
    userSelectedZone.current.active = false;
    userSelectedZone.current.w = Number(x) - userSelectedZone.current.x;
    userSelectedZone.current.h = Number(y) - userSelectedZone.current.y;

    // If there is no docType selected, don't trigger anything on mouseUp
    if (doc && (!doc.doc_type || doc.status === DocumentStatus.Processing)) return;

    // Retrieve selected words within the zone
    let words = getWordsInUserSelectedZone();
    let mappedFields = getMappedFieldsForPage(doc, pageNumber).flat();

    // Check if some words selected are already mapped in another field
    // If it's the case, then we trigger an error
    let isAnyWordAlreadyMapped = false;
    if (mappedFields?.length > 0) {
      for (const word of words) {
        const sameWord = mappedFields.find(
          (f: IDocumentMappedField) =>
            // Check with focusedField
            ((focusedField && (focusedField.technicalName !== f.field_id || (focusedField.technicalName === f.field_id && focusedField.row !== f.row))) ||
              // Checks in focusedTable mode
              (focusedTable?.focusedCell &&
                (focusedTable.focusedCell.cell.technicalName !== f.field_id || (focusedTable.focusedCell.cell.technicalName === f.field_id && focusedTable.focusedCell.row !== f.row))) ||
              // Checks in focusedColumn mode
              (focusedTable?.focusedColumn && focusedTable.focusedColumn.column.technicalName !== f.field_id)) &&
            f.mapping?.find((w) => {
              return w.page === word.page && w.bbox.x0 === word.bbox.x0 && w.bbox.x1 === word.bbox.x1 && w.bbox.y0 === word.bbox.y0 && w.bbox.y1 === word.bbox.y1;
            })
        );

        if (sameWord && (focusedField || focusedTable?.focusedCell?.edition || focusedTable?.focusedColumn)) {
          isAnyWordAlreadyMapped = true;
          let errorMessage = `${t("labelling_panel.toaster_failed_mapping_already_mapped")} ${t(`documentType.${sameWord.field_id}`, `${sameWord.field_id}`)} ${typeof sameWord.row === "number" && Number.isInteger(sameWord.row) ? `- ${t("labelling_panel.toaster_failed_table_row", { row: sameWord.row + 1 })}` : ""
            }`;
          toast.error(errorMessage);
          break;
        }
      }
    }

    // If some words are selected and none are already mapped, then proceed
    if (!isAnyWordAlreadyMapped && words.length > 0) {
      // Dispatch the selected zone into redux state

      // If we are in table column mapping mode
      if (focusedTable?.focusedColumn) dispatch(addColumnMappingWords(words));
      // Note: if a field is focused, the mapping will be automatically saved for this field
      else {
        // If a field is focused, the mapping will be automatically saved for this field
        if (focusedField || focusedTable?.focusedCell?.edition) {
          dispatch(selectMappingWords(words));
        }
        // Else, if one field is already mapped with the selection, focus it
        else {
          const mappedWord = mappedFields?.find((f: IDocumentMappedField) =>
            f.mapping?.find((w) => {
              return w.page === words[0].page && w.bbox.x0 === words[0].bbox.x0 && w.bbox.x1 === words[0].bbox.x1 && w.bbox.y0 === words[0].bbox.y0 && w.bbox.y1 === words[0].bbox.y1;
            })
          );
          // If a mapped word has been found on this position, focus the associated field
          if (mappedWord) {
            if (mappedWord.table) {
              const tableField = currentDocType?.fields.find((f) => f.technicalName === mappedWord.table && f.type === DocumentTypeFieldType.table);
              if (tableField) {
                const column = tableField.columns?.find((c) => c.technicalName === mappedWord.field_id);
                if (column && mappedWord.row !== undefined) {
                  dispatch(setFocusedTable(tableField));
                  let tableFocusedCell: TableFocusedCell = { cell: column, row: mappedWord.row, edition: false };
                  dispatch(setFocusedTableCell(tableFocusedCell));
                }
              }
            } else {
              const field = currentDocType?.fields.find((f) => f.technicalName === mappedWord.field_id);
              if (field) dispatch(setFocusedField({ field }));
            }
          }
          // Else just trigger the classic words selection (and annotation modal display)
          else {
            dispatch(selectMappingWords(words));

            // Trigger callback to inform pageViewer that a zone has been selected
            onUserZoneSelection(userSelectedZone.current);
          }
        }
      }
    }

    // Re-draw mapped fields
    clearCanvas();
    drawMappedFields();
    // drawTableRows();
    drawSelectedMappingWords();
  };

  //
  // Helpers
  //

  // Get all words currently selected by the user with the mapping zone
  const getWordsInUserSelectedZone = (): OcrWord[] => {
    if (!pageAnnotationsCanvas.current || !pageMetadata) return [];

    const location: OcrBbox = {
      x0: userSelectedZone.current.x,
      x1: userSelectedZone.current.x + userSelectedZone.current.w,
      y0: userSelectedZone.current.y,
      y1: userSelectedZone.current.y + userSelectedZone.current.h,
    };

    // reset coordinates of bounding box if inverted:
    if (location.x0 > location.x1) [location.x0, location.x1] = [location.x1, location.x0];
    if (location.y0 > location.y1) [location.y0, location.y1] = [location.y1, location.y0];

    // Find words in this zone
    let words = [];
    for (const word of pageWords) {
      const scaledBbox = convertBboxToCanvasScale(word.bbox);
      if (scaledBbox && !(scaledBbox.x0 > location.x1 || scaledBbox.x1 < location.x0 || scaledBbox.y0 > location.y1 || scaledBbox.y1 < location.y0)) {
        words.push(word);
      }
    }

    return words;
  };

  //
  // Rendering
  //

  // Based on current pageMetadata and ReactPDF component autoRotation, calculcate the real rotation needed
  const currentPageMetadata = pagesMetadata.find((pm) => pm.page === pageNumber);
  let rotation = 0;
  if (currentPageMetadata) {
    if (renderingOrientation === 0) rotation = -currentPageMetadata.rotationAngle;
    else rotation = renderingOrientation - currentPageMetadata.rotationAngle;
  }

  //console.log(`Custom page rotation ${currentPageMetadata?.rotationAngle} / Rendering orientation: ${renderingOrientation} / Final page orientation: ${rotation}`);
  return (
    <div ref={ref}>
      <Page
        canvasRef={(ref) => {
          pageCanvas.current = ref;
          if (pageCanvas.current) pageCanvas.current.id = "page-canvas-" + pageNumber;
        }}
        className={"bg-white shadow-lg mx-2 my-2 relative z-0"}
        key={`page_${pageNumber}`}
        pageNumber={pageNumber}
        renderTextLayer={false}
        onLoadSuccess={() => setIsLoaded(true)}
        width={pageDisplayWidth}
        rotate={rotation}
      />
    </div>
  );
};

type TableRowPosition = {
  index: number;
  y0: number;
  y1: number;
};

export default CustomPage;
