import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";

import { selectCustomStylesFitContent } from "components/SelectSearch";
import { IConnectorMeta, IExporter } from "models/connector";
import { t } from "i18next";
import { AVAILABLE_EXPORT_FORMATTERS } from "models/usecase";

import Button, { ButtonStyle } from "components/Button";
import { BiTrash } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { HiArrowNarrowRight } from "react-icons/hi";

interface ExportersSectionProps {
  connectors: IConnectorMeta[];
  exporters: IExporter[];
  onExportersChange: (selectedExporters: IExporter[]) => void;
}

const ExportersSection: React.FC<ExportersSectionProps> = ({ connectors, exporters, onExportersChange }) => {
  const [selectedExporters, setSelectedExporters] = useState<IExporter[]>(exporters || []);

  // Effect to sync state with parent component on each change
  useEffect(() => {
    onExportersChange(selectedExporters);
  }, [selectedExporters, onExportersChange]);

  // Function to handle adding a new exporter to the array
  const addExporter = () => {
    setSelectedExporters([
      ...selectedExporters,
      { connectorId: "", exportFormatters: [] } // Empty new exporter object
    ]);
  };

  // Function to handle updating an exporter's connectorId
  const updateConnectorMeta = (index: number, newValue: any) => {
    const updatedExporters = [...selectedExporters];
    updatedExporters[index].connectorId = newValue.value.id;
    console.log("updatedExporters - ", updatedExporters);
    setSelectedExporters(updatedExporters);
  };

  // Function to handle updating an exporter's exportFormatters
  const updateFormatters = (index: number, selectedOptions: MultiValue<unknown>) => {
    const updatedExporters = [...selectedExporters];
    updatedExporters[index].exportFormatters = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setSelectedExporters(updatedExporters);
  };

  // Function to delete an exporter
  const deleteExporter = (index: number) => {
    const updatedExporters = selectedExporters.filter((_, i) => i !== index);
    setSelectedExporters(updatedExporters);
  };

  return (
    <div>
      {selectedExporters.map((exporter, index) => (
        <div key={index} className="my-3 flex items-center">
          <ConnectorSelect
            connectors={connectors}
            selectedConnectorId={exporter.connectorId}
            onChange={(newValue) => updateConnectorMeta(index, newValue)}
          />

          <div className="mx-3 text-gray-500">
            <HiArrowNarrowRight className="text-gray-500 text-xl" />
          </div>

          <ExportFormattersSelect
            selectedFormatters={exporter.exportFormatters || []}
            onChange={(selectedOptions) => updateFormatters(index, selectedOptions)}
          />
          <button
            onClick={() => deleteExporter(index)}
            className="mx-3 hover:text-red-500"
          >
            <BiTrash className="inline" />
          </button>
        </div>
      ))}
      <Button
        text={t("usecase.exporters.add_exporter")}
        onClick={addExporter}
        color={ButtonStyle.Blue}
        leftIcon={<MdAdd className="inline text-xs mr-2" />}
        className="align-middle mb-2 text-xl"
        small={true}
      />
    </div>
  );
};

interface ConnectorSelectProps {
  connectors: IConnectorMeta[];
  selectedConnectorId: string | null;
  onChange: (newValue: unknown) => void
};

const ConnectorSelect: React.FC<ConnectorSelectProps> = ({ connectors, selectedConnectorId, onChange }) => {
  const formatConnectorsSelectOptions = () => {
    return connectors.map((c) => ({ value: c, label: `${c.name} | ${c.type}` })).sort((a, b) => a.label.localeCompare(b.label));
  };

  const selectedConnectorMeta = selectedConnectorId ? connectors.find(c => c.id === selectedConnectorId) : null;

  return (
    // <div className="flex items-center align-middle  border-docloop_borderColor my-3">
    <Select
      classNamePrefix="connector_select"
      isSearchable={true}
      name="connector"
      value={
        (selectedConnectorId && selectedConnectorMeta) ?
          { value: selectedConnectorId, label: `${selectedConnectorMeta.name} | ${selectedConnectorMeta.type}` } :
          null
      }
      placeholder={t("usecase_configuration.connector_selection_placeholder")}
      options={formatConnectorsSelectOptions()}
      onChange={(newValue: unknown) => onChange(newValue)}
      styles={selectCustomStylesFitContent}
      isClearable={true}
    />
    // </div>
  );
};

interface ExportFormattersSelectProps {
  selectedFormatters: string[];
  onChange: (selectedOptions: MultiValue<unknown>) => void;
};

const ExportFormattersSelect: React.FC<ExportFormattersSelectProps> = ({ selectedFormatters, onChange }) => {
  const formatOptions = AVAILABLE_EXPORT_FORMATTERS.map(formatter => ({
    value: formatter,
    label: formatter
  })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="flex-grow-0">
      <Select
        isMulti
        classNamePrefix={"export_formatters_select"}
        className="text-xs"
        isSearchable={true}
        name="export_formatters"
        placeholder={t("usecase_configuration.export_formatters_selection_placeholder")}
        options={formatOptions}
        // styles={selectCustomStylesFitContent}
        value={formatOptions.filter(option => selectedFormatters.includes(option.value))}
        onChange={(newValue: MultiValue<unknown>) => onChange(newValue)}
      />
    </div>
  );
}

export default ExportersSection;
