import React from "react";
import { t } from "i18next";
import toast from "react-hot-toast";
import { AiOutlineStar } from "react-icons/ai";
import { HiTrash } from "react-icons/hi";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSearchDocumentsTypes, setSearchText } from "redux/search";
import { ISavedQuery } from "models/user";
import { deleteSearchQuery } from "redux/users";
import _ from "lodash";

type SavedQueriesProps = {};

export const SavedQueries: React.FC<SavedQueriesProps> = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.user);
  const { searchText, searchDocumentsTypes } = useAppSelector((state) => state.search);

  // Handle click on savedQuery => automatically set searchText
  const handleSavedQueryClick = (query: ISavedQuery) => {
    dispatch(setSearchText(query.search_text ?? ""));
    dispatch(setSearchDocumentsTypes(query.search_documents_types ?? []));
  };

  // Handle click on delete savedQuery
  const handleSavedQueryDeleteClick = (query: ISavedQuery) => {
    try {
      dispatch(deleteSearchQuery(query.name));
      toast.error(t("home.saved_queries.delete_successful"));
    } catch (err) {
      toast.error(t("home.saved_queries.delete_error"));
    }
  };

  // Return true if the query in params is currently selected
  const isQuerySelected = (query: ISavedQuery) => {
    return (searchText === query.search_text) && _.isEqual([...searchDocumentsTypes]?.sort() ?? [], [...query.search_documents_types]?.sort() ?? [])
  };

  //
  return (
    <div className="mt-20 pl-4 pr-2 select-none">
      <div className="font-semibold text-xs">
        <AiOutlineStar className="inline mr-2 text-2xl" />
        {t("home.saved_queries.title")}
      </div>
      <ul className="pl-8 pr-1 pt-1">
        {currentUser?.saved_queries.map((savedQuery, index) => {
          const isSelected = isQuerySelected(savedQuery);
          return (
            <li
              key={`saved-query-${index}`}
              className={`group flex items-center text-xs cursor-pointer py-1 ${isSelected ? "text-blue-500 font-semibold hover:text-blue-600" : "text-gray-500 hover:text-gray-700"}`}
              onClick={() => handleSavedQueryClick(savedQuery)}
            >
              {savedQuery.name}

              <div
                className="hidden cursor-pointer ml-3 text-sm text-gray-400 hover:text-red-400 group-hover:inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSavedQueryDeleteClick(savedQuery);
                }}
              >
                <HiTrash />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
