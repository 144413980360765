export enum ConnectorType {
  FTP = "Ftp", // SFTP
  AKANEA_DOUANE = "AkaneaDouane", //  FTP-like
  FTP_WITH_PDF = "FtpWithPdf", // Export format + Original pdf
  FTP_NON_SECURE = "FtpNonSecure", // Basic FTP like Chatel
  HTTP = "Http",
  DISPATCH_MISSION = "DispatchMission",
  DISPATCH_QUOTATION = "DispatchQuotation"
};

export interface INewConnector {
  name: string;
  type: ConnectorType;
}
export interface IConnectorMeta extends INewConnector {
  id: string;
}

export interface IConnector extends IConnectorMeta {
  params: { [key: string]: string };
}

export interface IFtpParams {
  host: string;
  port: number;
  username: string;
  password: string;
  path: string;
  filename?: string; // UNUSED: Filename that could override default name from export_formatter
}

export interface IDispatchParams {
  host: string;
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
}

export interface IExporter {
  connectorId: string;
  exportFormatters: string[];
}