import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import Home from "views/scenes/home";
import Login from "views/scenes/login";
import JobFileValidation from "views/scenes/jobfile/validation";
import JobFileDocuments from "views/scenes/jobfile/documents";
import UsecaseConfiguration from "views/scenes/admin/usecase/configuration";
import UsersHome from "views/scenes/admin/tenantUsers";
import UserDetails from "views/scenes/admin/user/details";
import UserPassword from "views/scenes/admin/user/password"
import ForgotPassword from "views/scenes/admin/user/forgot_password";
import ConnectorsList from "views/scenes/admin/connectors";
import UsecasesList from "views/scenes/admin/usecase";

import MainLayout from "components/MainLayout";
import AdminLayout from "views/scenes/admin/components/AdminLayout";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { logout } from "redux/users";
import api from "services/api";
import Registration from "views/scenes/registration";
import ConnectorConfiguration from "views/scenes/admin/connectors/ConnectorConfiguration";
import ReferentialsList from "views/scenes/admin/referentials";
import ReferentialConfiguration from "views/scenes/admin/referentials/configuration";

export default function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Refreshing the app must refresh the token
    api.get("/status")
      .then((response) => console.log(`API Status:`, response.data))
      .catch((err) => {
        console.log(err);
        dispatch(logout());
      });
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route element={<MainLayout />}>
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/jobfile/:jobFileId/documents" element={<PrivateRoute><JobFileDocuments /></PrivateRoute>} />
          <Route path="/jobfile/:jobFileId/documents/:jobfileDocId" element={<PrivateRoute><JobFileDocuments /></PrivateRoute>} />
          <Route path="/jobfile/:jobFileId/validation" element={<PrivateRoute><JobFileValidation /></PrivateRoute>} />
          <Route element={<AdminLayout />}>
            <Route path="/admin/users/my_profile" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
            <Route path="/admin/users/my_password" element={<PrivateRoute><UserPassword /></PrivateRoute>} />
            <Route path="/admin/users" element={<PrivateRoute><UsersHome /></PrivateRoute>} />
            <Route path="/admin/connectors" element={<PrivateRoute><ConnectorsList /></PrivateRoute>} />
            <Route path="/admin/connectors/:connectorId" element={<PrivateRoute><ConnectorConfiguration /></PrivateRoute>} />
            <Route path="/admin/usecase" element={<PrivateRoute><UsecasesList /></PrivateRoute>} />
            <Route path="/admin/usecase/:useCaseId/configuration" element={<PrivateRoute><UsecaseConfiguration /></PrivateRoute>} />
            <Route path="/admin/referentials" element={<PrivateRoute><ReferentialsList /></PrivateRoute>} />
            <Route path="/admin/referentials/:referentialId/configuration" element={<PrivateRoute><ReferentialConfiguration /></PrivateRoute>} />
          </Route>
          <Route path="*" element={<PrivateRoute><Home /></PrivateRoute>} />
        </Route>
      </Routes>
    </Router>
  );
}

export const PrivateRoute = ({ children }: { children: React.JSX.Element }) => {
  const userState = useAppSelector((state) => state.user);
  // A component needs to return a single root element.
  //You can use fragments to package an array of elements as a single element, by using the fragment as that single root element.
  if (userState.loggedIn) {
    return children
  } else {
    return <Navigate to="/login" replace />
  }
};