import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  DocumentFieldOrigin,
  DocumentFieldRowUpdateRequest,
  DocumentFieldUpdateRequest,
  DocumentHeuristicsMetadata,
  DocumentStatus,
  DocumentTableFieldAddRowRequest,
  IDocument,
  IDocumentField,
  IDocumentTableRow,
} from "models/document";

import { DocumentTypeFieldType, IDocumentTypeField } from "models/document_type";
import DocumentsService from "services/documents.service";
import { OcrWord } from "types/labelling";

//
// Declare types for table context
//

// Type that hold metadata of a page
export type PageMetadata = {
  page: number;
  width: number;
  height: number;
  rotationAngle: number;
};

// Type that hold everything needed for a focused cell
export type TableFocusedCell = {
  row: number;
  cell: IDocumentTypeField;
  edition: boolean;
};

// Type that hold everything needed for table rows selection
type TableRowsSelection = {
  rows: number[];
  multiSelectionEnabled?: boolean;
  rangeSelectionEnabled?: boolean; // Used when you select one row, then press shift and select a new row
};

// Type that represents a position
export type Position = {
  x: number;
  y: number;
  w: number;
  h: number;
};

// Type that hold everything needed for a focused column (for mapping feature)
type TableFocusedColumn = {
  column: IDocumentTypeField;
  selectedMappingWords: OcrWord[][]; // Array of array of mapping words.
};

// Type that hold everything related to the current focused table
export type FocusedTable = {
  table: IDocumentTypeField; // Table focused
  rowsSelection: TableRowsSelection; // Rows selected in table component
  isUpdatingRows: boolean; // Is currently updating rows (insert / delete)
  displayCleaningAction: boolean; // Display cleaning table action

  focusedCell?: TableFocusedCell; // Cell focused
  focusedColumn?: TableFocusedColumn; // Column focused

  error?: string | null; // error messages when updates failed from api
};

export type LabellingState = {
  currentDocument: IDocument | null;
  currentDocumentHeuristics: DocumentHeuristicsMetadata | null;
  pagesMetadata: PageMetadata[];
  isDocumentLoading: boolean;
  readOnly: boolean;

  focusedField: IDocumentTypeField | null;
  multiMapping: boolean | null;
  selectedMappingWords: OcrWord[] | null;

  focusedTable: FocusedTable | null;
};

export const initialState: LabellingState = {
  currentDocument: null,
  currentDocumentHeuristics: null,
  pagesMetadata: [],
  isDocumentLoading: false,
  readOnly: false,

  focusedField: null,
  multiMapping: false,
  selectedMappingWords: null,

  focusedTable: null,
};

// Type for focusedField event
type focusedFieldAction = {
  field: IDocumentTypeField | null;
  // Used with true when mapping is done on top of PageViewer to keep the bbox in a persistent selection
  keepSelectedMappingWords?: boolean;
};

export const loadDocumentLabelling = createAsyncThunk("labelling/loadDocument", async (docId: string) => {
  const res = await DocumentsService.getDocument(docId);
  return res;
});

export const validateDocument = createAsyncThunk("labelling/validateDocument", async (docId: string) => {
  const res = await DocumentsService.validateDocument(docId);
  return res;
});
export const unvalidateDocument = createAsyncThunk("labelling/unvalidateDocument", async (docId: string) => {
  const res = await DocumentsService.unvalidateDocument(docId);
  return res;
});

export const addTableRow = createAsyncThunk("labelling/addTableRow", async ({ docId, newRow }: { docId: string; newRow: DocumentTableFieldAddRowRequest }, { dispatch }) => {
  const heuristicsData = await DocumentsService.addTableRow(docId, newRow);
  dispatch(setHeuristicsMetadata(heuristicsData));
  return true;
});

export const updateTableColumnRows = createAsyncThunk(
  "labelling/updateTableColumnRows",
  async ({ docId, tableId, columnId, fields }: { docId: string; tableId: string; columnId: string; fields: IDocumentField[] }, { dispatch }) => {
    const heuristicsData = await DocumentsService.updateTableColumnRows(docId, tableId, columnId, fields);
    dispatch(setHeuristicsMetadata(heuristicsData));
    return true;
  }
);

//
// Core
//
const labellingSlice = createSlice({
  name: "labelling",
  initialState,
  reducers: {
    // Set current document in state
    setCurrentDocument(state, action: PayloadAction<{ doc: IDocument | null; keepPagesMetadata?: boolean }>) {
      state.currentDocument = action.payload.doc;
      if (!action.payload.keepPagesMetadata) {
        state.pagesMetadata = [];
        state.selectedMappingWords = [];
      }
      state.focusedField = null;
      state.focusedTable = null;
      state.currentDocumentHeuristics = null;
      state.readOnly = action.payload.doc?.status === DocumentStatus.None
    },

    // Save page metadata in state
    // Needed for multiple functions
    addPageMetadata(state, action: PayloadAction<PageMetadata>) {
      let existingEntryIndex = state.pagesMetadata.findIndex((pm) => pm.page === action.payload.page);
      if (existingEntryIndex !== -1) state.pagesMetadata[existingEntryIndex] = action.payload;
      else state.pagesMetadata.push(action.payload);
    },

    // Save heuristics data
    setHeuristicsMetadata(state, action: PayloadAction<DocumentHeuristicsMetadata | null>) {
      state.currentDocumentHeuristics = action.payload;
    },

    //
    // Every action related to key/value field mapping
    //
    setFocusedField(state, action: PayloadAction<focusedFieldAction>) {
      state.focusedField = action.payload.field;
      if (state.focusedTable?.focusedCell) state.focusedTable.focusedCell = undefined;
      if (state.focusedTable?.focusedColumn) state.focusedTable.focusedColumn = undefined;

      if (action.payload.keepSelectedMappingWords !== true) state.selectedMappingWords = [];
    },
    setMultiMapping(state, action: PayloadAction<boolean>) {
      state.multiMapping = action.payload;
    },
    selectMappingWords(state, action: PayloadAction<OcrWord[] | null>) {
      state.selectedMappingWords = action.payload;
    },
    // Required because the remote save is done after a debounce of few milliseconds
    // Due to that, we need to update the field locally
    updateDocumentFieldLocally(state, action: PayloadAction<DocumentFieldUpdateRequest>) {
      const fieldId = action.payload.field_id;
      if (!state.currentDocument?.extraction) return;
      const documentField = state.currentDocument.extraction.data[fieldId];
      if (documentField) {
        // if the origin is still manual, prevent redux state update
        // It's required to avoid rendering on each key entered
        if (documentField.origin === DocumentFieldOrigin.Manual && action.payload.origin === DocumentFieldOrigin.Manual) return;

        state.currentDocument.extraction.data[fieldId] = {
          ...documentField,
          field_id: action.payload.field_id,
          field_type: action.payload.field_type,
          origin: action.payload.origin,
          value: action.payload.value,
          mapping: action.payload.mapping,
        };
      } else {
        state.currentDocument.extraction.data[fieldId] = {
          field_id: action.payload.field_id,
          field_type: action.payload.field_type,
          origin: action.payload.origin,
          value: action.payload.value,
          mapping: action.payload.mapping,
        };
      }
    },

    //
    // Every action related to Table
    //

    setFocusedTable(state, action: PayloadAction<IDocumentTypeField | null>) {
      state.focusedTable = action.payload ? { table: action.payload, rowsSelection: { rows: [], multiSelectionEnabled: false }, isUpdatingRows: false, displayCleaningAction: false } : null;
      state.focusedField = null;
    },
    setFocusedTableCell(state, action: PayloadAction<TableFocusedCell | null>) {
      if (state.focusedTable) {
        state.focusedField = null;
        state.focusedTable.focusedCell = action.payload ?? undefined;
        state.focusedTable.focusedColumn = undefined;
        state.selectedMappingWords = [];

        // If there is a focused cell
        if (action.payload) {
          // reset row selection
          state.focusedTable.rowsSelection = { rows: [], multiSelectionEnabled: false };
        }
      }
    },
    setTableRowsSelection(state, action: PayloadAction<TableRowsSelection>) {
      if (state.focusedTable) {
        state.focusedTable.rowsSelection = action.payload;
      }
    },

    setDisplayCleaningAction(state, action: PayloadAction<boolean>) {
      if (state.focusedTable) {
        state.focusedTable.displayCleaningAction = action.payload;
      }
    },

    // Based on updateDocumentFieldLocally, but for table fields
    updateDocumentTableLocally(state, action: PayloadAction<DocumentFieldRowUpdateRequest>) {
      const tableFieldId = action.payload.table;
      if (!state.currentDocument?.extraction) return;
      const documentTable = state.currentDocument.extraction.data[tableFieldId];
      if (documentTable) {
        // Identify the table row based on the row number
        //console.log("[LABELLING STORE] Table found in store");
        let row = documentTable.value[action.payload.row];
        if (row) {
          //console.log("[LABELLING STORE] Updating existing row");
          // Replace the row with the new one
          documentTable.value[action.payload.row] = action.payload.value;
        } else {
          //console.log("[LABELLING STORE] Create new row");
          // Add the row if it doesn't exist
          documentTable.value.push(action.payload.value);
        }
      } else {
        // Table is not yet created in currentDocument.extraction.data
        console.log("[LABELLING STORE] Table not found in store - Creating a new one");
        // Init the table in the data
        state.currentDocument.extraction.data[tableFieldId] = {
          field_id: action.payload.table,
          field_type: DocumentTypeFieldType.table,
          origin: DocumentFieldOrigin.None,
          value: [action.payload.value],
          mapping: [],
        };
      }
    },

    // Insert new row in table
    insertRowInTableLocally(state, action: PayloadAction<DocumentFieldRowUpdateRequest>) {
      const tableFieldId = action.payload.table;
      if (!state.currentDocument?.extraction) return;
      const documentTable = state.currentDocument.extraction.data[tableFieldId];

      if (documentTable) {
        // Identify the table row based on the row number
        //console.log("[LABELLING STORE] Table found in store");
        // If the row in payload is greater than the number of rows of the table, just insert it at the end
        if (action.payload.row >= documentTable.value.length) {
          documentTable.value.push(action.payload.value);
        }
        // Else insert it at specified index
        else {
          documentTable.value.splice(action.payload.row, 0, action.payload.value);
        }
      } else {
        // Table is not yet created in currentDocument.extraction.data
        console.log("[LABELLING STORE] Table not found in store - Creating a new one");
        // Init the table in the data
        state.currentDocument.extraction.data[tableFieldId] = {
          field_id: action.payload.table,
          field_type: DocumentTypeFieldType.table,
          origin: DocumentFieldOrigin.None,
          value: [action.payload.value],
          mapping: [],
        };
      }
    },

    // Delete multiple rows in a table field
    deleteDocumentTableRowsLocally(state, action: PayloadAction<{ table: string; rows: number[] }>) {
      const tableFieldId = action.payload.table;
      if (!state.currentDocument?.extraction) return;
      const documentTable = state.currentDocument.extraction.data[tableFieldId];

      if (documentTable) {
        // Remove rows from the table
        documentTable.value = (documentTable.value as Array<IDocumentField[][]>).filter((_, rowIndex) => !action.payload.rows.includes(rowIndex));
      } else {
        console.log("Table not found in document");
      }
    },

    // Specific function used by column mapping feature
    // Things to handle:
    // - Init table if not done already
    // - Update existing rows
    // - Insert additional rows if needed
    // - Clean empty rows
    updateDocumentTableColumnLocally(state, action: PayloadAction<IDocumentField[]>) {
      if (!state.focusedTable?.focusedColumn) return;
      const tableFieldId = state.focusedTable.table.technicalName;
      const focusedCol = state.focusedTable.focusedColumn.column;

      if (!state.currentDocument?.extraction) return;
      let documentTable = state.currentDocument.extraction.data[tableFieldId];

      let tableRows: IDocumentTableRow[] = [];

      // Check if document already have table initialized
      // If not, init the table in the data
      if (!documentTable) {
        state.currentDocument.extraction.data[tableFieldId] = {
          field_id: state.focusedTable.table.technicalName,
          field_type: state.focusedTable.table.type,
          origin: DocumentFieldOrigin.None,
          value: [],
          mapping: [],
        };
        documentTable = state.currentDocument.extraction.data[tableFieldId];
      } else {
        tableRows = documentTable.value as IDocumentTableRow[];
      }

      // Browse each existing row and update row if needed
      let rowIndex = 0;

      for (rowIndex; rowIndex < tableRows.length; rowIndex++) {
        let existingRowField = tableRows[rowIndex][focusedCol.technicalName];
        // If field exists, update value
        if (existingRowField) {
          // Either update cell value or clean it
          existingRowField.value = action.payload[rowIndex]?.value ?? "";
          existingRowField.origin = action.payload[rowIndex]?.origin ?? DocumentFieldOrigin.None;
          existingRowField.mapping = action.payload[rowIndex]?.mapping ?? [];
        }
        // Else, create it
        else {
          tableRows[rowIndex][focusedCol.technicalName] = {
            field_id: focusedCol.technicalName,
            field_type: focusedCol.type,
            value: action.payload[rowIndex]?.value ?? "",
            origin: action.payload[rowIndex]?.origin ?? DocumentFieldOrigin.None,
            mapping: action.payload[rowIndex]?.mapping ?? [],
          };
        }
      }

      // If current rowIndex is still below the number of lines in payload, then we have to create new row
      for (rowIndex; rowIndex < action.payload.length; rowIndex++) {
        const newRow: IDocumentTableRow = {};
        newRow[focusedCol.technicalName] = {
          field_id: focusedCol.technicalName,
          field_type: focusedCol.type,
          value: action.payload[rowIndex]?.value ?? "",
          origin: action.payload[rowIndex]?.origin ?? DocumentFieldOrigin.None,
          mapping: action.payload[rowIndex]?.mapping ?? [],
        };
        tableRows.push(newRow);
      }

      // Automaticallly clean rows with only empty value
      let rowIndexesToRemove: number[] = [];
      for (rowIndex = 0; rowIndex < tableRows.length; rowIndex++) {
        const isRemovableRow = Object.values(tableRows[rowIndex]).every((f) => !f.value || f.value.length === 0);
        if (isRemovableRow) rowIndexesToRemove.push(rowIndex);
      }
      tableRows = tableRows.filter((_, rowIndex) => !rowIndexesToRemove.includes(rowIndex));

      // Save change in currentDocument
      documentTable.value = tableRows;
    },

    //
    // Every action related to column mapping
    //
    setFocusedTableColumn(state, action: PayloadAction<TableFocusedColumn | null>) {
      if (state.focusedTable) {
        state.focusedTable.focusedCell = undefined;
        state.focusedTable.focusedColumn = action.payload ?? undefined;
      }
      state.focusedField = null;
      state.selectedMappingWords = [];
    },

    // Add other words to current column mapping
    addColumnMappingWords(state, action: PayloadAction<OcrWord[]>) {
      if (state.focusedTable?.focusedColumn) {
        // If there is only one word in payload, check if it's already selected
        // If it's the case, then we unselect it
        if (action.payload.length === 1) {
          let cleanDone = false;
          for (let i = 0; i < state.focusedTable.focusedColumn.selectedMappingWords.length; i++) {
            const sameWordIndex = state.focusedTable.focusedColumn.selectedMappingWords[i].findIndex((w) => _.isEqual(w, action.payload[0]));
            if (sameWordIndex !== -1) {
              // Word already selected, unselect it
              state.focusedTable.focusedColumn.selectedMappingWords[i].splice(sameWordIndex, 1);
              cleanDone = true;

              // If selection zone is now empty, remove it
              if (state.focusedTable.focusedColumn.selectedMappingWords[i].length === 0) {
                state.focusedTable.focusedColumn.selectedMappingWords.splice(i, 1);
              }
            }
          }

          if (cleanDone) return;
        }

        // Else we just add the payload to selected zones
        state.focusedTable.focusedColumn.selectedMappingWords.push(action.payload);
      }
    },

    // Column mapping: revert last zone mapped
    revertLastColumnMapping(state) {
      if (state.focusedTable?.focusedColumn && state.focusedTable.focusedColumn.selectedMappingWords.length > 0) {
        const newSelectedMappingZones = [...state.focusedTable.focusedColumn.selectedMappingWords];
        newSelectedMappingZones.pop();
        state.focusedTable.focusedColumn.selectedMappingWords = newSelectedMappingZones;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDocumentLabelling.pending, (state) => {
      state.isDocumentLoading = true;
    });
    builder.addCase(loadDocumentLabelling.fulfilled, (state, action: PayloadAction<IDocument>) => {
      state.currentDocument = action.payload;
      state.isDocumentLoading = false;
    });
    builder.addCase(loadDocumentLabelling.rejected, (state) => {
      state.currentDocument = null;
      state.isDocumentLoading = false;
    });

    builder.addCase(addTableRow.pending, (state) => {
      if (state.focusedTable) state.focusedTable.isUpdatingRows = true;
    });
    builder.addCase(addTableRow.fulfilled, (state, action: PayloadAction<boolean>) => {
      if (state.focusedTable) state.focusedTable.isUpdatingRows = false;
    });
    builder.addCase(addTableRow.rejected, (state) => {
      if (state.focusedTable) state.focusedTable.isUpdatingRows = false;
    });

    builder.addCase(updateTableColumnRows.pending, (state) => {
      if (state.focusedTable) state.focusedTable.isUpdatingRows = true;
    });
    builder.addCase(updateTableColumnRows.fulfilled, (state, action: PayloadAction<boolean>) => {
      if (state.focusedTable) state.focusedTable.isUpdatingRows = false;
    });
    builder.addCase(updateTableColumnRows.rejected, (state) => {
      if (state.focusedTable) state.focusedTable.isUpdatingRows = false;
    });

    builder.addCase(validateDocument.fulfilled, (state, action: PayloadAction<IDocument>) => {
      if (state.currentDocument && state.currentDocument._id === action.payload._id) {
        state.currentDocument.status = action.payload.status
        state.readOnly = true
      }
    });
    builder.addCase(unvalidateDocument.fulfilled, (state, action: PayloadAction<IDocument>) => {
      if (state.currentDocument && state.currentDocument._id === action.payload._id) {
        state.currentDocument.status = action.payload.status
        state.readOnly = false
      }
    });
  },
});

export const {
  setCurrentDocument,
  addPageMetadata,
  setHeuristicsMetadata,

  setFocusedField,
  setMultiMapping,
  selectMappingWords,
  updateDocumentFieldLocally,

  setFocusedTable,
  setFocusedTableCell,
  setTableRowsSelection,
  setDisplayCleaningAction,

  updateDocumentTableLocally,
  deleteDocumentTableRowsLocally,
  insertRowInTableLocally,
  updateDocumentTableColumnLocally,

  setFocusedTableColumn,
  addColumnMappingWords,
  revertLastColumnMapping,
} = labellingSlice.actions;
export const reducer = labellingSlice.reducer;
